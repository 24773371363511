import React, { useState, useEffect } from "react";
import logo from "../assets/img/nationalaccess-logo.png";
import loginImg from "../assets/img/login-greet.jpg";
import { Switch } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import "../assets/styles/ReactOtpInput.css";
import qrImg from "../assets/img/qr-img.png";
import toast from "react-hot-toast";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { forgotEmailPost } from "../utils/redux/slice/forgotEmailSlice";
import { forgotOtpPost } from "../utils/redux/slice/forgotOtpSlice";
import { forgotResetPassPost } from "../utils/redux/slice/forgotResetPassSlice";
import { ThreeDots } from "react-loader-spinner";

export default function ForgotPassword() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpSms, setOtpSms] = useState("");
  const [otpUI, setOtpUI] = useState({ email: true, sms: false });

  const [flagForgotUI, setFlagForgotUI] = useState({
    otpUI: false,
    newPass: false,
  });

  const postEmailLoader = useSelector(
    (state) => state.forgotEmailStorage.isLoading
  );
  const putOtpLoader = useSelector((state) => state.forgotOtpStorage.isLoading);
  const putPassLoader = useSelector(
    (state) => state.forgotPassStorage.isLoading
  );

  const [email, setEmail] = useState("");
  const [data, setData] = useState({ password: "", confirmPassword: "" });
  const [smsUIFlag, setSmsUIFlag] = useState(false);

  const submitEmail = async (e) => {
    e.preventDefault();
    const res = await dispatch(forgotEmailPost(email,navigate));
    console.log(email);
    if (res.payload) {
      if (res.payload.hasOwnProperty("sms")) {
        toast.success("Email and SMS has Sent");
        localStorage.setItem("initialEmail", res.payload.email);
        setSmsUIFlag(true);
      } else {
        toast.success("Email has Sent");
      }
      setFlagForgotUI({
        ...flagForgotUI,
        otpUI: true,
      });
    }
    if (res.error) {
      console.log(res.error);
      toast.error(res.error.message, { id: 1 });
    }
  };
  console.log(email);

  const validateEmailOtp = async (e) => {
    e.preventDefault();
    if (otp.length !== 6) {
      toast.error("Enter Complete Sign-in code");
      return false;
    }
    if (!smsUIFlag) {
      const res = await dispatch(
        forgotOtpPost({ email_otp: otp, email: email },navigate)
      );
      if (res.payload) {
        toast.success("Sign-in code validated");
        setOtp("");
        setOtpUI({
          ...otpUI,
          email: false,
          sms: false,
        });
        localStorage.setItem("temp_auth_token", res.payload.temp_auth_token);
        setFlagForgotUI({
          ...flagForgotUI,
          otpUI: false,
          newPass: true,
        });
        console.log(res.payload);
      }
      if (res.error) {
        console.log(res.error);
        toast.error(res.error.message);
        setOtp("");
        setOtpUI({
          ...otpUI,
          email: true,
          sms: false,
        });
      }
    } else {
      setOtpUI({
        ...otpUI,
        email: false,
        sms: true,
      });
    }
  };

  const validateSmsOtp = async (e) => {
    e.preventDefault();
    if (otpSms.length !== 6) {
      toast.error("Enter Complete Sign-in code");
      return false;
    }
    const res = await dispatch(
      forgotOtpPost({ sms_otp: otpSms, email_otp: otp, email: email },navigate)
    );

    if (res.payload) {
      toast.success("Sign-in code validated");
      setOtp("");
      setOtpSms("");
      setOtpUI({
        ...otpUI,
        email: false,
        sms: false,
      });
      localStorage.setItem("temp_auth_token", res.payload.temp_auth_token);
      setFlagForgotUI({
        ...flagForgotUI,
        otpUI: false,
        newPass: true,
      });
      console.log(res.payload);
    }
    if (res.error) {
      console.log(res.error);
      toast.error(res.error.message);
      setOtp("");
      setOtpSms("");
      setOtpUI({
        ...otpUI,
        email: true,
        sms: false,
      });
    }
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [passData, setPassData] = useState({
    password: "",
    confirmPassword: "",
  });
  const handlePassChange = (e) => {
    setPassData({
      ...passData,
      [e.target.name]: e.target.value,
    });
  };
  const [postPassword, setPostPassword] = useState({
    confirmPassword: "",
    password: "",
  });

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const temp_auth_token = localStorage.getItem("temp_auth_token");

  const submitPassword = async (e) => {
    e.preventDefault();
    if (passData.password !== passData.confirmPassword) {
      toast.error("Password or Confirm Password Mismatch");
      return false;
    }
    console.log("password match");
    const res = await dispatch(
      forgotResetPassPost({
        password: passData.password,
        temp_auth_token: temp_auth_token,
      },navigate)
    );

    if (res.payload) {
      console.log("this is a res", res.payload);
      localStorage.removeItem("temp_auth_token");
      localStorage.setItem("role", res.payload.data.role.replace("-", ""));
      localStorage.setItem("access_token", res.payload.access_token);
      localStorage.setItem("userProf", JSON.stringify(res.payload.data));
      localStorage.setItem("user2fa", JSON.stringify(res.payload.data));
      localStorage.removeItem("initialEmail");

      if (res.payload.data.role === "admin") {
        toast.success("Login Success");
        navigate("/admin-home");
        // For Tidio start
        window.location.reload();
        // For Tidio end
      } else if (res.payload.data.role === "primary-attorney") {
        toast.success("Login Success");
        localStorage.setItem("attorney_id", res.payload.attorney_id);
        localStorage.setItem("firm_id", res.payload.firm_id);
        navigate("/firm-home");
        // For Tidio start
        window.location.reload();
        // For Tidio end
      } else if (res.payload.data.role === "attorney") {
        toast.success("Login Success");
        navigate("/attorney-home");
        localStorage.setItem("attorney_id", res.payload.attorney_id);
        localStorage.setItem("firm_id", res.payload.firm_id);
        // For Tidio start
        window.location.reload();
        // For Tidio end
      } else {
       
        localStorage.setItem(
          "client_data",
          JSON.stringify(res.payload.client_data)
        );
        navigate("/client-home");
        toast.success("Login Success");
        // For Tidio start
        window.location.reload();
        // For Tidio end
      }
    }
    if (res.error) {
      console.log("This is error 2", res);
      let err = JSON.parse(res.error.message);
      console.log(err);
      toast.error(err.detail[0], { id: 1 });
    }
  };

  return (
    <>
      <html>
        <body>
          <div className="h-screen bg-white flex justify-center items-center">
            <div className="">
              <div className="flex flex-1 bg-[#F0F2F5] lg:w-full md:w-screen">
                <div className="relative hidden h-[45.938rem] w-[28.25rem]  flex-1 lg:block ">
                  <img
                    className="absolute inset-y-0 right-0 h-full w-min object-cover"
                    src={loginImg}
                    alt="loginImg"
                  />
                </div>
                <div className="flex flex-1 flex-col justify-center w-screen px-4 py-12 sm:px-6  lg:flex-none lg:px-20 lg:w-auto  xl:px-24 ">
                  <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div className="flex flex-col">
                      <img
                        className="h-10 w-auto object-contain mb-5"
                        src={logo}
                        alt="Natl"
                      />

                      {!flagForgotUI.otpUI && !flagForgotUI.newPass ? (
                        <h2 className="mt-0 text-xl text-center font-bold  text-[#344767]">
                          Forgot Password
                        </h2>
                      ) : flagForgotUI.otpUI ? (
                        <h2 className="mt-0 text-xl text-center font-bold  text-[#344767]">
                          Forgot Password
                        </h2>
                      ) : flagForgotUI.newPass ? (
                        <h2 className="mt-0 text-xl text-center font-bold  text-[#344767]">
                          Reset Password
                        </h2>
                      ) : null}

                      {!flagForgotUI.otpUI && !flagForgotUI.newPass ? (
                        <div className="mt-0 text-sm text-center   text-[#7b809a]">
                          Enter your email
                        </div>
                      ) : flagForgotUI.otpUI ? (
                        <>
                          {otpUI.email && (
                            <div className="mt-0 text-sm text-center   text-[#7b809a]">
                              Please enter the One-Time Password from your Email
                            </div>
                          )}
                          {otpUI.sms && (
                            <div className="mt-0 text-sm text-center   text-[#7b809a]">
                              Please enter the One-Time Password from your SMS
                            </div>
                          )}
                        </>
                      ) : flagForgotUI.newPass ? (
                        <div className="mt-0 text-sm text-center  text-[#7b809a]">
                          Enter Your New Password
                        </div>
                      ) : null}
                    </div>

                    <div className="mt-10">
                      {!flagForgotUI.otpUI && !flagForgotUI.newPass ? (
                        <>
                          {postEmailLoader ? (
                            <div className="w-full flex justify-center items-center">
                              <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color="#1a3253"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            </div>
                          ) : (
                            <div>
                              <form
                                onSubmit={(e) => {
                                  submitEmail(e);
                                }}
                                className="space-y-6"
                              >
                                <div>
                                  <div className="mt-2">
                                    <input
                                      id="email"
                                      name="email"
                                      type="email"
                                      autoComplete="email"
                                      placeholder="Email"
                                      onChange={(e) => setEmail(e.target.value)}
                                      required
                                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#9ca0b3] pl-4  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                  </div>
                                </div>

                                <div>
                                  <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-[#2991e0] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#4999e0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  >
                                    SUBMIT
                                  </button>
                                </div>
                              </form>
                            </div>
                          )}
                        </>
                      ) : flagForgotUI.otpUI ? (
                        <>
                          {otpUI.email && (
                            <div>
                              <form
                                onSubmit={(e) => {
                                  validateEmailOtp(e);
                                }}
                              >
                                <div
                                  id="otpContainer"
                                  className=" h-28 flex justify-center align-center"
                                >
                                  <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    // inputType="number"
                                    numInputs={6}
                                    // renderSeparator={<span>_</span>}
                                    renderInput={(props) => (
                                      <input
                                        {...props}
                                        type="number"
                                        placeholder="-"
                                        style={{
                                          fontSize: "1.5rem",
                                          height: "3rem",
                                          width: "100%",
                                          margin: "0.2rem",
                                          padding: "0.2rem 0 0.2rem 0",
                                          textAlign: "center",
                                          borderBottom: "1px solid #376080",
                                          borderRadius: "5px",
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                                <div className="flex justify-center ">
                                  <button
                                    type="submit"
                                    className="rounded-md bg-[#2991e0] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#1991e0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </form>
                            </div>
                          )}
                          {otpUI.sms && (
                            <>
                              {putOtpLoader ? (
                                <div className="w-full flex justify-center items-center">
                                  <ThreeDots
                                    height="80"
                                    width="80"
                                    radius="9"
                                    color="#1a3253"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                </div>
                              ) : (
                                <div>
                                  <form
                                    onSubmit={(e) => {
                                      validateSmsOtp(e);
                                    }}
                                  >
                                    <div
                                      id="otpContainer"
                                      className=" h-28 flex justify-center align-center"
                                    >
                                      <OtpInput
                                        value={otpSms}
                                        onChange={setOtpSms}
                                        // inputType="number"
                                        numInputs={6}
                                        // renderSeparator={<span>_</span>}
                                        renderInput={(props) => (
                                          <input
                                            {...props}
                                            type="number"
                                            placeholder="-"
                                            style={{
                                              fontSize: "1.5rem",
                                              height: "3rem",
                                              width: "100%",
                                              margin: "0.2rem",
                                              padding: "0.2rem 0 0.2rem 0",
                                              textAlign: "center",
                                              borderBottom: "1px solid #376080",
                                              borderRadius: "5px",
                                            }}
                                          />
                                        )}
                                      />
                                    </div>
                                    <div className="flex justify-center ">
                                      <button
                                        type="submit"
                                        className="rounded-md bg-[#2991e0] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#1991e0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ) : flagForgotUI.newPass ? (
                        <>
                          {putPassLoader ? (
                            <div className="w-full flex justify-center items-center">
                              <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color="#1a3253"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            </div>
                          ) : (
                            <div>
                              <form
                                onSubmit={(e) => {
                                  submitPassword(e);
                                }}
                                className="space-y-6"
                              >
                                <div className="mb-4">
                                  <div className="mt-2 relative w-full">
                                    <input
                                      id="password"
                                      name="password"
                                      type={
                                        isPasswordVisible ? "text" : "password"
                                      }
                                      autoComplete="password"
                                      placeholder="Password"
                                      onChange={handlePassChange}
                                      required
                                      value={passData.password}
                                      className="block w-full rounded-md border py-1.5 shadow-sm  placeholder:text-[#9ca0b3] pl-4 focus:outline-none sm:text-sm sm:leading-6"
                                    />
                                    <button
                                      type="button"
                                      className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                                      onClick={() => togglePasswordVisibility()}
                                    >
                                      {isPasswordVisible ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="w-5 h-5"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="w-5 h-5"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                          />
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                          />
                                        </svg>
                                      )}
                                    </button>
                                  </div>
                                </div>
                                <div className="mb-4">
                                  <div className="mt-2 relative w-full">
                                    <input
                                      id="confirmPassword"
                                      name="confirmPassword"
                                      type={
                                        isPasswordVisible ? "text" : "password"
                                      }
                                      autoComplete=""
                                      placeholder="Confirm Password"
                                      onChange={handlePassChange}
                                      required
                                      value={passData.confirmPassword}
                                      className="block w-full rounded-md border py-1.5 shadow-sm  placeholder:text-[#9ca0b3] pl-4 focus:outline-none sm:text-sm sm:leading-6"
                                    />
                                    <button
                                      type="button"
                                      className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                                      onClick={() => togglePasswordVisibility()}
                                    >
                                      {isPasswordVisible ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="w-5 h-5"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="w-5 h-5"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                          />
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                          />
                                        </svg>
                                      )}
                                    </button>
                                  </div>
                                </div>

                                <div>
                                  <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-[#2991e0] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#4999e0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  >
                                    SUBMIT
                                  </button>
                                </div>
                              </form>
                            </div>
                          )}
                        </>
                      ) : null}
                      <div className="text-end leading-6">
                      <span className="text-sm mr-2 text-[#344767]">Already have an account</span>
                        <Link
                          to="/login"
                          className="font-semibold text-[#344767] text-base underline hover:text-[#6f7d94]"
                        >
                           Login
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </body>
      </html>
    </>
  );
}
