// check.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { domainUrl, logOutHandler } from "../../constants";
import toast from "react-hot-toast";

export const getCheckWebFormGet = createAsyncThunk(
  "getCheckWebFormGet",
  async (data,thunkAPI) => {
    try {
      const res = await axios.get(`${domainUrl}docusign-data?token=${data}`);
      console.log("this is  res", res.data);

      return res.data;
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        const navigate = thunkAPI.extra.navigateCallback;
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      } else {
        throw error.response.data.detail;
      }
    }
  }
);

const getCheckWebFormSlice = createSlice({
  name: "getCheckWebFormStorage",
  initialState: { data: [], isLoading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCheckWebFormGet.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCheckWebFormGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getCheckWebFormGet.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export const {} = getCheckWebFormSlice.actions;
export default getCheckWebFormSlice.reducer;
