import { useEffect, useMemo, useState } from "react";
import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Line, Doughnut } from "react-chartjs-2";

import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

import MaterialReactTable from "material-react-table";
import { useNavigate } from "react-router-dom";
import { domainUrl, logOutHandler } from "../../utils/constants";
import axios from "axios";
import toast from "react-hot-toast";
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function LoginHistoryTable({ clientData }) {
  const access_token = localStorage.getItem("access_token");
  const role = localStorage.getItem("role");
  const navigate = useNavigate()
  const [loginDetails, setLoginDetails] = useState([]);

  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowPerpage, setRowPerpage] = useState(5);

  const getLoginHistory = async () => {
    console.log("Debug: fetching payment events");
    try {
      // console.log("requesting..")
      const res = await axios.get(
        `${domainUrl}login-history?id=${clientData?.user?.id}&limit=${rowPerpage}&offset=${page}&sorting=[{"id":"created_time","desc":true}]`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      // console.log("called", res);
      if (res.data) {
        console.log("))))))))))))(((((((((", res.data);
        setLoginDetails(res.data);
        setCount(res.data.count);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  };

  useEffect(() => {
    if (clientData?.user) {
      getLoginHistory();
    }
  }, [clientData, page]);

  return (
    <>
      <div className="my-2">
        <div className="w-full border py-3 pl-2 rounded-xl shadow">
          <div className="pb-4 border-b border-gray-300 ">
            <div className="text-lg font-bold leading-7 text-gray-700">
              Login History
            </div>
          </div>
          {loginDetails?.data?.length ? (
            <div className="border p-3 rounded-lg shadow-md">
              <div className="relative mt-2 overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase">
                    <tr>
                      <th scope="col" className="px-6 py-3 bg-gray-50 ">
                        Login Time
                      </th>
                      <th scope="col" className="px-6 py-3 bg-gray-50 ">
                        IP Address
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Device Info
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loginDetails.data &&
                      loginDetails.data.map((log, index) => (
                        <tr
                          key={index}
                          className="w-full border-b border-gray-200"
                        >
                          <td className="min-w-[120px] px-6 py-4">{log.created_time}</td>
                          <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50">
                            {log.ip_address}
                          </td>
                          <td className="px-6 py-4">{log.user_agent}</td>
                        </tr> 
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-1 mt-2 justify-between sm:justify-end">
                <div
                  onClick={() => {
                    if (page !== 0) {
                      setPage(page - 1);
                    }
                  }}
                  className={`relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-slogbold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 ${
                    page === 0
                      ? "cursor-not-allowed opacity-50"
                      : "hover:cursor-pointer"
                  }`}
                  disabled={page === 0}
                >
                  Previous
                </div>
                <div
                  onClick={() => {
                    if (Math.ceil(count / rowPerpage) - 1 > page) {
                      setPage(page + 1);
                    }
                  }}
                  className={`relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-slogbold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 ${
                    Math.ceil(count / rowPerpage) - 1 <= page
                      ? "cursor-not-allowed opacity-50"
                      : "hover:cursor-pointer"
                  }`}
                  disabled={Math.ceil(count / rowPerpage) - 1 <= page}
                >
                  Next
                </div>
              </div>
            </div>
          ) : (
            <div className="italic text-base text-center">No data found</div>
          )}
        </div>
      </div>
    </>
  );
}
