import React, { useEffect, useState } from "react";
import { domainUrl, logOutHandler } from "../utils/constants";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const ClientAttorneyView = () => {
  const [data, setData] = useState({});
  const [globalLoading, setGlobalLoading] = useState(false);

  const navigate = useNavigate()
  const access_token = localStorage.getItem("access_token");
  const role = localStorage.getItem("role");
  const formatDate = (dateString) => {
    //   const originalDate = new Date(dateString);
    //   console.log(dateString)
    //   console.log(originalDate)
    //   const day = originalDate.getDate().toString().padStart(2, "0");
    //   const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    //   const year = originalDate.getFullYear();

    //   return `${month}/${day}/${year}`;
    console.log(dateString);
    if (dateString) {
      const date = dateString.split("-");
      console.log(date);
      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };
  function formatUSD(number) {
    // Check if the input is a valid number
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    // Format the number as USD currency
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }
  const getClientDetailsHandler = async () => {
    try {
      setGlobalLoading(true);
      const res = await axios.get(`${domainUrl}webform`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (res.data) {
        setData(res.data);

        console.log(res.data);
        setGlobalLoading(false);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler()
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setGlobalLoading(false);
    }
  };
  useEffect(() => {
    getClientDetailsHandler();
  }, []);

  return (
    <div>
      {globalLoading ? (
        <div className="w-full h-screen flex justify-center items-center">
          <Oval
            height={80}
            width={80}
            color="#1a3253"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-globalLoading"
            secondaryColor="#396181"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div className="mt-5">
          <div className="p-4">
            <div className=" md:w-full lg:w-full lg:p-0  flex items-center justify-center rounded-lg">
              <div className="border sm:w-screen bg-white  rounded-lg md:w-full lg:w-full px-8 py-8 shadow-lg w-96">
                <h2 className="text-xl font-semibold mb-4 text-[#344767]">
                  My Attorney
                </h2>
                <div className="mt-8">
                  <div className="bg-white py-4 my-6 border border-gray-200 rounded-md shadow-md">
                    <div className="max-w-7xl px-6 lg:px-8">
                      <div className="border-b pb-1 mx-auto max-w-2xl lg:mx-0">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl inline-flex">
                          {data?.contracts
                            ? `${data.contracts[0].attorney.user.first_name} ${data.contracts[0].attorney.user.last_name}`
                            : "`"}
                        </h2>
                      </div>
                      <dl className="mx-auto mt-5 grid max-w-2xl grid-cols-1 gap-x-4 gap-y-4 text-base leading-7 md:grid-cols-3 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        <div>
                          <dt className="font-semibold text-gray-900">Email</dt>
                          <dd className="mt-1 text-gray-600">
                            {data?.contracts
                              ? data.contracts[0].attorney.user.email
                              : ""}
                          </dd>
                        </div>
                        <div>
                          <dt className="font-semibold text-gray-900">
                            Primary Phone
                          </dt>
                          <dd className="mt-1 text-gray-600">
                            {data?.contracts
                              ? data.contracts[0].attorney.user
                                  .cell_phone_number
                              : ""}
                          </dd>
                        </div>
                        <div>
                          <dt className="font-semibold text-gray-900">
                            Firm Name
                          </dt>
                          <dd className="mt-1 text-gray-600">
                            {data?.contracts
                              ? data.contracts[0].attorney.firm.firm_name
                              : ""}
                          </dd>
                        </div>
                        <div>
                          <dt className="font-semibold text-gray-900">
                            Case Type
                          </dt>
                          <dd className="mt-1 text-gray-600">
                            {data?.contracts ? data.contracts[0].case_type : ""}
                          </dd>
                        </div>
                        <div>
                          <dt className="font-semibold text-gray-900">
                            Case Number
                          </dt>
                          <dd className="mt-1 text-gray-600">
                            {data?.contracts
                              ? data.contracts[0].case_number
                              : ""}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientAttorneyView;
