import React, { useState } from "react";
import { Oval, ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import logo from "../assets/img/nationalaccess-logo.png";
import loginImg from "../assets/img/login-greet.jpg";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { postPassSetupPost } from "../utils/redux/slice/postPassSetup";
import { postCheckInvitationsPost } from "../utils/redux/slice/postCheckInvitationsSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const InvitationsPage = () => {
  const [allow, setAllow] = useState(false);
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const postCheckInvitationsLoader = useSelector(
    (state) => state.postCheckInvitationsStorage.isLoading
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const token = searchParams.get("token");

  const tokenData = { token };
  const checkAllowed = async () => {
    localStorage.clear();
    const res = await dispatch(postCheckInvitationsPost(tokenData,navigate));
    if (res.payload) {
      setAllow(true);
      console.log(res.payload);
      localStorage.setItem("tempProfile", JSON.stringify(res.payload));
    }
    if (res.error) {
      console.log(res.error.message);
      toast.error(res.error.message, { id: 1 });
      navigate("/forbidden");
    }
  };

  useEffect(() => {
    checkAllowed();
  }, []);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const tempProfile = JSON.parse(localStorage.getItem("tempProfile"));
  const postPassSetupLoader = useSelector(
    (state) => state.postPassSetupStorage.isLoading
  );

  const [passData, setPassData] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setPassData({ ...passData, [e.target.name]: e.target.value });
  };

  const submitPassword = async () => {
    if (passData.password !== passData.confirmPassword) {
      toast.error("Password mismatch");
      return false;
    }
    const res = await dispatch(
      postPassSetupPost({ password: passData.password, token: tokenData.token },navigate)
    );
    if (res.payload) {
      console.log(res.payload);
      if (res.payload.access_token) {
        localStorage.setItem("role", res.payload.data.role.replace("-", ""));
        localStorage.setItem("access_token", res.payload.access_token);

        localStorage.setItem("userProf", JSON.stringify(res.payload.data));
        localStorage.setItem("user2fa", JSON.stringify(res.payload.data));
        toast.success("Login Success", { id: 1 });
        if (res.payload.data.role === "admin") {
          navigate("/admin-home");
        } else if (res.payload.data.role === "primary-attorney") {
          localStorage.setItem("attorney_id", res.payload.attorney_id);
          localStorage.setItem("firm_id", res.payload.firm_id);

          navigate("/firm-home");
        } else if (res.payload.data.role === "attorney") {
          navigate("/attorney-home");
          localStorage.setItem("attorney_id", res.payload.attorney_id);
          localStorage.setItem("firm_id", res.payload.firm_id);
        } else {
          if (res.payload.status) {
            localStorage.setItem(
              "authorize_customer_id",
              res.payload.authorize_customer_id
            );
            localStorage.setItem(
              "client_data",
              JSON.stringify(res.payload.client_data)
            );
            if (res.payload.status === "invitation-accepted") {
              localStorage.setItem("web_form", true);
              localStorage.setItem("client_status", true);
              navigate("/client-home");
            }
          } else {
            navigate("/client-home");
          }
        }
      } else {
        console.log("this is a res", res.payload);
      }
    }
    if (res.error) {
      console.log(res.error.message);
      let err = JSON.parse(res.error.message);
      toast.error(err.detail[0]);
    }
  };

  return (
    <div>
      {postCheckInvitationsLoader ? (
        <div className="w-full h-screen flex justify-center items-center">
          <Oval
            height={80}
            width={80}
            color="#1a3253"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#396181"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div className="h-screen bg-white flex justify-center items-center">
          {allow && (
            <div className="">
              <div className="flex flex-1 bg-[#F0F2F5] lg:w-full md:w-screen">
                <div className="relative hidden h-[45.938rem] w-[28.25rem]  flex-1 lg:block ">
                  <img
                    className="absolute inset-y-0 right-0 h-full w-min object-cover"
                    src={loginImg}
                    alt="loginImg"
                  />
                </div>
                <div className="flex flex-1 flex-col justify-center w-screen px-4 py-12 sm:px-6  lg:flex-none lg:px-20 lg:w-auto  xl:px-24 ">
                  <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div className="flex flex-col">
                      <img
                        className="h-10 w-auto object-contain mb-5"
                        src={logo}
                        alt="Natl"
                      />

                      <h2 className="mt-0 text-xl text-center font-bold  text-[#344767]">
                        {`Hi, ${tempProfile && tempProfile.first_name}`}
                      </h2>

                      <div className="mt-0 text-sm text-center   text-[#7b809a]">
                        Please enter Password to setup login
                      </div>
                    </div>

                    <div className="mt-10">
                      <>
                        {postPassSetupLoader ? (
                          <div className="w-full flex justify-center items-center">
                            <ThreeDots
                              height="80"
                              width="80"
                              radius="9"
                              color="#1a3253"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          // <div>
                          //   <form
                          //     onSubmit={(e) => {
                          //       submitPassword(e);
                          //     }}
                          //     className="space-y-6"
                          //   >
                          //     <div>
                          //       <div className="mt-2">
                          //         <input
                          //           id="password"
                          //           name="password"
                          //           type="text"
                          //           autoComplete="password"
                          //           placeholder="Password"
                          //           onChange={handleChange}
                          //           required
                          //           className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#9ca0b3] pl-4  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          //         />
                          //       </div>
                          //     </div>
                          //     <div>
                          //       <div className="mt-2">
                          //         <input
                          //           id="confirmPassword"
                          //           name="confirmPassword"
                          //           type="text"
                          //           autoComplete="confirmPassword"
                          //           placeholder="Confirm New Password"
                          //           onChange={handleChange}
                          //           required
                          //           className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#9ca0b3] pl-4  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                          //         />
                          //       </div>
                          //     </div>

                          //     <div>
                          //       <button
                          //         type="submit"
                          //         className="flex w-full justify-center rounded-md bg-[#2991e0] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#4999e0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          //       >
                          //         SUBMIT
                          //       </button>
                          //     </div>
                          //   </form>
                          // </div>

                          <div>
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                submitPassword(e);
                              }}
                              className="space-y-6"
                            >
                              <div className="mb-4">
                                <div className="mt-2 relative w-full">
                                  <input
                                    id="password"
                                    name="password"
                                    type={
                                      isPasswordVisible ? "text" : "password"
                                    }
                                    autoComplete="password"
                                    placeholder="Password"
                                    onChange={handleChange}
                                    required
                                    value={passData.password}
                                    className="block w-full rounded-md border py-1.5 shadow-sm  placeholder:text-[#9ca0b3] pl-4 focus:outline-none sm:text-sm sm:leading-6"
                                  />
                                  <button
                                    type="button"
                                    className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                                    onClick={() => togglePasswordVisibility()}
                                  >
                                    {isPasswordVisible ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-5 h-5"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-5 h-5"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                      </svg>
                                    )}
                                  </button>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="mt-2 relative w-full">
                                  <input
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type={
                                      isPasswordVisible ? "text" : "password"
                                    }
                                    autoComplete=""
                                    placeholder="Confirm Password"
                                    onChange={handleChange}
                                    required
                                    value={passData.confirmPassword}
                                    className="block w-full rounded-md border py-1.5 shadow-sm  placeholder:text-[#9ca0b3] pl-4 focus:outline-none sm:text-sm sm:leading-6"
                                  />
                                  <button
                                    type="button"
                                    className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                                    onClick={() => togglePasswordVisibility()}
                                  >
                                    {isPasswordVisible ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-5 h-5"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-5 h-5"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                      </svg>
                                    )}
                                  </button>
                                </div>
                              </div>

                              <div>
                                <button
                                  type="submit"
                                  className="flex w-full justify-center rounded-md bg-[#2991e0] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#4999e0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                  SUBMIT
                                </button>
                              </div>
                            </form>
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default InvitationsPage;
