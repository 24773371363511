import React from 'react'
import EscrowHistoryTable from './Tables/EscrowHistoryTable'

const EscrowHistory = () => {
  return (
    <>
      <EscrowHistoryTable/>
    </>
  )
}

export default EscrowHistory
