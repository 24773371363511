import React, { useCallback, useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import ModalComponent from "../../Components/ModalComponent";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import {
  PlusIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { domainUrl, logOutHandler } from "../../utils/constants";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import amex from "../../Images/cards/amex.png";
import bankImg from "../../Images/cards/bank_img.jpeg";
import addcardImg from "../../Images/cards/addcard.png";
import american_express from "../../Images/cards/american_express.png";
import carteblanche from "../../Images/cards/carteblanche.png";
import celo from "../../Images/cards/celo.png";
import dankort from "../../Images/cards/dankort.png";
import diners from "../../Images/cards/diners.png";
import discover from "../../Images/cards/discover.png";
import forbrugsforeningen from "../../Images/cards/forbrugsforeningen.jpg";
import hipercard from "../../Images/cards/hipercard.svg";
import instapayment from "../../Images/cards/instapayment.webp";
import jcb from "../../Images/cards/jcb.png";
import laser from "../../Images/cards/laser.png";
import mastercard from "../../Images/cards/mastercard.png";
import mnp from "../../Images/cards/mnp.png";
import nocards from "../../Images/cards/nocards.jpg";
import onpay from "../../Images/cards/onpay.png";
import rupay from "../../Images/cards/rupay.png";
import troy from "../../Images/cards/troy.png";
import uatp from "../../Images/cards/uatp.png";
import visa from "../../Images/cards/visa.png";
import visa_electron from "../../Images/cards/visa_electron.png";
import vpay from "../../Images/cards/vpay.png";
import toast from "react-hot-toast";
import { Oval, RotatingLines, ThreeDots } from "react-loader-spinner";
import { PlaidLink } from "react-plaid-link";
import CurrencyFormat from "react-currency-format";
import ClientScheduleTable from "../Tables/ClientScheduleTable";

const ClientDashboard = () => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const navigate = useNavigate();
  const client_data = JSON.parse(localStorage.getItem("client_data"));
  const [paymentSelectFlag, setPaymentSelectFlag] = useState(false);
  const [paymentAddFlag, setPaymentAddFlag] = useState(false);
  const [selectedAddOption, setSelectedAddOption] = useState("");
  const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false);
  const selectedPay = localStorage.getItem("selectedPay");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    selectedPay ? selectedPay : ""
  );

  const [grantPlaidAccess, setGrantPlaidAccess] = useState(false);
  const [plaidToken, setPlaidToken] = useState(null);

  const createLinkToken = async () => {
    const res = await axios.post(`${domainUrl}plaid/create_link_token`);

    console.log(res);
    setPlaidToken(res.data.link_token);
    setGrantPlaidAccess(true);
    console.log("Plaid access granted");
  };

  const onSuccess = useCallback(async (public_token, metadata) => {
    // send public_token to server
    console.log("Public Token Recieved", public_token);
    try {
      const res = await axios.post(
        `${domainUrl}plaid/set_access_token`,
        { public_token },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      console.log(res);
      setGrantPlaidAccess(false);
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  }, []);

  const handleAddPaymentChange = (e) => {
    console.log(e);
    setSelectedAddOption(e);
    setOpenAddPaymentModal(true);
    setPaymentAddFlag(false);
  };

  const handleSelectionPay = async (account) => {
    console.log(account);
    localStorage.setItem("selectedPay", account.customer_payment_method_id);
    setSelectedPaymentMethod(account.id);
    setSelectedMethod(account.type);
    setSelectedMethodId(account.customer_payment_method_id);
    setSelectedMethodLabel(account.description);
    if (
      client_data.preferred_payment_autopay &&
      client_data.preferred_payment_method == account.customer_payment_method_id
    ) {
      setSelectedCheckbox("yes");
    } else {
      setSelectedCheckbox("");
    }
  };

  const [accountsDetail, setAccountsDetail] = useState([]);

  const filterPositiveDecimal = (value) => {
    var numericString = value.replace(/[^0-9]/g, "");
    return numericString;
  };
  const formatCardNumber = (value = "") => {
    // Remove any non-numeric characters
    const numericValue = value.replace(/[^0-9]+/g, "");

    // Ensure the total length does not exceed 18 characters
    if (numericValue.length > 20) {
      return numericValue.slice(0, 20);
    }

    // Format the number with spaces every 4 digits
    const formattedNumber = numericValue.replace(/(\d{4})/g, "$1 ");

    // Trim any leading or trailing spaces
    return formattedNumber.trim();
    // handleChange(formattedObject);
  };
  const formatAlphabetsWithSpace = (value = "") => {
    let formattedAlphabets;
    const { length } = value;
    // Filter non-alphabets and spaces
    const regex = () => value.replace(/[^a-zA-Z\s]+/g, "");

    // New validation
    if (length >= 0) {
      formattedAlphabets = regex().toUpperCase();
    }

    return formattedAlphabets;
  };
  function formatUSD(number) {
    // Check if the input is a valid number
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    // Format the number as USD currency
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }
  const formatDate = (dateString) => {
    //   const originalDate = new Date(dateString);
    //   console.log(dateString)
    //   console.log(originalDate)
    //   const day = originalDate.getDate().toString().padStart(2, "0");
    //   const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    //   const year = originalDate.getFullYear();

    //   return `${month}/${day}/${year}`;
    console.log(dateString);
    if (dateString) {
      const date = dateString.split("-");
      console.log(date);
      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };

  const access_token = localStorage.getItem("access_token");
  const authorize_customer_id = localStorage.getItem("authorize_customer_id");

  const [clientData, setClientData] = useState({});
  const [cardAchLoader, setCardAchLoader] = useState(false);
  const [cardAchDirectLoader, setCardAchDirectLoader] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [paymentScheduledDetails, setPaymentScheduledDetails] = useState();
  const [amountType, setAmountType] = useState("default");
  const [selectedMethod, setSelectedMethod] = useState("");
  const [selectedMethodId, setSelectedMethodId] = useState("");
  const [selectedMethodLabel, setSelectedMethodLabel] = useState("");
  const [payableAmount, setPayableAmount] = useState("");
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [confirmPageFlag, setConfirmPageFlag] = useState(false);
  const [transactionFlag, setTransactionFlag] = useState(false);

  const handleCheckboxChange = (checkboxValue) => {
    setSelectedCheckbox(checkboxValue);
  };

  const getNextScheduledDetails = async () => {
    try {
      setGlobalLoading(true);
      const res = await axios.get(`${domainUrl}upcoming-payment`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (res.data) {
        console.log(res.data);
        setPaymentScheduledDetails(res.data);
        setPayableAmount(
          res.data.total_payable
            ? res.data.total_payable
            : res.data.upcoming_payment?.balance_to_pay
        );
        setGlobalLoading(false);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setGlobalLoading(false);
    }
  };

  const getClientDetailsHandler = async () => {
    try {
      setGlobalLoading(true);
      const res = await axios.get(`${domainUrl}webform`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (res.data) {
        // setData(res.data.contracts[0].payment_schedule);
        // setRowCount(res.data.contracts[0].payment_schedule_count);
        setClientData(res.data);

        console.log(res.data);
        setGlobalLoading(false);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setGlobalLoading(false);
    }
  };
  useEffect(() => {
    getClientDetailsHandler();
    getNextScheduledDetails();
    return () => {
      localStorage.removeItem("selectedPay");
    };
  }, []);

  const getPaymentDueDetails = async (method) => {
    try {
      const res = await axios.get(
        `${domainUrl}payment-info?customer_authorize_id=${authorize_customer_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        const creditCardData = res.data.credit_card;
        const bankAccountData = res.data.bank_account;

        // Map credit card data
        const creditCardDetails = creditCardData.map((card, index) => ({
          id: index,
          name: card.card_type,
          description: `${card.card_number}`,
          type: "card",
          customer_payment_method_id: card.payment_method_id,
        }));

        // Map bank account data
        const bankAccountDetails = bankAccountData.map((account, index) => ({
          id: index + creditCardData.length, // Start index from creditCardData.length to avoid id conflicts
          name: account.account_type,
          description: `${account.routing_number}`,
          type: "ach",
          customer_payment_method_id: account.payment_method_id,
        }));

        // Combine both credit card and bank account details
        // const combinedDetails = [...creditCardDetails, ...bankAccountDetails];
        const combinedDetails = [...bankAccountDetails, ...creditCardDetails];

        // Set the state with combined details
        setAccountsDetail(combinedDetails);

        console.log(accountsDetail);
        if (method == "card") {
          return creditCardData;
        } else {
          return bankAccountData;
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      // alert("Got gateway error");
    }
  };
  useEffect(() => {
    getPaymentDueDetails();
  }, [paymentAddFlag]);

  const [typingTimeout, setTypingTimeout] = useState(0);
  const [data, setData] = useState({
    cardholder: "",
    card_number: "",
    bank_account_number: "",
    bank_routing_number: "",
    expiration_date: "",
    cvv: "",
    zip_code: "",
    accountholder: "",
    bank_name: "",
  });
  const [errorFlag, setErrorFlag] = useState({
    cardholder: "",
    card_number: "",
    bank_account_number: "",
    bank_routing_number: "",
    expiration_date: "",
    cvv: "",
    zip_code: "",
    accountholder: "",
    bank_name: "",
  });
  const [cardDetails, setCardDetails] = useState({
    cardExpiryMonth: "",
    cardExpiryYear: "",
  });
  const [cardDetailsError, setCardDetailsError] = useState({
    cardExpiryMonth: "",
    cardExpiryYear: "",
  });
  const length_validate = {
    zip_code: 5,
    card_number: { min: 16, max: 16 },
    bank_account_number: { min: 5, max: 17 },
    bank_routing_number: 9,
    cvv: 4,
  };
  const errMsg = {
    zip_code: "Zipcode should be 5 digit",
    cvv: "CVV should be 3 or 4 digit",
    card_number: "Card number must be 16 digit.",
    bank_account_number:
      "Bank account number needs to be between 12 and 17 digits.",
    bank_routing_number: "Bank routing number should be 9 digit.",
  };

  const filterNumberLength = (value, max) => {
    const numericValue = value.replace(/[^0-9]/g, "");

    if (numericValue.length > max) {
      return numericValue.slice(0, max);
    }
    return numericValue;
  };
  function formatAlphabetsAndSpaces(input) {
    // Use a regular expression to match only alphabets and spaces
    const regex = /[^a-zA-Z ]/g;
    // Replace any characters that don't match the regex with an empty string
    const formattedString = input.replace(regex, "");

    return formattedString;
  }
  function extractDateFromTimestamp(timestamp) {
    const dateObject = new Date(timestamp);
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 as getMonth() returns 0-based index
    const day = dateObject.getDate().toString().padStart(2, "0");
    const year = dateObject.getFullYear();
    return `${month}/${day}/${year}`;
  }

  const formHandler = async (e) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setErrorFlag((prevErrorFlag) => ({
      ...prevErrorFlag,
      [e.target.name]: "",
    }));
    if (
      e.target.name == "cardExpiryMonth" ||
      e.target.name == "cardExpiryYear"
    ) {
      if (e.target.name == "cardExpiryMonth") {
        setCardDetailsError({
          ...cardDetailsError,
          cardExpiryMonth: "",
        });
      } else {
        setCardDetailsError({
          ...cardDetailsError,
          cardExpiryYear: "",
        });
      }
      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        ["expiration_date"]: "",
      }));
    }

    if (e.target.name == "cardholder") {
      setData((prevValue) => ({
        ...prevValue,
        [e.target.name]: formatAlphabetsAndSpaces(e.target.value),
      }));
    } else if (e.target.name == "card_number") {
      setData((prevValue) => ({
        ...prevValue,
        [e.target.name]: e.target.value,
      }));
    } else if (e.target.name == "cardExpiryMonth") {
      const currentYear = new Date().getFullYear() % 100;
      const currentMonth = new Date().getMonth() + 1;
      const monthValue = parseInt(e.target.value, 10);
      const yearValue = parseInt(cardDetails.cardExpiryYear, 10);
      if (
        monthValue == 0 ||
        monthValue > 12 ||
        (currentYear == cardDetails.cardExpiryYear && monthValue < currentMonth)
      ) {
        // toast.error("Invalid expiry Month",{id:1});
        // return;
        setCardDetailsError({
          ...cardDetailsError,
          cardExpiryMonth: "*invalid month",
        });
      }
      if (
        String(yearValue).length == 2 &&
        yearValue <= currentYear % 100 &&
        parseInt(e.target.value, 10) <= currentMonth
      ) {
        setErrorFlag((prevValue) => ({
          ...prevValue,
          expiration_date: "*enter a valid expiry date",
        }));
      }
      setCardDetails({
        ...cardDetails,
        cardExpiryMonth: e.target.value,
      });

      setData((prevValue) => ({
        ...prevValue,
        expiration_date: `${e.target.value}/${cardDetails.cardExpiryYear}`,
      }));
    } else if (e.target.name == "cardExpiryYear") {
      const currentYear = new Date().getFullYear() % 100;
      const currentMonth = new Date().getMonth() + 1;
      const yearValue = parseInt(e.target.value, 10);
      if (
        e.target.value.length == 2 &&
        parseInt(e.target.value) <
          parseInt(new Date().getFullYear().toString().slice(-2))
      ) {
        // toast.error("Invalid expiry Year",{id:1});
        // return
        setCardDetailsError({
          ...cardDetailsError,
          cardExpiryYear: "*invalid year",
        });
      }
      if (
        String(yearValue).length == 2 &&
        yearValue <= currentYear % 100 &&
        parseInt(cardDetails.cardExpiryMonth, 10) <= currentMonth
      ) {
        setErrorFlag((prevValue) => ({
          ...prevValue,
          expiration_date: "*enter a valid expiry date",
        }));
      }
      setCardDetails({
        ...cardDetails,
        cardExpiryYear: e.target.value,
      });
      setData((prevValue) => ({
        ...prevValue,
        expiration_date: `${cardDetails.cardExpiryMonth}/${e.target.value}`,
      }));
      console.log(
        parseInt(e.target.value) <
          parseInt(new Date().getFullYear().toString().slice(-2))
      );
    } else if (e.target.name == "bank_account_number") {
      setData((prevValue) => ({
        ...prevValue,
        [e.target.name]: filterNumberLength(
          e.target.value,
          length_validate[e.target.name].max
        ),
      }));
    } else if (e.target.name == "bank_routing_number") {
      setData((prevValue) => ({
        ...prevValue,
        [e.target.name]: filterNumberLength(
          e.target.value,
          length_validate[e.target.name]
        ),
      }));
    } else if (e.target.name == "zip_code") {
      setData((prevValue) => ({
        ...prevValue,
        [e.target.name]: filterNumberLength(
          e.target.value,
          length_validate[e.target.name]
        ),
      }));
    } else if (e.target.name == "cvv") {
      setData((prevValue) => ({
        ...prevValue,
        [e.target.name]: filterNumberLength(
          e.target.value,
          length_validate[e.target.name]
        ),
      }));
    } else if (e.target.name == "accountholder") {
      setData((prevValue) => ({
        ...prevValue,
        [e.target.name]: formatAlphabetsAndSpaces(e.target.value),
      }));
    } else if (e.target.name == "bank_name") {
      setData((prevValue) => ({
        ...prevValue,
        [e.target.name]: formatAlphabetsAndSpaces(e.target.value),
      }));
    }

    setTypingTimeout(setTimeout(async () => validateFormFields(e), 300));
  };

  const validateFormFields = async (e) => {
    if (e.target.name == "card_number") {
      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        [e.target.name]:
          e.target.value.length != 19 ? errMsg[e.target.name] : "",
      }));
    } else if (e.target.name == "bank_account_number") {
      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        [e.target.name]:
          e.target.value.length > 17 || e.target.value.length < 12
            ? errMsg[e.target.name]
            : "",
      }));
    } else if (e.target.name == "bank_routing_number") {
      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        [e.target.name]:
          e.target.value.length != 9 ? errMsg[e.target.name] : "",
      }));
    } else if (e.target.name == "zip_code") {
      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        [e.target.name]:
          e.target.value.length != 5 ? errMsg[e.target.name] : "",
      }));
    } else if (e.target.name == "cvv") {
      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        [e.target.name]: e.target.value.length < 3 ? errMsg[e.target.name] : "",
      }));
    }
  };

  const validateFormFieldsForCardSubmit = () => {
    for (const key in data) {
      if (
        key !== "bank_account_number" &&
        key !== "bank_routing_number" &&
        key !== "accountholder" &&
        key !== "bank_name" &&
        data[key] === ""
      ) {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          [key]: `*${key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (match) => match.toUpperCase())} is required`,
        }));
      }
    }
    for (const key in cardDetails) {
      if (cardDetails[key] === "") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          expiration_date: "Expiry date is required",
        }));
      }
    }
    for (const key in data) {
      if (
        key !== "bank_account_number" &&
        key !== "bank_routing_number" &&
        key !== "accountholder" &&
        key !== "bank_name" &&
        data[key] === ""
      ) {
        return true;
      }
    }
    for (const key in cardDetails) {
      if (cardDetails[key] === "") {
        return true;
      }
    }
    const cardholderError = errorFlag.cardholder !== "";
    const cardNumberError = errorFlag.card_number !== "";
    const expiryMonthError = cardDetailsError.cardExpiryMonth !== "";
    const expiryYearError = cardDetailsError.cardExpiryYear !== "";
    const expiryError = errorFlag.expiration_date !== "";
    const cvvError = errorFlag.cvv !== "";
    const zipError = errorFlag.zip_code !== "";
    const expiryDateError = errorFlag.expiration_date !== "";

    console.log(cardDetailsError, errorFlag);

    // Return true if any of the fields have errors, otherwise return false
    return (
      cardholderError ||
      cardNumberError ||
      expiryMonthError ||
      expiryYearError ||
      expiryError ||
      cvvError ||
      zipError ||
      expiryDateError
    );
  };

  const postGatewayCardData = async (type) => {
    const hasErrors = validateFormFieldsForCardSubmit();

    // If there are errors, display an error message and return
    if (hasErrors) {
      toast.error("Complete the fields", { id: 1 });
      return;
    }
    console.log(data);

    if (type == "save") {
      try {
        setCardAchLoader(true);
        const res = await axios.post(
          `${domainUrl}payment-info`,
          {
            type: "card",
            customer_authorize_id: authorize_customer_id,
            data: {
              card_number: data.card_number.replace(/\s/g, ""),
              expiration_date: `20${cardDetails.cardExpiryYear}-${cardDetails.cardExpiryMonth}`,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (res.data) {
          console.log(res.data);
          toast.success(res.data?.message, { id: 1 });
          setOpenAddPaymentModal(false);
          setPaymentAddFlag(false);
          getPaymentDueDetails();
          setData({
            cardholder: "",
            card_number: "",
            bank_account_number: "",
            bank_routing_number: "",
            expiration_date: "",
            cvv: "",
            zip_code: "",
            accountholder: "",
            bank_name: "",
          });
          setCardDetails({
            cardExpiryMonth: "",
            cardExpiryYear: "",
          });

          setSelectedAddOption("");
          setCardAchLoader(false);
          // alert("Got gateway response")
        }
      } catch (error) {
        console.log(error);
        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        toast.error(error.response.data.detail, { id: 1 });
        setCardAchLoader(false);
        // alert("Got gateway error")
      }
    } else {
      try {
        setCardAchDirectLoader(true);
        const res = await axios.post(
          `${domainUrl}payment-info`,
          {
            type: "card",
            customer_authorize_id: authorize_customer_id,
            data: {
              card_number: data.card_number.replace(/\s/g, ""),
              expiration_date: `20${cardDetails.cardExpiryYear}-${cardDetails.cardExpiryMonth}`,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (res.data) {
          console.log(res.data);
          toast.success(res.data?.message, { id: 1 });
          let cardGetDetails = await getPaymentDueDetails("card");
          setSelectedAddOption("");
          setCardAchDirectLoader(false);
          postTransactionGateway(type, cardGetDetails);
        }
      } catch (error) {
        console.log(error);
        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        toast.error(error.response.data.detail, { id: 1 });
        setCardAchDirectLoader(false);
      }
    }
  };

  const validateFormFieldsForAchSubmit = () => {
    for (const key in data) {
      if (
        key !== "cardholder" &&
        key !== "card_number" &&
        key !== "expiration_date" &&
        key !== "cvv" &&
        key !== "zip_code" &&
        data[key] === ""
      ) {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          [key]: `*${key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (match) => match.toUpperCase())} is required`,
        }));
      }
    }
    for (const key in data) {
      if (
        key !== "cardholder" &&
        key !== "card_number" &&
        key !== "expiration_date" &&
        key !== "cvv" &&
        key !== "zip_code" &&
        data[key] === ""
      ) {
        return true;
      }
    }

    const bankAccountError = errorFlag.bank_account_number !== "";
    const bankRoutingError = errorFlag.bank_routing_number !== "";
    const holderError = errorFlag.accountholder !== "";
    const bankNameError = errorFlag.bank_name !== "";

    console.log(errorFlag);

    // Return true if any of the fields have errors, otherwise return false
    return bankAccountError || bankRoutingError || holderError || bankNameError;
  };

  const postGatewayAchData = async (type) => {
    const hasErrors = validateFormFieldsForAchSubmit();

    // If there are errors, display an error message and return
    if (hasErrors) {
      toast.error("Complete the fields", { id: 1 });
      return;
    }
    console.log(data);

    if (type == "save") {
      try {
        setCardAchLoader(true);
        const res = await axios.post(
          `${domainUrl}payment-info`,
          {
            type: "ach",
            customer_authorize_id: authorize_customer_id,
            data: {
              bank_routing_number: `${data.bank_routing_number}`,
              bank_account_number: `${data.bank_account_number}`,
              name: `${data.accountholder}`,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (res.data) {
          console.log(res.data);
          if (res.data) {
            toast.success(res.data.message, { id: 1 });
            setOpenAddPaymentModal(false);
            setPaymentAddFlag(false);
            getPaymentDueDetails();
            setData({
              cardholder: "",
              card_number: "",
              bank_account_number: "",
              bank_routing_number: "",
              expiration_date: "",
              cvv: "",
              zip_code: "",
              accountholder: "",
              bank_name: "",
            });
            setSelectedAddOption("");
            setCardAchLoader(false);
          }
        }
      } catch (error) {
        console.log(error);
        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        toast.error(error.response.data.detail, { id: 1 });
        setCardAchLoader(false);
        // alert("Got gateway error")
      }
    } else {
      try {
        setCardAchDirectLoader(true);
        const res = await axios.post(
          `${domainUrl}payment-info`,
          {
            type: "ach",
            customer_authorize_id: authorize_customer_id,
            data: {
              bank_routing_number: `${data.bank_routing_number}`,
              bank_account_number: `${data.bank_account_number}`,
              name: `${data.accountholder}`,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (res.data) {
          console.log(res.data);
          if (res.data) {
            toast.success(res.data.message, { id: 1 });
            let achGetDetails = await getPaymentDueDetails("ach");
            setSelectedAddOption("");
            setCardAchDirectLoader(false);
            postTransactionGateway(type, achGetDetails);
          }
        }
      } catch (error) {
        console.log(error);
        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        toast.error(error.response.data.detail, { id: 1 });
        setCardAchDirectLoader(false);
      }
    }
  };

  const postTransactionGateway = async (type, methodGetDetails) => {
    if (type == "save") {
      try {
        setTransactionFlag(true);
        const res = await axios.post(
          `${domainUrl}transactions`,
          {
            customer_authorize_id: authorize_customer_id,
            customer_payment_method_id: selectedMethodId,
            amount: String(payableAmount).replace(/[$,]/g, ""),
            autopay: selectedCheckbox == "yes" ? true : false,
            type: selectedMethod,
            label: selectedMethodLabel,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (res.data) {
          console.log(res.data);
          getClientDetailsHandler();
          getNextScheduledDetails();
          // getPaymentDueDetails()
          client_data.preferred_payment_method = selectedMethodId;
          client_data.preferred_payment_autopay =
            selectedCheckbox == "yes" ? true : false;
          localStorage.setItem("client_data", JSON.stringify(client_data));
          setTransactionFlag(false);
          toast.success(res.data.message, { id: 1 });
          localStorage.removeItem("selectedPay");
          setSelectedPaymentMethod("");
          setPaymentSelectFlag(false);
          setConfirmPageFlag(false);
          setPayableAmount("");
          setSelectedCheckbox(null);
          setSelectedMethod("");
          setSelectedMethodId("");
          setAmountType("default");
        }
      } catch (error) {
        console.log(error);
        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setTransactionFlag(false);
        toast.error(error.response.data.detail, { id: 1 });
        if (error.response.data.unverified) {
          createLinkToken();
        }
      }
    } else {
      try {
        let parameter = {};
        if (methodGetDetails[0].hasOwnProperty("routing_number")) {
          parameter = {
            customer_authorize_id: authorize_customer_id,
            customer_payment_method_id: methodGetDetails[0].payment_method_id,
            amount: String(payableAmount).replace(/[$,]/g, ""),
            autopay: false,
            type: "ach",
            label: methodGetDetails[0].routing_number,
          };
        } else {
          parameter = {
            customer_authorize_id: authorize_customer_id,
            customer_payment_method_id: methodGetDetails[0].payment_method_id,
            amount: String(payableAmount).replace(/[$,]/g, ""),
            autopay: false,
            type: "card",
            label: methodGetDetails[0].card_number,
          };
        }

        setTransactionFlag(true);
        const res = await axios.post(`${domainUrl}transactions`, parameter, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });
        if (res.data) {
          console.log(res.data);
          getClientDetailsHandler();
          getNextScheduledDetails();
          client_data.preferred_payment_method =
            methodGetDetails[0].customer_payment_method_id;
          client_data.preferred_payment_autopay = false;
          localStorage.setItem("client_data", JSON.stringify(client_data));
          toast.success(res.data.message, { id: 1 });
          localStorage.removeItem("selectedPay");
          setSelectedPaymentMethod("");
          setPaymentSelectFlag(false); //not for admin page
          setConfirmPageFlag(false);
          setPayableAmount("");
          setAmountType("default");
          setOpenAddPaymentModal(false);
          setData({
            cardholder: "",
            card_number: "",
            bank_account_number: "",
            bank_routing_number: "",
            expiration_date: "",
            cvv: "",
            zip_code: "",
            accountholder: "",
            bank_name: "",
          });
          setCardDetails({
            cardExpiryMonth: "",
            cardExpiryYear: "",
          });
          setTransactionFlag(false);
        }
      } catch (error) {
        console.log(error);
        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setTransactionFlag(false);

        toast.error(error.response.data.detail, { id: 1 });
        if (error.response.data.unverified) {
          createLinkToken();
        }
      }
    }
  };

  if (transactionFlag) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <div className="flex flex-col justify-center items-center">
          <div>
            <RotatingLines
              visible={true}
              height="96"
              width="96"
              color="#1a3253"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
          <div className="italic">Transaction is being processed..</div>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      {globalLoading ? (
        <div className="w-full h-screen flex justify-center items-center">
          <Oval
            height={80}
            width={80}
            color="#1a3253"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-globalLoading"
            secondaryColor="#396181"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div className="pt-10">
          <motion.div
            initial={{ y: 100 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.1, type: "spring", stiffness: 120 }}
          >
            {!paymentSelectFlag ? (
              <div>
                <div className="pb-10 w-full sm:flex justify-evenly gap-5">
                  <div className="w-full">
                    <div className="w-full  flex  flex-col items-center bg-white border border-gray-200 rounded-lg shadow ">
                      <div className="w-full flex flex-col justify-between px-4 py-2 leading-normal">
                        <div className="w-full flex justify-between items-center mb-4 border-b border-[#f2f4f6] ">
                          <div className="mt-[-3.5rem] ml-[-0.5rem] h-16 w-16 flex justify-center items-center ">
                            <svg
                              className="w-12 h-12"
                              viewBox="0 0 57 59"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.987793"
                                width="55.4146"
                                height="58.7699"
                                rx="12"
                                fill="url(#paint0_linear_482_6587)"
                              />
                              <path
                                d="M28.6951 44.8114C36.7286 44.8114 43.2407 37.9049 43.2407 29.385C43.2407 20.8651 36.7284 13.9586 28.6951 13.9586C20.6618 13.9586 14.1494 20.8653 14.1494 29.385C14.1494 37.9047 20.6618 44.8114 28.6951 44.8114ZM22.431 35.7419L23.1469 34.0529C23.2351 33.8439 23.401 33.6844 23.6035 33.6074C23.6867 33.5799 23.7694 33.5634 23.8524 33.5634C23.9818 33.5634 24.1066 33.5964 24.2204 33.6626C25.3097 34.2785 26.7727 34.6474 28.1317 34.6474C29.7292 34.6474 30.8914 33.9543 30.8914 33.0026C30.8914 32.3864 30.5539 31.6435 28.0693 30.7854C25.3772 29.8889 22.6329 28.5794 22.6329 25.6417C22.6329 23.4135 24.1631 21.6696 26.7257 20.9819L27.1146 20.8771V19.034C27.1146 18.5828 27.4671 18.214 27.8928 18.214H29.7343C30.1648 18.214 30.5125 18.5825 30.5125 19.034V20.6899L30.9691 20.7505C32.0636 20.8878 33.0336 21.1739 33.9259 21.6198C34.1125 21.7134 34.2581 21.8784 34.3252 22.0875C34.3978 22.2909 34.3875 22.5221 34.2993 22.7144L33.5573 24.3812C33.4278 24.6673 33.1527 24.8542 32.8518 24.8542C32.7426 24.8542 32.634 24.8268 32.5299 24.7772C32.0474 24.5406 30.9167 23.9958 29.1115 23.9958C27.4722 23.9958 26.7407 24.667 26.7407 25.3385C26.7407 26.0867 27.5033 26.5928 29.8894 27.4293C32.4311 28.3093 35.0249 29.6354 35.0249 32.8262C35.0249 34.5482 33.8992 36.8644 30.7244 37.7116L30.3355 37.8214V39.7304C30.3355 40.1869 29.983 40.5557 29.5573 40.5557H27.6846C27.2541 40.5557 26.9064 40.1871 26.9064 39.7304V37.9808L26.4448 37.9312C25.0129 37.7608 23.6644 37.348 22.7566 36.7926C22.4051 36.5833 22.2704 36.1324 22.431 35.742L22.431 35.7419Z"
                                fill="white"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_482_6587"
                                  x1="28.6951"
                                  y1="0"
                                  x2="28.6951"
                                  y2="58.7699"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#3E3D45" />
                                  <stop offset="1" stop-color="#202020" />
                                </linearGradient>
                              </defs>
                            </svg>
                          </div>

                          <div className="w-full flex justify-end">
                            <div className="font-bold rounded-md py-3 px-2 text-black text-xl mb-2">
                              Current Payment
                            </div>
                          </div>
                        </div>
                        <div className="w-full flex justify-end">
                        {paymentScheduledDetails?.upcoming_payment?.balance_to_pay && ( <button
                            onClick={() => {
                              setPaymentSelectFlag(true);
                              setPayableAmount(
                                paymentScheduledDetails &&
                                  (paymentScheduledDetails.total_payable
                                    ? formatUSD(
                                        paymentScheduledDetails.total_payable
                                      )
                                    : formatUSD(
                                        paymentScheduledDetails.upcoming_payment
                                          ?.balance_to_pay
                                      ))
                              );
                            }}
                            type="button"
                            // className="bg-green-600 font-bold rounded-md py-3 px-10 text-white text-lg mb-2 hover:shadow-lg"
                            className={`font-bold rounded-md py-3 px-10 text-white text-lg mb-2 hover:shadow-lg ${
                              paymentScheduledDetails?.due_payments?.length >= 2
                                ? "bg-red-600"
                                : paymentScheduledDetails?.due_payments
                                    ?.length === 1
                                ? "bg-yellow-400"
                                : "bg-green-600"
                            }`}
                          >
                            PAY NOW
                          </button>
                        )}
                        </div>
                        <div className="rounded-lg  w-full">
                          <div class="relative  overflow-x-auto  sm:rounded-lg">
                            <table class="w-full text-sm text-left text-gray-500 ">
                              <tbody>
                                <tr class=" bg-white font-sans">
                                  <th
                                    scope="col"
                                    class="px-3 py-3 text-[#344767] text-base"
                                  >
                                    Current Amount Due
                                  </th>
                                  <th scope="col" class="px-6 py-3 text-base">
                                    {paymentScheduledDetails?.total_payable
                                      ? formatUSD(
                                          paymentScheduledDetails?.total_payable
                                        )
                                      : "--"}
                                  </th>
                                </tr>
                                <tr class="bg-[#f5f5f5] font-sans">
                                  <th
                                    scope="row"
                                    class="px-3  font-bold text-[#344767] whitespace-nowrap text-base"
                                  >
                                    Payment Due Date
                                  </th>
                                  <td class="px-6 py-4 text-base">
                                    {paymentScheduledDetails?.due_payments
                                      ? formatDate(
                                          paymentScheduledDetails?.due_payments[
                                            paymentScheduledDetails
                                              ?.due_payments.length - 1
                                          ]?.date
                                        )
                                      : "--"}
                                  </td>
                                </tr>
                                <tr class=" bg-white font-sans">
                                  <th
                                    scope="col"
                                    class="px-3 py-3 text-[#344767] text-base"
                                  >
                                    Scheduled Payment Due
                                  </th>
                                  <th scope="col" class="px-6 py-3 text-base">
                                    {paymentScheduledDetails?.total_due_amount
                                      ? formatUSD(
                                          paymentScheduledDetails?.total_due_amount
                                        )
                                      : "--"}
                                  </th>
                                </tr>
                                <tr class=" bg-white font-sans">
                                  <th
                                    scope="row"
                                    class="px-3  font-bold text-[#344767] whitespace-nowrap text-base"
                                  >
                                    Late Fees
                                  </th>
                                  <td class="px-6 py-4 text-base">
                                    {paymentScheduledDetails?.late_fees
                                      ? formatUSD(
                                          paymentScheduledDetails?.late_fees
                                        )
                                      : "--"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full mt-5 sm:mt-0">
                    <div className="w-full  flex  flex-col items-center bg-white border border-gray-200 rounded-lg shadow ">
                      <div className="w-full flex flex-col justify-between px-4 py-2 leading-normal">
                        <div className="w-full flex justify-between items-center mb-4 border-b border-[#f2f4f6] ">
                          <div className="mt-[-3.5rem] ml-[-0.5rem] h-16 w-16 flex justify-center items-center ">
                            <svg
                              className="w-12 h-12"
                              viewBox="0 0 66 64"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.219513"
                                width="64.9366"
                                height="64"
                                rx="12"
                                fill="#26C6DA"
                              />
                              <path
                                d="M32.6878 48.7992C42.1017 48.7992 49.7329 41.2781 49.7329 32C49.7329 22.7219 42.1015 15.2008 32.6878 15.2008C23.2741 15.2008 15.6427 22.7221 15.6427 32C15.6427 41.2779 23.2741 48.7992 32.6878 48.7992ZM25.3474 38.9227L26.1862 37.0834C26.2896 36.8557 26.4841 36.6821 26.7213 36.5982C26.8188 36.5682 26.9157 36.5502 27.013 36.5502C27.1647 36.5502 27.3108 36.5863 27.4443 36.6583C28.7207 37.329 30.4351 37.7307 32.0276 37.7307C33.8997 37.7307 35.2615 36.976 35.2615 35.9396C35.2615 35.2685 34.8661 34.4595 31.9545 33.5251C28.7999 32.5488 25.5839 31.1227 25.5839 27.9236C25.5839 25.4971 27.3771 23.598 30.38 22.8491L30.8358 22.735V20.7279C30.8358 20.2366 31.2488 19.8349 31.7477 19.8349H33.9056C34.41 19.8349 34.8175 20.2362 34.8175 20.7279V22.5312L35.3526 22.5971C36.6351 22.7466 37.7718 23.0582 38.8175 23.5437C39.0361 23.6457 39.2067 23.8254 39.2853 24.0531C39.3705 24.2746 39.3584 24.5264 39.255 24.7357L38.3855 26.5509C38.2338 26.8625 37.9114 27.066 37.5587 27.066C37.4308 27.066 37.3036 27.0361 37.1816 26.9821C36.6162 26.7246 35.2912 26.1313 33.1758 26.1313C31.2548 26.1313 30.3976 26.8622 30.3976 27.5934C30.3976 28.4082 31.2912 28.9593 34.0873 29.8703C37.0658 30.8286 40.1052 32.2727 40.1052 35.7474C40.1052 37.6227 38.7861 40.145 35.0658 41.0676L34.61 41.1872V43.266C34.61 43.7632 34.197 44.1648 33.6981 44.1648H31.5037C30.9993 44.1648 30.5918 43.7635 30.5918 43.266V41.3608L30.0508 41.3068C28.3729 41.1212 26.7927 40.6717 25.7289 40.0669C25.3171 39.8389 25.1591 39.3479 25.3474 38.9227L25.3474 38.9227Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div className="w-full flex justify-end">
                            <div className="font-bold rounded-md py-3 px-2 text-black text-xl mb-2">
                              Upcoming Payments
                            </div>
                          </div>
                        </div>

                        <div className="rounded-lg  w-full">
                          <div className="w-full my-2 flex justify-end items-center">
                            <span className="px-1 rounded-md text-base font-semibold">
                              Auto Pay
                            </span>
                            {client_data.preferred_payment_autopay ? (
                              <>
                                <span className="">
                                  <CheckCircleIcon className="h-10 text-green-500" />
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="">
                                  <CancelIcon className="h-10 text-red-500" />
                                </span>
                              </>
                            )}
                          </div>
                          <div class="relative  overflow-x-auto  sm:rounded-lg">
                            <table class="w-full text-sm text-left text-gray-500 ">
                              <tbody>
                                <tr class=" bg-white font-sans">
                                  <th
                                    scope="col"
                                    class="px-3 py-3 text-[#344767] text-base"
                                  >
                                    Amount Due
                                  </th>
                                  <th scope="col" class="px-6 py-3 text-base">
                                    {paymentScheduledDetails?.upcoming_payment
                                      ? formatUSD(
                                          paymentScheduledDetails
                                            ?.upcoming_payment?.balance_to_pay
                                        )
                                      : "--"}
                                  </th>
                                </tr>
                                <tr class="bg-[#f5f5f5] font-sans">
                                  <th
                                    scope="row"
                                    class="px-3  font-bold text-[#344767] whitespace-nowrap text-base"
                                  >
                                    Next Payment Due Date
                                  </th>
                                  <td class="px-6 py-4 text-base">
                                    {paymentScheduledDetails?.upcoming_payment
                                      ? formatDate(
                                          paymentScheduledDetails
                                            ?.upcoming_payment?.date
                                        )
                                      : "--"}
                                  </td>
                                </tr>
                                <tr class=" bg-white font-sans">
                                  <th
                                    scope="row"
                                    class="px-3  font-bold text-[#344767] whitespace-nowrap text-base"
                                  >
                                    Paid
                                  </th>
                                  <td class="px-6 py-4 text-base">
                                    {paymentScheduledDetails?.upcoming_payment &&
                                    paymentScheduledDetails?.upcoming_payment ? (
                                      <>
                                        <span className="text-base font-bold text-black">{`${
                                          clientData?.contracts
                                            ? formatUSD(
                                                clientData?.contracts[0]
                                                  ?.total_paid_amount
                                              )
                                            : "--"
                                        }`}</span>
                                        <span className="mx-3">/</span>

                                        <span className="text-base font-bold text-black">
                                          {`${
                                            clientData?.contracts
                                              ? formatUSD(
                                                  clientData?.contracts[0]
                                                    ?.contract_amount
                                                )
                                              : "--"
                                          }`}{" "}
                                        </span>
                                      </>
                                    ) : (
                                      `--`
                                    )}
                                  </td>
                                </tr>
                                <tr class=" bg-white font-sans">
                                  <th
                                    scope="row"
                                    class="px-3  font-bold text-[#344767] whitespace-nowrap text-base"
                                  >
                                    Last Payment
                                  </th>
                                  <td class="px-6 py-4 text-base">
                                    {paymentScheduledDetails?.last_transaction
                                      ? `Thank you for your last payment of ${
                                          paymentScheduledDetails?.last_transaction
                                            ? formatUSD(
                                                paymentScheduledDetails
                                                  ?.last_transaction
                                                  ?.amount_paid
                                              )
                                            : "--"
                                        } on
                                ${
                                  paymentScheduledDetails?.last_transaction
                                    ? extractDateFromTimestamp(
                                        paymentScheduledDetails
                                          ?.last_transaction?.paid_at
                                      )
                                    : "--"
                                }`
                                      : `--`}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <>
                  {clientData?.contracts ? (
                    <ClientScheduleTable
                      contract_id={clientData?.contracts[0]?.id}
                    />
                  ) : (
                    ""
                  )}
                </>
              </div>
            ) : (
              <div>
                {!confirmPageFlag ? (
                  <div className="pb-20 xl:flex justify-center items-center">
                    <div className="p-5 flex flex-col border rounded-lg shadow-md md:flex-row gap-5 my-3">
                      <div className="my-2">
                        <div className="text-xl font-semibold text-[#344767] flex justify-between items-center">
                          {/* <div className="flex items-center">
                          <div className="text-xl font-bold">
                            Payment Details
                          </div>
                        </div> */}
                          <div className="flex items-center gap-5">
                            <span
                              className="hover:cursor-pointer"
                              onClick={() => {
                                localStorage.removeItem("selectedPay");
                                setSelectedPaymentMethod("");
                                setPaymentSelectFlag(false);
                                setConfirmPageFlag(false);
                                setPayableAmount("");
                                setSelectedCheckbox(null);
                                setSelectedMethod("");
                                setSelectedMethodId("");
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                fill="currentColor"
                                class="bi bi-arrow-left-circle"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                                />
                              </svg>
                            </span>
                            <div className="text-xl font-bold">
                              Payment Details
                            </div>
                          </div>
                        </div>
                        <div className="shadow-lg bg-white rounded-xl my-5 border">
                          <div className="px-4">
                            <div className="overflow-x-auto py-5">
                              <div className="inline-block min-w-full py-2 align-middle sm:px-3 lg:px-4">
                                <table class="w-full text-sm text-left text-gray-500 ">
                                  <tbody>
                                    <tr class="bg-[#f5f5f5] font-sans">
                                      <th
                                        scope="row"
                                        className="px-3 font-bold text-[#344767] whitespace-nowrap text-base"
                                      >
                                        Payment Amount
                                      </th>
                                      <div className="relative">
                                        <CurrencyFormat
                                          placeholder="$0.00"
                                          value={
                                            amountType == "default"
                                              ? paymentScheduledDetails &&
                                                (paymentScheduledDetails.total_payable
                                                  ? formatUSD(
                                                      paymentScheduledDetails.total_payable
                                                    )
                                                  : formatUSD(
                                                      paymentScheduledDetails
                                                        .upcoming_payment?.balance_to_pay
                                                    ))
                                              : payableAmount
                                          }
                                          onValueChange={(values) => {
                                            const { formattedValue, value } =
                                              values;
                                            setPayableAmount(formattedValue);
                                          }}
                                          disabled={
                                            amountType == "default"
                                              ? true
                                              : false
                                          }
                                          displayType={"input"}
                                          thousandSeparator={true}
                                          prefix={"$"}
                                          className="pl-8 w-full px-2 text-2xl font-bold text-black outline-none md:px-6 py-4"
                                        />
                                      </div>
                                      {/* <td class="px-6 py-4 text-lg font-bold">{paymentScheduledDetails?.total_payable ? formatUSD(paymentScheduledDetails?.total_payable) : "--"}</td> */}
                                    </tr>
                                    <tr class="bg-white font-sans">
                                      <th
                                        scope="row"
                                        className="px-3  font-bold text-[#344767] whitespace-nowrap text-base"
                                      >
                                        Date Due
                                      </th>
                                      <td class="w-full px-2 text-lg font-bold sm:px-6 py-4">
                                        {paymentScheduledDetails?.due_payments
                                          ? formatDate(
                                              paymentScheduledDetails
                                                ?.due_payments[
                                                paymentScheduledDetails
                                                  ?.due_payments.length - 1
                                              ]?.date
                                            )
                                          : "--"}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                {paymentScheduledDetails.upcoming_payment?.balance_to_pay && (
                                <RadioGroup
                                  value={amountType}
                                  onChange={setAmountType}
                                >
                                  <RadioGroup.Label className="sr-only">
                                    Amount Type
                                  </RadioGroup.Label>
                                  <div className="relative -space-y-px rounded-md bg-white">
                                    <RadioGroup.Option
                                      key={"default"}
                                      value={"default"}
                                      onClick={() => {
                                        setPayableAmount(
                                          paymentScheduledDetails &&
                                            (paymentScheduledDetails.total_payable
                                              ? formatUSD(
                                                  paymentScheduledDetails.total_payable
                                                )
                                              : formatUSD(
                                                  paymentScheduledDetails
                                                    .upcoming_payment?.balance_to_pay
                                                ))
                                        );
                                      }}
                                      className={({ checked }) =>
                                        classNames(
                                          amountType === "default"
                                            ? "rounded-tl-md rounded-tr-md"
                                            : "",
                                          amountType === "default"
                                            ? "rounded-bl-md rounded-br-md"
                                            : "",
                                          checked
                                            ? "z-10 border-green-200 bg-green-50"
                                            : "border-gray-200",
                                          "relative flex cursor-pointer flex-col border p-4 focus:outline-none md:grid lg:grid-cols-2 md:pl-4 md:pr-6"
                                        )
                                      }
                                    >
                                      {({ active, checked }) => (
                                        <>
                                          <span className="flex gap-3 items-center text-sm">
                                            <span
                                              className={classNames(
                                                checked
                                                  ? "bg-green-600 border-transparent"
                                                  : "bg-white border-gray-300",
                                                active
                                                  ? "ring-2 ring-offset-2 ring-green-600"
                                                  : "",
                                                "h-4 w-4 rounded-full border flex items-center justify-center"
                                              )}
                                              aria-hidden="true"
                                            >
                                              <span className="rounded-full bg-white w-1.5 h-1.5" />
                                            </span>
                                            <RadioGroup.Description
                                              as="span"
                                              className=" ml-2 pl-1 text-base md:ml-0 md:pl-0"
                                            >
                                              <span
                                                className={classNames(
                                                  checked
                                                    ? "text-black"
                                                    : "text-gray-900",
                                                  "font-medium"
                                                )}
                                              >
                                                Current Payment Due
                                              </span>
                                            </RadioGroup.Description>
                                          </span>
                                          <RadioGroup.Description
                                            as="span"
                                            className=" ml-2 pl-1 text-xl text-end md:ml-0 md:pl-0"
                                          >
                                            <span
                                              className={classNames(
                                                checked
                                                  ? "text-black"
                                                  : "text-gray-900",
                                                "font-bold"
                                              )}
                                            >
                                              {paymentScheduledDetails &&
                                              paymentScheduledDetails.total_payable
                                                ? formatUSD(
                                                    paymentScheduledDetails.total_payable
                                                  )
                                                : formatUSD(
                                                    paymentScheduledDetails
                                                      .upcoming_payment?.balance_to_pay
                                                  )}
                                            </span>
                                          </RadioGroup.Description>
                                        </>
                                      )}
                                    </RadioGroup.Option>
                                    <RadioGroup.Option
                                      key={"custom"}
                                      value={"custom"}
                                      onClick={() => {
                                        setPayableAmount("");
                                      }}
                                      className={({ checked }) =>
                                        classNames(
                                          amountType === "custom"
                                            ? "rounded-tl-md rounded-tr-md"
                                            : "",
                                          amountType === "custom"
                                            ? "rounded-bl-md rounded-br-md"
                                            : "",
                                          checked
                                            ? "z-10 border-blue-200 bg-blue-50"
                                            : "border-gray-200",
                                          "relative flex cursor-pointer flex-col border p-4 focus:outline-none md:grid lg:grid-cols-2 md:pl-4 md:pr-6"
                                        )
                                      }
                                    >
                                      {({ active, checked }) => (
                                        <>
                                          <span className="flex gap-3 items-center text-sm">
                                            <span
                                              className={classNames(
                                                checked
                                                  ? "bg-blue-600 border-transparent"
                                                  : "bg-white border-gray-300",
                                                active
                                                  ? "ring-2 ring-offset-2 ring-blue-600"
                                                  : "",
                                                "h-4 w-4 rounded-full border flex items-center justify-center"
                                              )}
                                              aria-hidden="true"
                                            >
                                              <span className="rounded-full bg-white w-1.5 h-1.5" />
                                            </span>
                                            <RadioGroup.Description
                                              as="span"
                                              className="ml-2 pl-1 text-base md:ml-0 md:pl-0"
                                            >
                                              <span
                                                className={classNames(
                                                  checked
                                                    ? "text-black"
                                                    : "text-gray-900",
                                                  "font-medium"
                                                )}
                                              >
                                                Other Amount
                                              </span>
                                            </RadioGroup.Description>
                                          </span>
                                        </>
                                      )}
                                    </RadioGroup.Option>
                                  </div>
                                </RadioGroup>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full md:w-[500px]">
                        <div className="my-5">
                          <div className="my-5 text-xl font-bold">
                            Payment Method
                          </div>
                          {accountsDetail.length ? (
                            <RadioGroup
                              value={selectedPay}
                              onChange={handleSelectionPay}
                            >
                              <RadioGroup.Label className="sr-only">
                                Payment Method
                              </RadioGroup.Label>
                              <div className="-space-y-px rounded-md bg-white">
                                {accountsDetail.length &&
                                  accountsDetail.map((account, accountIdx) => (
                                    <RadioGroup.Option
                                      key={account.id}
                                      value={account}
                                      className={({ checked }) =>
                                        classNames(
                                          selectedMethodId ==
                                            account.customer_payment_method_id
                                            ? "rounded-tl-md rounded-tr-md"
                                            : "",
                                          selectedMethodId ==
                                            account.customer_payment_method_id
                                            ? "rounded-bl-md rounded-br-md"
                                            : "",
                                          selectedMethodId ==
                                            account.customer_payment_method_id
                                            ? "z-10 border-indigo-200 bg-indigo-50"
                                            : "border-gray-200",
                                          "relative flex items-center cursor-pointer border p-4 focus:outline-none"
                                        )
                                      }
                                    >
                                      {({ active, checked }) => (
                                        <>
                                          <span
                                            className={classNames(
                                              selectedMethodId ==
                                                account.customer_payment_method_id
                                                ? "bg-indigo-600 border-transparent"
                                                : "bg-white border-gray-300",
                                              selectedMethodId ==
                                                account.customer_payment_method_id
                                                ? "ring-2 ring-offset-2 ring-indigo-600 bg-indigo-600"
                                                : "",
                                              "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                            )}
                                            aria-hidden="true"
                                          >
                                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                                          </span>
                                          <span className="inline-flex items-center mx-3">
                                            {account.type == "ach" ? (
                                              <img
                                                src={bankImg}
                                                alt="bankImg"
                                                className=" w-10 mt-1  flex-shrink-0"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name == "Amex" ? (
                                              <img
                                                src={amex}
                                                alt="amex"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name ==
                                            "AmericanExpress" ? (
                                              <img
                                                src={american_express}
                                                alt="american_express"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name == "Carteblanche" ? (
                                              <img
                                                src={carteblanche}
                                                alt="carteblanche"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name == "Celo" ? (
                                              <img
                                                src={celo}
                                                alt="celo"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name == "Dankort" ? (
                                              <img
                                                src={dankort}
                                                alt="dankort"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name == "Diners Club" ? (
                                              <img
                                                src={diners}
                                                alt="diners"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name == "Discover" ? (
                                              <img
                                                src={discover}
                                                alt="discover"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}

                                            {account.name ==
                                            "Forbrugsforeningen" ? (
                                              <img
                                                src={forbrugsforeningen}
                                                alt="forbrugsforeningen"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name == "Hipercard" ? (
                                              <img
                                                src={hipercard}
                                                alt="hipercard"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name == "Instapayment" ? (
                                              <img
                                                src={instapayment}
                                                alt="instapayment"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name == "JCB" ? (
                                              <img
                                                src={jcb}
                                                alt="jcb"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name == "laser" ? (
                                              <img
                                                src={laser}
                                                alt="laser"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name == "MasterCard" ? (
                                              <img
                                                src={mastercard}
                                                alt="mastercard"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name == "mnp" ? (
                                              <img
                                                src={mnp}
                                                alt="mnp"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name == "nocards" ? (
                                              <img
                                                src={nocards}
                                                alt="nocards"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name == "onpay" ? (
                                              <img
                                                src={onpay}
                                                alt="onpay"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name == "rupay" ? (
                                              <img
                                                src={rupay}
                                                alt="rupay"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name == "troy" ? (
                                              <img
                                                src={troy}
                                                alt="troy"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name == "uatp" ? (
                                              <img
                                                src={uatp}
                                                alt="uatp"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name == "Visa" ? (
                                              <img
                                                src={visa}
                                                alt="visa"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name == "visa_electron" ? (
                                              <img
                                                src={visa_electron}
                                                alt="visa_electron"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {account.name == "vpay" ? (
                                              <img
                                                src={vpay}
                                                alt="vpay"
                                                className=" w-12  flex-shrink-0 rounded-full"
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                          <span className="ml-3 flex flex-col">
                                            <RadioGroup.Label
                                              as="span"
                                              className={classNames(
                                                checked
                                                  ? "text-indigo-900"
                                                  : "text-gray-900",
                                                "block text-base font-bold"
                                              )}
                                            >
                                              {account.name}
                                            </RadioGroup.Label>
                                            <RadioGroup.Description
                                              as="span"
                                              className={classNames(
                                                checked
                                                  ? "text-indigo-700"
                                                  : "text-gray-400",
                                                "block text-sm"
                                              )}
                                            >
                                              {account.description}
                                            </RadioGroup.Description>
                                          </span>
                                          {client_data.preferred_payment_autopay &&
                                          client_data.preferred_payment_method ==
                                            account.customer_payment_method_id ? (
                                            <span className="bg-amber-300 px-1 rounded-md text-sm absolute right-2 top-[-5px]">
                                              Auto Pay
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          {client_data.preferred_payment_method ==
                                          account.customer_payment_method_id ? (
                                            <span className="text-sm text-indigo-800 font-bold italic absolute right-2 bottom-0">
                                              Preferred
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      )}
                                    </RadioGroup.Option>
                                  ))}
                              </div>
                            </RadioGroup>
                          ) : (
                            <div className="italic text-gray-400 text-center">
                              No Payment method is added
                            </div>
                          )}
                        </div>
                        {selectedMethod === "card" && (
                          <div className="border-l-4 border-yellow-400 bg-yellow-50 px-4 py-2">
                            <div className="flex">
                              <div className="flex-shrink-0">
                                <InformationCircleIcon
                                  className="h-5 w-5 text-yellow-400"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-3">
                                <p className="text-sm text-yellow-700">
                                  Additional 2.5% processing fee applicable
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="flex justify-end my-3">
                          <div
                            onClick={() => {
                              if (
                                payableAmount == "$0.00" ||
                                payableAmount == ""
                              ) {
                                toast.error("Please enter Amount", {
                                  id: 1,
                                });
                                return;
                              } else if (
                                parseFloat(
                                  String(payableAmount).replace(/[$,]/g, "")
                                ) < 1
                              ) {
                                toast.error("Minimum Amount is $1.00", {
                                  id: 1,
                                });
                                return;
                              }
                              setPaymentAddFlag(true);
                              setOpenAddPaymentModal(true);
                            }}
                            className="text-lg font-semibold text-[#344767] flex items-center gap-2 hover:cursor-pointer"
                          >
                            <div>
                              <PlusIcon className="w-10" />
                            </div>
                            <div className="text-2xl">Add New</div>
                          </div>
                        </div>
                        {selectedMethod && (
                          <div>
                            <div
                              className={`px-3 py-1 border rounded-lg ${
                                selectedCheckbox == "yes"
                                  ? "bg-green-300"
                                  : selectedCheckbox == "no"
                                  ? "bg-[#FFBF00]"
                                  : "bg-gray-200"
                              }`}
                            >
                              <div className="text-xl font-bold italic">
                                Use this payment method for autopay
                              </div>
                            </div>
                            <div>
                              <div className="my-6 mx-2 flex items-center gap-5">
                                <div className="relative flex gap-x-2">
                                  <div className="flex h-6 items-center ">
                                    <input
                                      id="yes"
                                      name="yes"
                                      type="checkbox"
                                      value={"yes"}
                                      checked={selectedCheckbox === "yes"}
                                      onChange={() =>
                                        handleCheckboxChange("yes")
                                      }
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 hover:cursor-pointer"
                                    />
                                  </div>
                                  <div className="leading-6">
                                    <label
                                      htmlFor="yes"
                                      className="font-medium text-gray-900 text-md"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                </div>
                                <div className="relative flex gap-x-2 ">
                                  <div className="flex h-6 items-center ng-yellow-300">
                                    <input
                                      id="no"
                                      name="no"
                                      type="checkbox"
                                      value={"no"}
                                      checked={selectedCheckbox === "no"}
                                      onChange={() =>
                                        handleCheckboxChange("no")
                                      }
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 hover:cursor-pointer"
                                    />
                                  </div>
                                  <div className="leading-6">
                                    <label
                                      htmlFor="no"
                                      className="font-medium text-gray-900 text-md"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="flex items-center justify-between border-t-2 pt-3">
                          <button
                            disabled={!selectedCheckbox ? true : false}
                            onClick={() => {
                              console.log(payableAmount, typeof payableAmount);
                              if (
                                payableAmount == "$0.00" ||
                                payableAmount == ""
                              ) {
                                toast.error("Please enter Amount", {
                                  id: 1,
                                });
                                return;
                              } else if (
                                parseFloat(
                                  String(payableAmount).replace(/[$,]/g, "")
                                ) < 1
                              ) {
                                toast.error("Minimum Amount is $1.00", {
                                  id: 1,
                                });
                                return;
                              }
                              setConfirmPageFlag(true);
                            }}
                            className={`text-2xl p-3 border rounded-lg font-bold ${
                              selectedCheckbox
                                ? "bg-green-500 text-white"
                                : "bg-gray-100"
                            }`}
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="pb-20 sm:flex justify-center items-center">
                    <div className="p-5 sm:p-10 border rounded-lg shadow-md">
                      <div className="text-xl md:text-xl font-semibold text-[#344767] flex justify-between items-center">
                        <div className="flex items-center gap-5">
                          <span
                            className="hover:cursor-pointer"
                            onClick={() => {
                              // setPaymentSelectFlag(false)
                              // setSelectedAddOption("")
                              setConfirmPageFlag(false);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              fill="currentColor"
                              class="bi bi-arrow-left-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                              />
                            </svg>
                          </span>
                          <div className="text-xl font-bold">
                            My Payment Details
                          </div>
                        </div>
                      </div>
                      <div className="w-full border my-4">
                        <div class="relative  overflow-x-auto  sm:rounded-lg">
                          <table class="w-full text-sm text-left text-gray-500 ">
                            <tbody>
                              {/* <tr class="bg-white font-sans">
                                <th
                                  scope="col"
                                  class="px-3 py-3 text-[#344767] text-base"
                                >
                                  Payment Amount
                                </th>
                                <th scope="col" class="px-6 py-3 text-gray-900 text-xl font-bold">
                                  {payableAmount
                                    ?
                                    payableAmount
                                    : "--"}
                                </th>
                              </tr> */}
                              <tr class="bg-[#f5f5f5] font-sans">
                                <th
                                  scope="row"
                                  class="px-3  font-bold text-[#344767] whitespace-nowrap text-sm"
                                >
                                  Payment Due Date
                                </th>
                                <td class="px-6 py-4 text-base">
                                  {paymentScheduledDetails?.due_payments
                                    ? formatDate(
                                        paymentScheduledDetails?.due_payments[
                                          paymentScheduledDetails?.due_payments
                                            .length - 1
                                        ]?.date
                                      )
                                    : "--"}
                                </td>
                              </tr>
                              <tr class="bg-white font-sans">
                                <th
                                  scope="col"
                                  class="px-3 py-3 text-[#344767] text-sm"
                                >
                                  Scheduled Payment Due
                                </th>
                                <th scope="col" class="px-6 py-3 text-base">
                                  {paymentScheduledDetails?.total_due_amount
                                    ? formatUSD(
                                        paymentScheduledDetails?.total_due_amount
                                      )
                                    : "--"}
                                </th>
                              </tr>
                              <tr class="bg-white font-sans">
                                <th
                                  scope="row"
                                  class="px-3  font-bold text-[#344767] whitespace-nowrap text-sm"
                                >
                                  Late Fees
                                </th>
                                <td class="px-6 py-4 text-base">
                                  {paymentScheduledDetails?.late_fees
                                    ? formatUSD(
                                        paymentScheduledDetails?.late_fees
                                      )
                                    : "--"}
                                </td>
                              </tr>
                              <tr class="bg-white font-sans sm:border-t border-gray-300">
                                <th
                                  scope="col"
                                  class="px-3 py-3 text-[#344767] text-sm sm:text-end"
                                >
                                  Current Amount Due
                                </th>
                                <th scope="col" class="px-6 py-3 text-base">
                                  {paymentScheduledDetails?.total_payable
                                    ? formatUSD(
                                        paymentScheduledDetails?.total_payable
                                      )
                                    : "--"}
                                </th>
                              </tr>
                            </tbody>
                          </table>
                          <div class="bg-white font-sans flex justify-end items-center">
                            <div class="px-3 py-3 text-[#344767] text-base font-bold">
                              Payment Amount:
                            </div>
                            <div class="px-3 py-3 text-gray-900 text-xl font-bold">
                              {payableAmount
                                ? formatUSD(
                                    String(payableAmount).replace(/[$,]/g, "")
                                  )
                                : "--"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-xl md:text-xl font-semibold text-[#344767] my-4">
                        Current Payment
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-3 sm:gap-5">
                        <div
                          className={`p-8 py-5 w-full grid-cols-1 rounded m-1 ${
                            selectedCheckbox === "yes"
                              ? "bg-blue-500"
                              : "bg-gray-300"
                          } text-lg font-bold text-white flex items-center gap-2`}
                        >
                          <div>
                            {selectedCheckbox === "yes" ? (
                              <DoneOutlineIcon
                                sx={{ height: "2rem", width: "2rem" }}
                              />
                            ) : (
                              <CancelIcon
                                sx={{ height: "2rem", width: "2rem" }}
                              />
                            )}
                          </div>
                          <div>Automatic Payment</div>
                        </div>

                        <div
                          className={`p-8 py-5 w-full grid-cols-1 rounded m-1 ${
                            selectedMethod === "ach"
                              ? "bg-blue-500"
                              : "bg-gray-300"
                          } text-lg font-bold text-white flex items-center gap-2`}
                        >
                          <div>
                            {selectedMethod === "ach" ? (
                              <DoneOutlineIcon
                                sx={{ height: "2rem", width: "2rem" }}
                              />
                            ) : (
                              <CancelIcon
                                sx={{ height: "2rem", width: "2rem" }}
                              />
                            )}
                          </div>
                          <div>ACH Debit</div>
                        </div>

                        <div
                          className={`p-8 py-5 w-full grid-cols-1 rounded m-1 ${
                            selectedMethod === "card"
                              ? "bg-blue-500"
                              : "bg-gray-300"
                          } text-lg font-bold text-white flex items-center gap-2`}
                        >
                          <div>
                            {selectedMethod === "card" ? (
                              <DoneOutlineIcon
                                sx={{ height: "2rem", width: "2rem" }}
                              />
                            ) : (
                              <CancelIcon
                                sx={{ height: "2rem", width: "2rem" }}
                              />
                            )}
                          </div>
                          <div>Credit Card</div>
                        </div>
                      </div>
                      <div className="w-full mt-6 sm:flex justify-end">
                        <button
                          onClick={() => {
                            postTransactionGateway("save");
                          }}
                          type="button"
                          // className="bg-green-600 rounded-md py-3 px-2  text-white text-lg  sm:py-3 px-10 hover:shadow-lg"
                          className={`w-full font-bold rounded-md py-3 px-10 text-white text-lg  hover:shadow-lg ${
                            paymentScheduledDetails?.due_payments?.length >= 2
                              ? "bg-red-600"
                              : paymentScheduledDetails?.due_payments
                                  ?.length === 1
                              ? "bg-yellow-400"
                              : "bg-green-600"
                          }`}
                        >
                          PAY NOW
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </motion.div>
        </div>
      )}

      <ModalComponent open={openAddPaymentModal} handleClose={() => {}}>
        <>
          {!paymentAddFlag ? (
            <div className="w-full sm:flex  justify-center items-center">
              {selectedAddOption == "card" ? (
                <div className="p-2 bg-white rounded-lg">
                  <div className="w-full flex justify-between">
                    <span
                      className="hover:cursor-pointer"
                      onClick={() => {
                        setPaymentAddFlag(true);
                        setSelectedAddOption("");
                        setData({
                          cardholder: "",
                          card_number: "",
                          bank_account_number: "",
                          bank_routing_number: "",
                          expiration_date: "",
                          cvv: "",
                          zip_code: "",
                          accountholder: "",
                          bank_name: "",
                        });
                        setErrorFlag({
                          cardholder: "",
                          card_number: "",
                          bank_account_number: "",
                          bank_routing_number: "",
                          expiration_date: "",
                          cvv: "",
                          zip_code: "",
                          accountholder: "",
                          bank_name: "",
                        });
                        setCardDetails({
                          cardExpiryMonth: "",
                          cardExpiryYear: "",
                        });
                        setCardDetailsError({
                          cardExpiryMonth: "",
                          cardExpiryYear: "",
                        });
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="currentColor"
                        class="bi bi-arrow-left-circle"
                        viewBox="0 0 16 16"
                      >
                        {" "}
                        <path
                          fill-rule="evenodd"
                          d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                        />{" "}
                      </svg>
                    </span>
                    <span
                      className="border hover:cursor-pointer"
                      onClick={() => {
                        setOpenAddPaymentModal(false);
                        setSelectedAddOption("");
                        setData({
                          cardholder: "",
                          card_number: "",
                          bank_account_number: "",
                          bank_routing_number: "",
                          expiration_date: "",
                          cvv: "",
                          zip_code: "",
                          accountholder: "",
                          bank_name: "",
                        });
                        setErrorFlag({
                          cardholder: "",
                          card_number: "",
                          bank_account_number: "",
                          bank_routing_number: "",
                          expiration_date: "",
                          cvv: "",
                          zip_code: "",
                          accountholder: "",
                          bank_name: "",
                        });

                        setCardDetails({
                          cardExpiryMonth: "",
                          cardExpiryYear: "",
                        });
                        setCardDetailsError({
                          cardExpiryMonth: "",
                          cardExpiryYear: "",
                        });
                      }}
                    >
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="48"
                          height="48"
                          fill="white"
                          fill-opacity="0.01"
                        />
                        <path
                          d="M14 14L34 34"
                          stroke="#333"
                          stroke-width="1"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14 34L34 14"
                          stroke="#333"
                          stroke-width="1"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="p-2">
                    <div className="text-xl font-semibold text-[#344767] flex justify-between items-center">
                      <div className="flex items-center">
                        <div className="text-xl font-bold">Add New Card</div>
                      </div>
                    </div>
                    <div class="bg-white font-sans flex justify-end items-baseline">
                      <div class="py-3 text-[#344767] text-base font-bold">
                        Payment Amount:
                      </div>
                      <div class="px-3 py-3 text-gray-900 text-xl font-bold">
                        {payableAmount
                          ? formatUSD(
                              String(payableAmount).replace(/[$,]/g, "")
                            )
                          : "--"}
                      </div>
                    </div>
                    <div className="my-2">
                      <div className="shadow-lg  rounded-xl grid  px-3 mt-5 py-2">
                        <form>
                          <div className="border-gray-900/10 pb-5">
                            <div className="grid">
                              <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-5">
                                <div className="text-start lg:grid-cols-1 sm:col-span-1 mt-5">
                                  <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                                    Name
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      type="text"
                                      value={data.cardholder}
                                      name="cardholder"
                                      id="cardholder"
                                      className="border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767] uppercase"
                                      onChange={(e) => formHandler(e)}
                                    />
                                  </div>
                                  <span className="text-red-500 text-xs">
                                    {errorFlag.cardholder}
                                  </span>
                                </div>

                                <div className="text-start lg:grid-cols-1 sm:col-span-1 mt-5">
                                  <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                                    Credit / Debit Card Number
                                  </label>
                                  <div className="mt-1">
                                    <CurrencyFormat
                                      name="card_number"
                                      id="card_number"
                                      onChange={(e) => formHandler(e)}
                                      value={data.card_number}
                                      className=" border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2  "
                                      format="#### #### #### ####"
                                      mask="_"
                                    />
                                  </div>
                                  <span className="text-red-500 text-xs">
                                    {errorFlag.card_number}
                                  </span>
                                </div>
                              </div>
                              <div className="text-start grid lg:grid-cols-2 sm:grid-cols-1 gap-5 mt-5">
                                <div className="sm:col-span-4">
                                  <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                                    Card Expires:(MM/YY)
                                  </label>
                                  <div className="w-full flex mt-5">
                                    <div className="w-20 flex flex-col gap-1">
                                      <input
                                        type="text"
                                        name="cardExpiryMonth"
                                        onChange={(e) => {
                                          formHandler(e);
                                        }}
                                        maxLength={2}
                                        id="cardExpiryMonth"
                                        value={cardDetails.cardExpiryMonth}
                                        className="mr-2 border-b-1 focus:border-[#344767] block w-16  outline-none   border-b-2 "
                                      />
                                      <p className="text-red-600 text-xs">
                                        {cardDetailsError?.cardExpiryMonth}
                                      </p>
                                    </div>
                                    /
                                    <div className="w-20 flex flex-col gap-1">
                                      <input
                                        type="text"
                                        name="cardExpiryYear"
                                        onChange={(e) => formHandler(e)}
                                        id="cardExpiryYear"
                                        value={cardDetails.cardExpiryYear}
                                        maxLength={2}
                                        className="ml-2 border-b-1 focus:border-[#344767] block w-16 outline-none  border-b-2 "
                                      />
                                      <p className="text-red-600 text-xs">
                                        {cardDetailsError?.cardExpiryYear}
                                      </p>
                                    </div>
                                  </div>
                                  <p className="text-red-600 text-xs">
                                    {errorFlag.expiration_date}
                                  </p>
                                </div>

                                <div className="text-start lg:grid-cols-1 sm:col-span-1 mt-5">
                                  <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                                    CVV / CVC
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      name="cvv"
                                      value={data.cvv}
                                      onChange={(e) => formHandler(e)}
                                      id="cvv"
                                      className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 "
                                    />
                                  </div>
                                  <span className="text-red-500 text-xs">
                                    {errorFlag.cvv}
                                  </span>
                                </div>

                                <div className="text-start lg:grid-cols-1 sm:col-span-1 mt-5">
                                  <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                                    Zip Code
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      name="zip_code"
                                      value={data.zip_code}
                                      onChange={(e) => formHandler(e)}
                                      id="zip_code"
                                      className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 "
                                    />
                                  </div>
                                  <span className="text-red-500 text-xs">
                                    {errorFlag.zip_code}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>

                        {/* </div> */}
                      </div>
                    </div>
                    <div className="">
                      <div className="sm:flex justify-end m-3">
                        <div className="sm:m-1">
                          <button
                            type="button"
                            onClick={(e) => {
                              console.log("Form Submitted");
                              postGatewayCardData("save");
                            }}
                            disabled={cardAchLoader}
                            className="inline-flex gap-3 justify-center items-center  rounded-md bg-green-700 bg-gradient-to-r from-[#439DEE] to-[#1E78E9]  text-lg md:text-lg w-full p-5 font-semibold text-white shadow-sm mt-1"
                          >
                            SAVE
                            {cardAchLoader && (
                              <Oval
                                height={20}
                                width={20}
                                color="#ffff"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#ffff"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                              />
                            )}
                          </button>
                        </div>
                        <div className="sm:m-1">
                          <button
                            type="button"
                            onClick={(e) => {
                              console.log("Form Direct Submitted");
                              postGatewayCardData("direct");
                            }}
                            disabled={cardAchDirectLoader}
                            className="inline-flex gap-3 justify-center items-center  rounded-md  bg-gradient-to-r from-[#63B967] to-[#4BA64F] p-5 w-full text-lg mt-1 lg:ml-5 font-semibold text-white shadow-sm xs:mt-1"
                          >
                            SAVE AND PAY NOW
                            {cardAchDirectLoader && (
                              <Oval
                                height={20}
                                width={20}
                                color="#ffff"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#ffff"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                              />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="p-2 bg-white rounded-lg">
                  <div className="w-full flex justify-between">
                    <span
                      className="hover:cursor-pointer"
                      onClick={() => {
                        setPaymentAddFlag(true);
                        setSelectedAddOption("");
                        setData({
                          cardholder: "",
                          card_number: "",
                          bank_account_number: "",
                          bank_routing_number: "",
                          expiration_date: "",
                          cvv: "",
                          zip_code: "",
                          accountholder: "",
                          bank_name: "",
                        });
                        setErrorFlag({
                          cardholder: "",
                          card_number: "",
                          bank_account_number: "",
                          bank_routing_number: "",
                          expiration_date: "",
                          cvv: "",
                          zip_code: "",
                          accountholder: "",
                          bank_name: "",
                        });
                        setCardDetails({
                          cardExpiryMonth: "",
                          cardExpiryYear: "",
                        });
                        setCardDetailsError({
                          cardExpiryMonth: "",
                          cardExpiryYear: "",
                        });
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="currentColor"
                        class="bi bi-arrow-left-circle"
                        viewBox="0 0 16 16"
                      >
                        {" "}
                        <path
                          fill-rule="evenodd"
                          d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                        />{" "}
                      </svg>
                    </span>
                    <span
                      className="border hover:cursor-pointer"
                      onClick={() => {
                        setOpenAddPaymentModal(false);
                        setSelectedAddOption("");
                        setData({
                          cardholder: "",
                          card_number: "",
                          bank_account_number: "",
                          bank_routing_number: "",
                          expiration_date: "",
                          cvv: "",
                          zip_code: "",
                          accountholder: "",
                          bank_name: "",
                        });
                        setErrorFlag({
                          cardholder: "",
                          card_number: "",
                          bank_account_number: "",
                          bank_routing_number: "",
                          expiration_date: "",
                          cvv: "",
                          zip_code: "",
                          accountholder: "",
                          bank_name: "",
                        });
                        setCardDetails({
                          cardExpiryMonth: "",
                          cardExpiryYear: "",
                        });
                        setCardDetailsError({
                          cardExpiryMonth: "",
                          cardExpiryYear: "",
                        });
                      }}
                    >
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="48"
                          height="48"
                          fill="white"
                          fill-opacity="0.01"
                        />
                        <path
                          d="M14 14L34 34"
                          stroke="#333"
                          stroke-width="1"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14 34L34 14"
                          stroke="#333"
                          stroke-width="1"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="p-2">
                    <div className="text-xl font-semibold text-[#344767] flex justify-between items-center">
                      <div className="flex items-center">
                        <div className="text-xl font-bold">
                          Add New ACH Method
                        </div>
                      </div>
                    </div>
                    <div class="bg-white font-sans flex justify-end items-baseline">
                      <div class="py-3 text-[#344767] text-base font-bold">
                        Payment Amount:
                      </div>
                      <div class="px-3 py-3 text-gray-900 text-xl font-bold">
                        {payableAmount
                          ? formatUSD(
                              String(payableAmount).replace(/[$,]/g, "")
                            )
                          : "--"}
                      </div>
                    </div>

                    <div className="w-full shadow-lg bg-white rounded-xl px-3 py-4">
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 py-2">
                        <div className="sm:col-span-2 mt-5">
                          <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                            Name
                          </label>
                          <div className="w-full mt-5">
                            <input
                              type="text"
                              name="accountholder"
                              value={data.accountholder}
                              onChange={(e) => {
                                formHandler(e);
                              }}
                              className="w-full mt-2 border-b-1 focus:border-[#344767]  outline-none  border-b-2 uppercase"
                            />
                          </div>
                          <span className="text-red-500 text-xs">
                            {errorFlag.accountholder}
                          </span>
                        </div>
                        <div className="sm:col-span-2 mt-5">
                          <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                            Account Number
                          </label>
                          <div className="w-full mt-5">
                            <input
                              type="text"
                              name="bank_account_number"
                              value={data.bank_account_number}
                              onChange={(e) => {
                                formHandler(e);
                              }}
                              className="w-full mt-2 border-b-1 focus:border-[#344767]  outline-none  border-b-2 "
                            />
                          </div>
                          <span className="text-red-500 text-xs">
                            {errorFlag.bank_account_number}
                          </span>
                        </div>
                        <div className="sm:col-span-2 mt-5">
                          <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                            Bank Routing Number
                          </label>
                          <div className="w-full mt-5">
                            <input
                              type="text"
                              name="bank_routing_number"
                              value={data.bank_routing_number}
                              onChange={(e) => {
                                formHandler(e);
                              }}
                              className="w-full mt-2 border-b-1 focus:border-[#344767] outline-none  border-b-2 "
                            />
                          </div>
                          <span className="text-red-500 text-xs">
                            {errorFlag.bank_routing_number}
                          </span>
                        </div>
                        <div className="sm:col-span-2 mt-5">
                          <label className="block text-md text-start font-medium leading-6 text-[#9ca0b3]">
                            Bank Name
                          </label>
                          <div className="w-full mt-5">
                            <input
                              type="text"
                              name="bank_name"
                              value={data.bank_name}
                              onChange={(e) => {
                                formHandler(e);
                              }}
                              className="w-full mt-2 border-b-1 focus:border-[#344767] outline-none  border-b-2 uppercase"
                            />
                          </div>
                          <span className="text-red-500 text-xs">
                            {errorFlag.bank_name}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="sm:flex justify-end m-3">
                        <div className="sm:m-1">
                          <button
                            type="button"
                            disabled={cardAchLoader}
                            onClick={(e) => {
                              console.log("Form Submitted");
                              postGatewayAchData("save");
                            }}
                            className="inline-flex gap-3 justify-center items-center  rounded-md bg-green-700 bg-gradient-to-r from-[#439DEE] to-[#1E78E9]  text-lg md:text-lg w-full p-5 font-semibold text-white shadow-sm mt-1"
                          >
                            SAVE
                            {cardAchLoader && (
                              <Oval
                                height={20}
                                width={20}
                                color="#ffff"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#ffff"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                              />
                            )}
                          </button>
                        </div>
                        <div className="sm:m-1">
                          <button
                            type="button"
                            disabled={cardAchLoader}
                            onClick={(e) => {
                              console.log("Form Direct Submitted");
                              postGatewayAchData("direct");
                            }}
                            className="inline-flex gap-3 justify-center items-center rounded-md  bg-gradient-to-r from-[#63B967] to-[#4BA64F] p-5 w-full text-lg mt-1 lg:ml-5 font-semibold text-white shadow-sm xs:mt-1"
                          >
                            SAVE AND PAY NOW
                            {cardAchDirectLoader && (
                              <Oval
                                height={20}
                                width={20}
                                color="#ffff"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#ffff"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                              />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="sm:flex justify-center items-center">
              <div className="p-2 bg-white rounded-lg">
                <div className="w-full flex justify-start">
                  <span
                    className="hover:cursor-pointer"
                    onClick={() => {
                      setSelectedAddOption("");
                      setOpenAddPaymentModal(false);
                      setPaymentAddFlag(false);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      class="bi bi-arrow-left-circle"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path
                        fill-rule="evenodd"
                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                      />{" "}
                    </svg>
                  </span>
                </div>
                <div className="p-2 rounded-lg sm:my-3">
                  <div className="my-2">
                    <div className="text-xl font-semibold text-[#344767] flex justify-between items-center">
                      <div className="flex items-center">
                        <div className="text-xl font-bold">
                          Add New Payment Method
                        </div>
                      </div>
                    </div>
                    <div className="shadow-lg bg-white rounded-xl my-5">
                      <RadioGroup
                        value={selectedAddOption ? selectedAddOption : null}
                        onChange={handleAddPaymentChange}
                      >
                        <RadioGroup.Label className="sr-only">
                          Add New Payment Method
                        </RadioGroup.Label>
                        <div className="-space-y-px rounded-md bg-white">
                          <RadioGroup.Option
                            value={"card"}
                            className={({ checked }) =>
                              classNames(
                                checked
                                  ? "z-10 border-indigo-200 bg-indigo-50"
                                  : "border-gray-200",
                                "relative flex items-center cursor-pointer border p-4 focus:outline-none"
                              )
                            }
                          >
                            {({ active, checked }) => (
                              <>
                                <span
                                  className={classNames(
                                    selectedAddOption == "card"
                                      ? "bg-indigo-600 border-transparent"
                                      : "bg-white border-gray-300",
                                    active
                                      ? "ring-2 ring-offset-2 ring-indigo-600 bg-indigo-600"
                                      : "",
                                    "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                  )}
                                  aria-hidden="true"
                                >
                                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                                </span>
                                <span className="inline-flex items-center mx-3">
                                  <img
                                    src={addcardImg}
                                    alt="addcardImg"
                                    className=" w-10 mt-1  flex-shrink-0"
                                  />
                                </span>
                                <span className="ml-3 flex flex-col">
                                  <RadioGroup.Label
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? "text-indigo-900"
                                        : "text-gray-900",
                                      "block text-base font-bold"
                                    )}
                                  >
                                    Add Credit or Debit Card
                                  </RadioGroup.Label>
                                  <RadioGroup.Description
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? "text-indigo-700"
                                        : "text-gray-400",
                                      "block text-sm"
                                    )}
                                  >
                                    Add card
                                  </RadioGroup.Description>
                                </span>
                              </>
                            )}
                          </RadioGroup.Option>
                        </div>
                        <div className="-space-y-px rounded-md bg-white">
                          <RadioGroup.Option
                            value={"ach"}
                            className={({ checked }) =>
                              classNames(
                                checked
                                  ? "z-10 border-indigo-200 bg-indigo-50"
                                  : "border-gray-200",
                                "relative flex items-center cursor-pointer border p-4 focus:outline-none"
                              )
                            }
                          >
                            {({ active, checked }) => (
                              <>
                                <span
                                  className={classNames(
                                    selectedAddOption == "ach"
                                      ? "bg-indigo-600 border-transparent"
                                      : "bg-white border-gray-300",
                                    active
                                      ? "ring-2 ring-offset-2 ring-indigo-600 bg-indigo-600"
                                      : "",
                                    "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                  )}
                                  aria-hidden="true"
                                >
                                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                                </span>
                                <span className="inline-flex items-center mx-3">
                                  <img
                                    src={bankImg}
                                    alt="bankImg"
                                    className=" w-10 mt-1  flex-shrink-0"
                                  />
                                </span>
                                <span className="ml-3 flex flex-col">
                                  <RadioGroup.Label
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? "text-indigo-900"
                                        : "text-gray-900",
                                      "block text-base font-bold"
                                    )}
                                  >
                                    Add Bank ACH
                                  </RadioGroup.Label>
                                  <RadioGroup.Description
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? "text-indigo-700"
                                        : "text-gray-400",
                                      "block text-sm"
                                    )}
                                  >
                                    Add Ach
                                  </RadioGroup.Description>
                                </span>
                              </>
                            )}
                          </RadioGroup.Option>
                        </div>
                      </RadioGroup>
                    </div>
                  </div>
                  <div className="my-2">
                    <div className="text-xl font-semibold text-[#344767] flex justify-between items-center">
                      <div className="flex items-center">
                        <div className="text-xl font-bold">Payment Details</div>
                      </div>
                    </div>
                    <div className="shadow-lg bg-white rounded-xl my-5 border">
                      <div className="px-4">
                        <div className="overflow-x-auto py-5">
                          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table class="w-full text-sm text-left text-gray-500 ">
                              <tbody>
                                <tr class="bg-[#f5f5f5] font-sans">
                                  <th
                                    scope="row"
                                    class="px-3  font-bold text-[#344767] whitespace-nowrap text-base"
                                  >
                                    Payment Amount
                                  </th>
                                  <td class="px-6 py-4 text-lg font-bold">
                                    {payableAmount
                                      ? formatUSD(
                                          String(payableAmount).replace(
                                            /[$,]/g,
                                            ""
                                          )
                                        )
                                      : "0.00"}
                                  </td>
                                </tr>
                                <tr class=" bg-white font-sans">
                                  <th
                                    scope="row"
                                    class="px-3  font-bold text-[#344767] whitespace-nowrap text-base"
                                  >
                                    Date Due
                                  </th>
                                  <td class="px-6 py-4 text-lg font-bold">
                                    {paymentScheduledDetails?.due_payments
                                      ? formatDate(
                                          paymentScheduledDetails?.due_payments[
                                            paymentScheduledDetails
                                              ?.due_payments.length - 1
                                          ]?.date
                                        )
                                      : "--"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      </ModalComponent>
      <ModalComponent open={grantPlaidAccess} handleClose={() => {}}>
        <div className="flex justify-center items-center">
          <div className="w-full  relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationCircleIcon
                    className="h-8 w-8 text-indigo-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    ALERT
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Note:{" "}
                      <span className="text-black font-bold">
                        The Bank Account you have choosen is not verified
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {plaidToken == null ? (
              <div className="w-full flex justify-center items-center">
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#1a3253"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse gap-3 sm:px-6">
                <div className="mt-3 inline-flex w-full justify-center items-center rounded-md bg-blue-500 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-blue-400 sm:mt-0 sm:w-auto">
                  <PlaidLink
                    token={plaidToken}
                    onSuccess={onSuccess}
                    // onExit={...}
                    // onEvent={...}
                    style={{
                      // marginTop: "0.75rem",
                      // display: "inline-flex",
                      // justifyContent: "center",
                      // borderRadius: "0.375rem",
                      // width: "100%",
                      backgroundColor: "#3B82F6",
                      padding: "0.75rem 1rem",
                      // fontSize: "0.875rem",
                      // fontWeight: "600",
                      color: "#ffff",
                      // boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
                      border: "none",
                      // textDecoration: "none",
                      // textAlign: "center",
                      // transition: "background 0.3s ease",
                    }}
                  >
                    Verify
                  </PlaidLink>
                </div>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={() => setGrantPlaidAccess(false)}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default ClientDashboard;
