import React, { useEffect, useState } from "react";
import { domainUrl, logOutHandler } from "../utils/constants";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import ClientAttorneyView from "./ClientAttorneyView";
import ClientScheduleTable from "./Tables/ClientScheduleTable";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const ClientContractsView = () => {
  const [data, setData] = useState({});
  const [globalLoading, setGlobalLoading] = useState(false);

  const navigate = useNavigate()
  const access_token = localStorage.getItem("access_token");
  const role = localStorage.getItem("role");
  const paymentPlanString = {
    weekly: "Weekly Payments",
    bi_weekly: "Bi-Weekly Payments",
    semi_monthly_dates: "Semi-Monthly By Date",
    semi_monthly_days: "Semi-Monthly By Day",
    monthly: "Monthly Payments",
  };
  const formatDate = (dateString) => {
    //   const originalDate = new Date(dateString);
    //   console.log(dateString)
    //   console.log(originalDate)
    //   const day = originalDate.getDate().toString().padStart(2, "0");
    //   const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    //   const year = originalDate.getFullYear();

    //   return `${month}/${day}/${year}`;
    console.log(dateString);
    if (dateString) {
      const date = dateString.split("-");
      console.log(date);
      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };
  function formatUSD(number) {
    // Check if the input is a valid number
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    // Format the number as USD currency
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }
  const getClientDetailsHandler = async () => {
    try {
      setGlobalLoading(true);
      const res = await axios.get(`${domainUrl}webform`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (res.data) {
        setData(res.data);
        console.log(res.data);
        setGlobalLoading(false);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler()
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setGlobalLoading(false);
    }
  };
  useEffect(() => {
    getClientDetailsHandler();
  }, []);

  return (
    <div>
      {globalLoading ? (
        <div className="w-full h-screen flex justify-center items-center">
          <Oval
            height={80}
            width={80}
            color="#1a3253"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-globalLoading"
            secondaryColor="#396181"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div className="mt-5">
          <ClientAttorneyView />
          <div className="p-4">
            <div className=" md:w-full lg:w-full lg:p-0  flex items-center justify-center rounded-lg">
              <div className="border sm:w-screen bg-white  rounded-lg md:w-full lg:w-full px-8 py-8 shadow-lg w-96">
                <h2 className="text-xl font-semibold mb-4 text-[#344767]">
                  My Contract
                </h2>

                <div>
                  <div className="mt-6 border-t border-gray-100">
                    <dl className="divide-y divide-gray-100">
                      <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Contract Amount
                        </dt>
                        <dd className="mt-1 text-lg leading-6 font-semi bold  text-gray-900 sm:col-span-2 sm:mt-0">
                          {data?.contracts
                            ? formatUSD(data.contracts[0].contract_amount)
                            : ""}
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Case Number
                        </dt>
                        <dd className="mt-1 text-lg leading-6 font-semi bold  text-gray-900 sm:col-span-2 sm:mt-0">
                          {data?.contracts ? data.contracts[0].case_number : ""}
                        </dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Case Type
                        </dt>
                        <dd className="mt-1 text-lg leading-6 font-semi bold  text-gray-900 sm:col-span-2 sm:mt-0">
                          {data?.contracts ? data.contracts[0].case_type : ""}
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Payment Plan
                        </dt>
                        <dd className="mt-1 text-lg leading-6 font-semi bold  text-gray-900 sm:col-span-2 sm:mt-0">
                          {data?.contracts
                            ? paymentPlanString[data.contracts[0].payment_plan]
                            : ""}
                        </dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Tenure
                        </dt>
                        <dd className="mt-1 text-lg leading-6 font-semi bold  text-gray-900 sm:col-span-2 sm:mt-0">
                          {data?.contracts
                            ? `${data.contracts[0].tenure} months`
                            : ""}
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Down Payment
                        </dt>
                        <dd className="mt-1 text-lg leading-6 font-semi bold  text-gray-900 sm:col-span-2 sm:mt-0">
                          {data?.contracts
                            ? `${formatUSD(
                                data.contracts[0].down_payment
                                  ? data.contracts[0].down_payment
                                  : 0
                              )}`
                            : ""}
                        </dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Start Date
                        </dt>
                        <dd className="mt-1 text-lg leading-6 font-semi bold  text-gray-900 sm:col-span-2 sm:mt-0">
                          {data?.contracts
                            ? formatDate(data.contracts[0].start_date)
                            : ""}
                        </dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          End Date
                        </dt>
                        <dd className="mt-1 text-lg leading-6 font-semi bold  text-gray-900 sm:col-span-2 sm:mt-0">
                          {data?.contracts
                            ? formatDate(data.contracts[0].end_date)
                            : ""}
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Attorney
                        </dt>
                        <dd className="mt-1 text-lg leading-6 font-semi bold  text-gray-900 sm:col-span-2 sm:mt-0">
                          {data?.contracts
                            ? `${data.contracts[0].attorney.user.first_name} ${data.contracts[0].attorney.user.last_name}`
                            : ""}
                        </dd>
                      </div>
                      <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                        <dt className="text-sm font-medium leading-6 text-gray-900">
                          Firm Name
                        </dt>
                        <dd className="mt-1 text-lg leading-6 font-semi bold  text-gray-900 sm:col-span-2 sm:mt-0">
                          {data?.contracts
                            ? data.contracts[0].attorney.firm.firm_name
                            : ""}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
          <>
            {data?.contracts ? (
              <ClientScheduleTable contract_id={data?.contracts[0]?.id} />
            ) : (
              ""
            )}
          </>
        </div>
      )}
    </div>
  );
};

export default ClientContractsView;
