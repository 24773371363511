// otpLogin.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { domainUrl, logOutHandler } from "../../constants";
import toast from "react-hot-toast";

export const otpPost = createAsyncThunk("otpPost", async (data, thunkAPI) => {
  try {
    const res = await axios.post(`${domainUrl}login`, JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("this is otp res ", res.data);
    return res.data;
  } catch (error) {
    if (error.response?.data.code == "token_not_valid") {
      logOutHandler();
      const navigate = thunkAPI.extra.navigateCallback;
      navigate("/login");
      toast.error("Session Expired", { id: 1 });
      return;
    } else {
      throw error.response.data.detail;
    }
  }
});

const otpSlice = createSlice({
  name: "otpStorage",
  initialState: { data: [], isLoading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(otpPost.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(otpPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(otpPost.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.detail;
      });
  },
});

export const {} = otpSlice.actions;
export default otpSlice.reducer;
