import { motion } from "framer-motion";

import PendingClientsTable from "../Tables/PendingClientsTable";
import PendingInvitationsTable from "../Tables/PendingInvitationsTable";
import ApprovedClientsTable from "../Tables/ApprovedClientsTable";
import RejectedClientsTable from "../Tables/RejectedClientsTable";
import DelinquentPaymentsBar from "../../Components/Graphs/DelinquentPaymentsBar";
import ExpectedPaymentsBar from "../../Components/Graphs/ExpectedPaymentsBar";
import FundCirculationPie from "../../Components/Graphs/FundCirculationPie";
import LocAndContractLine from "../../Components/Graphs/LocAndContractLine";
import ContractPerformanceBar from "../../Components/Graphs/ContractPerformanceBar";
import CustomerManagementBar from "../../Components/Graphs/CustomerManagementBar";

export default function FirmDashboard() {
  return (
    <>
      <div>
        <motion.div
          initial={{ y: 100 }}
          animate={{ y: 0 }}
          transition={{ delay: 1, type: "spring", stiffness: 120 }}
        >
          <main className="py-10">
            <div className="w-full">
              <div className="mt-5 container m-auto grid grid-cols-1 place-items-center gap-x-2 gap-y-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">

                <DelinquentPaymentsBar />
                <FundCirculationPie />
                <LocAndContractLine />
                <ExpectedPaymentsBar />
                <ContractPerformanceBar />
                <CustomerManagementBar />
              </div>
            </div>

            <PendingInvitationsTable />
            <PendingClientsTable />
            <ApprovedClientsTable />
            <RejectedClientsTable />
          </main>
        </motion.div>
      </div>
    </>
  );
}
