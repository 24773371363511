import React from "react";
import PendingClientsTable from "./Tables/PendingClientsTable";

const PendingClients = () => {
  return (
    <div>
      <PendingClientsTable />
    </div>
  );
};

export default PendingClients;
