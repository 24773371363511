import { Navigate } from "react-router-dom";
import AddClients from "../../Pages/AddClients";

const RequireAuth = ({ children, path }) => {
  const access_token = localStorage.getItem("access_token");
  const role = localStorage.getItem("role");
  const client_add_block = localStorage.getItem("client_add_block");
  console.log(path);
  // return;
  let RouteMap = {
    admin: [
      //basic

      "/dashboard",
      "/admin-home",
      "/admin-home-dev",
      "/profile-page",
      "/firm-overview",
      "/client-overview",

      //major
      "/add-firm",
      "/list-firm",
      "/firm-payouts",
      "/payout-history",
      "/escrow-overview",
      "/view-firm",
      "/update-clients",
      "/update-firm",
      "/client-view",
      "/list-clients",
      "/delinquent-clients",
      "/predelinquent-clients",
      "/pastdelinquent-clients",
      "/add-attorney",
      "/list-attorneys",
      "/attorney-profile",
      "/attorney-pending-invitations",
      "/pending-invitations",
      "/pending-clients",
      "/rejected-clients",
      "/approved-clients",
      "/view-pending-client",
      "/view-rejected-client",
      "/paused-clients",
      "/archived-clients",
      "/missed-payment-clients",
      "/manage-holidays",
      "/escrow-history",
      "/transaction-history",
      "/delinquent-payments",
      "/expected-payments",
      "/admin-payment-page",
      "/client-total-expected",
      "/client-total-received",
      "/on-time-clients",
      "/delayed-clients",
      "/completed-clients",
      "/financial-reports",
      "/total-escrow-balance",
      "/audit-log",
    ],
    primaryattorney: [
      //basic

      "/dashboard",
      "/firm-home",
      "/profile-page",

      //major
      "/escrow-overview",
      "/add-clients",
      "/update-clients",
      "/list-clients",
      "/delinquent-clients",
      "/predelinquent-clients",
      "/add-attorney",
      "/list-attorneys",
      "/attorney-profile",
      "/pending-invitations",
      "/pending-clients",
      "/rejected-clients",
      "/approved-clients",
      "/view-pending-client",
      "/view-rejected-client",
      "/missed-payment-clients",
      "/client-view",
      "/client-overview",
      "/delinquent-payments",
      "/financial-reports",
      "/expected-payments",
      "/firm-escrow-performance",
      "/client-total-expected",
      "/client-total-received",
      "/on-time-clients",
      "/delayed-clients",
      "/completed-clients",
    ],
    attorney: [
      //basic

      "/dashboard",
      "/attorney-home",
      "/profile-page",

      //major

      "/add-clients",
      "/update-clients",
      "/list-clients",
      "/delinquent-clients",
      "/predelinquent-clients",
      "/pending-invitations",
      "/pending-clients",
      "/rejected-clients",
      "/approved-clients",
      "/client-view",
      "/client-overview",
      "/view-pending-client",
      "/view-rejected-client",
      // "/view-client-profile",
      "/attorney-payout-history",
      "/attorney-all-payments",
      "/attorney-client-contracts",
      "/attorney-total-clients",
      "/missed-payment-clients",
      "/on-time-clients",
      "/transaction-history",
    ],
    client: [
      //basic

      "/client-paynow",
      "/dashboard",
      "/client-home",
      "/profile-page",
      "/client-attorney-view",
      "/client-contracts-view",
    ],
  };

  // RouteMap[role.replace("-","")]
  if (role) {
    console.log(
      role,
      role.replace("-", ""),
      RouteMap[role.replace("-", "")].includes(path)
    );
    if (RouteMap[role.replace("-", "")].includes(path)) {
      if (access_token === undefined || access_token === null) {
        return <Navigate replace to="/login" />;
      }
      if (client_add_block && path !== "/add-clients") {
        localStorage.setItem("add_exit_modal", true);
        return <AddClients />;
        // return <Navigate to={"/add-clients"} />;
      }
      return <>{children}</>;
    } else {
      return <Navigate replace to={"/forbidden"} />;
    }
  }
  return <Navigate to={-1} />;
};

export default RequireAuth;
