import React from 'react'
import DelinquentClientsTable from './Tables/DelinquentClientsTable'

const DelinquentClients = () => {
  return (
    <>
     <DelinquentClientsTable/> 
    </>
  )
}

export default DelinquentClients
