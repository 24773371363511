import { useNavigate } from "react-router-dom";
import axios from "axios";
import { domainUrl, logOutHandler } from "../../utils/constants";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  Cell,
} from "recharts";

export default function DelinquentPaymentsBar() {
  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const [delinquentGraphData, setDelinquentGraphData] = useState({
    30: {},
    60: {},
    90: {},
    180: {},
    older: {},
  });

  const maxPaymentsTotal = Object.keys(delinquentGraphData).reduce(
    (maxTotal, key) => {
      const paymentsTotal = delinquentGraphData[key]?.payments_total || 0;
      return Math.max(maxTotal, paymentsTotal);
    },
    0
  );

  const getDelinquentPaymentGraphData = async (date_range) => {
    try {
      const res = await axios.get(
        `${domainUrl}delinquent-payments?date_range=${date_range}&graph=true`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data, delinquentGraphData);

        if (res.data.date_range == "0,30") {
          setDelinquentGraphData((prevData) => ({
            ...prevData,
            30: res.data,
          }));
        } else if (res.data.date_range == "30,60") {
          setDelinquentGraphData((prevData) => ({
            ...prevData,
            60: res.data,
          }));
        } else if (res.data.date_range == "60,90") {
          setDelinquentGraphData((prevData) => ({
            ...prevData,
            90: res.data,
          }));
        } else if (res.data.date_range == "90,180") {
          setDelinquentGraphData((prevData) => ({
            ...prevData,
            180: res.data,
          }));
        } else {
          setDelinquentGraphData((prevData) => ({
            ...prevData,
            older: res.data,
          }));
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  };

  function formatUSD(number) {
    // Check if the input is a valid number
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    // Format the number as USD currency
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }

  const data = [
    {
      name: "30",
      payment: delinquentGraphData[30]?.payments_delinquent,
    },
    {
      name: "60",
      payment: delinquentGraphData[60]?.payments_delinquent,
    },
    {
      name: "90",
      payment: delinquentGraphData[90]?.payments_delinquent,
    },
    {
      name: "180",
      payment: delinquentGraphData[180]?.payments_delinquent,
    },
    {
      name: "Older",
      payment: delinquentGraphData["older"]?.payments_delinquent,
    },
  ];

  useEffect(() => {
    getDelinquentPaymentGraphData("0,30");
    getDelinquentPaymentGraphData("30,60");
    getDelinquentPaymentGraphData("60,90");
    getDelinquentPaymentGraphData("90,180");
    getDelinquentPaymentGraphData("180,365");
  }, []);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-2 bg-white border border-black shadow-md flex flex-col gap-4 rounded-md">
          <p className="text-medium text-sm">{label} Days</p>
          <p className="text-lg font-semibold text-blue-600">
            Payment:
            <span className="ml-2">
              {formatUSD(payload[0].payload.payment)}
            </span>
          </p>
        </div>
      );
    }
  };

  const colors = ["#FFBF00", "#ee9a00", "#90EE90", "#ADD8E6", "#0054a8"];

  return (
    <>
      <div className="w-full">
        <div className="w-full h-[350px] p-2 flex flex-col justify-center items-center border border-gray-200 rounded-xl shadow-md">
          <div className="mb-3 text-base font-bold">DELINQUENT PAYMENTS</div>
          <div className="w-full h-60 sm:w-[27rem] lg:w-[27rem]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width="100%"
                height="100%"
                data={data}
                onClick={(event, entry) => {
                  console.log(event, entry);
                  if (!event?.activePayload) {
                    return;
                  }
                  // Determine which bar was clicked and navigate accordingly
                  const routeMap = {
                    30: "0,30",
                    60: "30,60",
                    90: "60,90",
                    180: "90,180",
                    Older: "180,9999",
                  };
                  const route = routeMap[event?.activeLabel];
                  if (route) {
                    localStorage.setItem(
                      "query_param/delinquent_payments",
                      route
                    );
                    navigate("/delinquent-payments"); // Navigate to the corresponding route
                  }
                }}
                margin={{
                  right: 35,
                  left: 35,
                }}
                padding={{
                  top: 20,
                  right: 20,
                  left: 20,
                  bottom: 20,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" interval={0} tick={{ fontSize: 10 }} />
                <YAxis
                  tickFormatter={formatUSD}
                  domain={[0, maxPaymentsTotal + maxPaymentsTotal * 0.1]}
                  tickCount={8}
                  tick={{ fontSize: 10 }}
                />
                <Tooltip
                  wrapperStyle={{ zIndex: 2 }}
                  content={<CustomTooltip />}
                />
                {/* <Legend /> */}
                <Bar
                  className="hover:cursor-pointer"
                  dataKey="payment"
                  barSize={50}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                  {/* <LabelList
                          dataKey="payment"
                          position={"top"}
                          angle={0}
                          textAnchor={"end"}
                          // formatter={formatUSD}
                          fill="black"
                        /> */}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="my-3 flex flex-col justify-evenly items-start  gap-2">
            <div className="flex  gap-3 justify-center items-start">
              <div className="text-sm inline-flex items-center gap-1">
                <span className="h-2 w-2 bg-[#FFBF00]"></span>30 days
              </div>
              <div className="text-sm inline-flex items-center gap-1">
                <span className="h-2 w-2 bg-[#ee9a00]"></span>60 days
              </div>
            </div>
            <div className="flex  gap-3 justify-center items-start">
              <div className="text-sm inline-flex items-center gap-1">
                <span className="h-2 w-2 bg-[#90EE90]"></span>90 days
              </div>
              <div className="text-sm inline-flex items-center gap-1">
                <span className="h-2 w-2 bg-[#ADD8E6]"></span>180 days
              </div>
            </div>
            <div className="flex  gap-3 justify-center items-start">
              <div className="text-sm inline-flex items-center gap-1">
                <span className="h-2 w-2 bg-[#0054a8]"></span>Older
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
