import { useEffect, useMemo, useState } from "react";
import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Line, Doughnut } from "react-chartjs-2";
import ExportImg from "../../assets/img/excel-img.png";
import UserProf from "../../assets/img/user_profile.png";
import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import MaterialReactTable from "material-react-table";
import { Hourglass, ThreeDots } from "react-loader-spinner";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import SmsIcon from "@mui/icons-material/Sms";
import { useNavigate } from "react-router-dom";
import { domainUrl, listedStates, logOutHandler } from "../../utils/constants";
import axios from "axios";
import toast from "react-hot-toast";
import { Avatar, Button, IconButton } from "@mui/material";
ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AuditLogTable() {
  const [data, setData] = useState([]);

  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isRefetching, setIsRefetching] = useState(false);

  const [rowCount, setRowCount] = useState(0);

  const role = localStorage.getItem("role");
  const access_token = localStorage.getItem("access_token");

  const navigate = useNavigate();
  //table state
  const [columnFilters, setColumnFilters] = useState([]);

  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [invitationEmailLoaders, setInvitationEmailLoaders] = useState({});
  const [invitationSmsLoaders, setInvitationSmsLoaders] = useState({});
  const [tableRefetch, setTableRefetch] = useState(false);

  const paymentPlanString = {
    weekly: "Weekly Payments",
    bi_weekly: "Bi-Weekly Payments",
    semi_monthly_dates: "Semi-Monthly By Date",
    semi_monthly_days: "Semi-Monthly By Day",
    monthly: "Monthly Payments",
  };

  function extractDateFromTimestamp(timestamp) {
    const dateObject = new Date(timestamp);
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 as getMonth() returns 0-based index
    const day = dateObject.getDate().toString().padStart(2, "0");
    const year = dateObject.getFullYear();
    return `${month}/${day}/${year}`;
  }

  function extractTimeFromTimestamp(timestamp) {
    const dateObject = new Date(timestamp);
    const hour = dateObject.getHours().toString().padStart(2, "0");
    const minute = dateObject.getMinutes().toString().padStart(2, "0");
    return `${hour}:${minute}`;
  }

  const resendEmailInvitation = async (id) => {
    try {
      setInvitationEmailLoaders({ ...invitationEmailLoaders, [id]: true });
      const res = await axios.post(
        `${domainUrl}invitations?id=${id}&type=email`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        setTableRefetch(!tableRefetch);
        setInvitationEmailLoaders({ ...invitationEmailLoaders, [id]: false });
        toast.success("An invitation has been send via Email", { id: 1 });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setInvitationEmailLoaders({ ...invitationEmailLoaders, [id]: false });
      toast.error("Something went wrong", { id: 1 });
    }
  };
  const resendSmsInvitation = async (id) => {
    try {
      setInvitationSmsLoaders({ ...invitationSmsLoaders, [id]: true });
      const res = await axios.post(
        `${domainUrl}invitations?id=${id}&type=sms`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        setTableRefetch(!tableRefetch);
        setInvitationSmsLoaders({ ...invitationSmsLoaders, [id]: false });
        toast.success("An invitation has been send via Sms", { id: 1 });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setInvitationSmsLoaders({ ...invitationSmsLoaders, [id]: false });
      toast.error("Something went wrong", { id: 1 });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const view_attorney_id = localStorage.getItem("view_attorney_id");
      const view_firm_id = localStorage.getItem("view_firm_id");
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL(
        // 'event-reports','http://191.96.1.252:8000/',
        "logs",
        `${domainUrl}`
      );
      url.searchParams.set(
        "offset",
        pagination.pageIndex * pagination.pageSize
      );
      url.searchParams.set("limit", `${pagination.pageSize}`);
      url.searchParams.set("offset", `${pagination.pageIndex}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("search", search ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      url.searchParams.set("table", `true`);
      try {
        const response = await fetch(url.href, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });
        let json = await response.json();
        let data = json;
        console.log("###", data);
        if (data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setData(data.data);
        setRowCount(data.count);

        // setRowCount(Math.ceil(data.count / pagination.pageSize));
        // setRowCount(5);
        // Math.ceil(totalCount / pagination.pageSize)
        // setTotalCount(data.count);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    search,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    tableRefetch,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "clients",
        header: "",
        columns: [
          {
            id: "created_time",
            header: "Created Date",
            accessorFn: (row) =>
              `${extractDateFromTimestamp(
                row.created_time
              )}  -  (${extractTimeFromTimestamp(row.created_time)})`,
            muiTableHeadCellFilterTextFieldProps: { placeholder: "YYYY-MM-DD" },
            accessorKey: "created_time",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "log_type",
            header: "Log Type",
            enableColumnActions: false,
            accessorFn: (row) => `${row.log_type}`,
            accessorKey: "log_type",
            size: 30,
          },
          // {
          //   id: "user",
          //   header: "User",
          //   enableColumnActions: false,
          //   accessorFn: (row) => `${row.user.first_name} ${row.user.last_name} [${row.user.email}]`,
          //   accessorKey: "user",
          //   size: 30,
          // },
          {
            id: "edited_by",
            header: "Performed By",
            enableColumnActions: false,
            accessorFn: (row) => `${row?.edited_by?.first_name} ${row?.edited_by?.last_name} [${row?.edited_by?.email}]`,
            accessorKey: "edited_by",
            size: 30,
          },
        ],
      },
    ],

    []
  );
  const formatDate = (dateString) => {
    //   const originalDate = new Date(dateString);
    //   console.log(dateString)
    //   console.log(originalDate)
    //   const day = originalDate.getDate().toString().padStart(2, "0");
    //   const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    //   const year = originalDate.getFullYear();

    //   return `${month}/${day}/${year}`;
    console.log(dateString);
    if (dateString) {
      const date = dateString.split("-");
      console.log(date);
      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };
  const getStateByCode = (code) => {
    const state = listedStates.find((state) => state.code === code);
    return state ? state : null;
  };
  function formatUSD(number) {
    // Check if the input is a valid number
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    // Format the number as USD currency
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }
  function formatUSCell(usPhoneNumber) {
    // Remove any non-digit characters
    let digitsOnly = usPhoneNumber.replace(/\D/g, "");

    // Check if the number has the expected length of 10 digits
    if (digitsOnly.length !== 10) {
      return "Invalid phone number format";
    }

    // Format the number in the original format and add +1 in front
    return "+1 " + digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }

  const [rowSelection, setRowSelection] = useState({});
  const [bulkEmailStatus, setBulkEmailStatus] = useState(false);
  const [bulkSmsStatus, setBulkSmsStatus] = useState(false);
  const [bulkEmailInvitationLoaders, setBulkEmailInvitationLoaders] =
    useState(false);
  const [bulkSmsInvitationLoaders, setBulkSmsInvitationLoaders] =
    useState(false);

  useEffect(() => {
    const length = Object.keys(rowSelection).length;
    console.log("JSON object length: " + length, rowSelection, data);
    if (length >= 1) {
      setBulkEmailStatus(true);
      setBulkSmsStatus(true);
    } else {
      setBulkEmailStatus(false);
      setBulkSmsStatus(false);
    }
  }, [rowSelection]);

  const sendBulk = async (type) => {
    const choosenRows = Object.keys(rowSelection).filter(
      (value) => rowSelection[value]
    );

    console.log("Choosen Rows", choosenRows);
    const idsToSend = choosenRows.map((index) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id == index) {
          return data[i].invitation.id;
        }
      }
    });
    console.log("Selected ids", idsToSend);
    let url = "";
    if (idsToSend.length > 1) {
      url = `${domainUrl}invitations?ids=${idsToSend}&type=${type}`;
    } else {
      url = `${domainUrl}invitations?id=${idsToSend}&type=${type}`;
    }
    if (type == "sms") {
      try {
        setBulkSmsInvitationLoaders(true);
        const res = await axios.post(
          url,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (res.data) {
          console.log(res.data);
          setTableRefetch(!tableRefetch);
          setBulkSmsInvitationLoaders(false);
          toast.success("Invited via SMS to the selected", { id: 1 });
          setRowSelection({});
        }
      } catch (error) {
        console.log(error);
        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setBulkSmsInvitationLoaders(false);
      }
    } else {
      try {
        setBulkEmailInvitationLoaders(true);
        const res = await axios.post(
          url,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (res.data) {
          console.log(res.data);
          setTableRefetch(!tableRefetch);
          setBulkEmailInvitationLoaders(false);
          toast.success("Invited via Email to the selected", { id: 1 });
          setRowSelection({});
        }
      } catch (error) {
        console.log(error);
        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setBulkEmailInvitationLoaders(false);
      }
    }
  };
  const exportFileHandler = async (id) => {
    const url = new URL(
      // 'event-reports','http://191.96.1.252:8000/',
      "clients",
      `${domainUrl}`
    );
    url.searchParams.set("status", "pending-invitation");
    url.searchParams.set("offset", pagination.pageIndex * pagination.pageSize);
    url.searchParams.set("limit", `${pagination.pageSize}`);
    url.searchParams.set("offset", `${pagination.pageIndex}`);
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("search", search ?? "");
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("export", true);

    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        console.log(response);
        console.log(response.headers.get("content-type"));
        console.log(typeof response.headers.get("content-type"));
        if (response.headers.get("content-type") == "application/json") {
          // toast
          console.log("inside json if");
          console.log(response.data.scheduled);
          response.data.text().then((text) => {
            toast.success(JSON.parse(text).scheduled, { autoClose: 10000 });
          });
        } else {
          let filename = `Pending Invitations_${Date.now()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  return (
    <>
      <div>
        <main className="py-10">
          <main className="w-full py-10 ">
            <div className="mx-5">
              <h2
                style={{
                  color: "#1a3253",
                }}
              >
                Audit Log
              </h2>
              <div className="text-base text-[#9ca0b3] my-2"></div>

              <div className="p-1 border rounded-lg">
                <MaterialReactTable
                  enableTopToolbar={true}
                  enableStickyHeader
                  // ############################################################
                  enableRowSelection={false}
                  renderTopToolbarCustomActions={() => {
                    if (role == "admin") {
                      return (
                        <div className="sm:flex justify-between gap-3">
                          <div className="mt-3 flex flex-col items-start gap-3 sm:flex-row sm:items-center sm:mt-0">
                            <Button
                              disabled={false}
                              startIcon={<Avatar src={ExportImg} />}
                              onClick={() => {
                                // Implement export logic for the selected rows (10 or 30) here
                                console.log("Exporting as Excel");
                                exportFileHandler();
                              }}
                              variant="contained"
                              sx={{
                                bgcolor: "#127c44",
                                "&:hover": {
                                  bgcolor: "#127c44",
                                },
                                height: "25px",
                              }}
                            >
                              Export
                            </Button>
                          </div>
                        </div>
                      );
                    }
                    return;
                  }}
                  // getRowId={(row) => row.userId} //give each row a more useful id
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  // state={{ rowSelection }} //pass our managed row selection state to the table to use
                  // ######################################################################
                  muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                  // enableColumnResizing
                  // columnResizeMode="onEnd"
                  muiTableBodyProps={{
                    sx: () => ({
                      "& tr:nth-child(odd)": {
                        backgroundColor: "#f8f9fa",
                      },
                      "& tr:nth-child(even)": {
                        backgroundColor: "#ffffff", // Set the background color for even rows
                      },
                    }),
                  }}
                  muiTablePaperProps={{
                    sx: {
                      maxWidth: "full",
                      // m: 'auto'
                    },
                    elevation: 0,
                  }}
                  muiTableHeadCellProps={{
                    sx: (theme) => ({
                      // background: "rgba(52, 210, 235, 0.1)",
                      // borderRight: "1px solid rgba(224,224,224,1)",
                      color: theme.palette.text.primary,
                    }),
                  }}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  enableColumnFilters={true}
                  rowNumberMode="static"
                  renderDetailPanel={({ row }) => {
                    return (
                      <div className="bg-white">
                        <div className="relative mx-auto  max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
                          <div className="px-4 sm:px-6 lg:px-8">
                            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                              {row.original.is_data_present && (<table className="w-full text-sm text-left rtl:text-right text-gray-500">
                                <thead className="text-sm text-gray-700  bg-gray-50 border-b border-gray-600">
                                  <tr>
                                    <th scope="col" className="px-6 py-3">
                                      Field
                                    </th>
                                    {row.original.prev_data && (<th scope="col" className="px-6 py-3">
                                      Modified from
                                    </th>)}
                                    {row.original.modified_data && (<th scope="col" className="px-6 py-3">
                                      Modified to
                                    </th>)}
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(row.original.modified_data).map((value, index) =>

                                  (<tr className="bg-white border-b">
                                    <th
                                      scope="col"
                                      className="bg-white px-6 py-4 font-bold text-gray-900 whitespace-nowrap  border-r border-gray-600"
                                    >{
                                        value}
                                    </th>
                                    {row.original.prev_data && (<td className="px-6 py-4">{row.original.prev_data[value]}</td>)}
                                    <td className="px-6 py-4">{row.original.modified_data[value]}</td>
                                  </tr>)
                                  )}


                                </tbody>
                              </table>)}


                              {!row.original.is_data_present && (<div className="w-fit p-3 text-base font-semibold text-gray-900">
                                {row.original.string_representation}
                              </div>)}
                            </div>
                          </div>
                        </div>

                      </div>


                    );
                  }}
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10, 15, 20, 25],
                    showFirstButton: false,
                    showLastButton: false,
                  }}
                  columns={columns}
                  data={data}
                  enableRowActions={false}
                  positionActionsColumn="last"
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      header: null, //change header text
                      // size: 300, //make actions column wider
                    },
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    //implement row selection click events manually
                    onClick: () => {
                      if (data.length) {
                        console.log(row.original.id);
                        // console.log(data[row.id - 1].id);
                        console.log("Clicked on ", row.id);
                        //alert();
                        // navigate(`/view-firm`);
                      }
                    },
                    // selected: rowSelection[row.id],
                    sx: {
                      cursor: "pointer",
                    },
                  })}
                  getRowId={(row) => row.id}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                  }}
                  // manualFiltering
                  manualPagination
                  manualSorting
                  muiToolbaralertBannerProps={
                    isError
                      ? {
                        color: "error",
                        children: "Error loading data",
                      }
                      : undefined
                  }
                  onColumnFiltersChange={setColumnFilters}
                  onGlobalFilterChange={setSearch}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  rowCount={rowCount}
                  // rowCount={Math.ceil(totalCount / pagination.pageSize)}
                  state={{
                    // columnFilters,
                    // search,
                    rowSelection,
                    isLoading,
                    pagination,
                    showalertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                  }}
                />
              </div>
            </div>
          </main>
        </main>
      </div>
    </>
  );
}
