import React from "react";
import ApprovedClientsTable from "./Tables/ApprovedClientsTable";

const ApprovedClients = () => {
  return (
    <div>
      <ApprovedClientsTable />
    </div>
  );
};

export default ApprovedClients;
