// editAttoreny.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { domainUrl, logOutHandler } from "../../constants";
import toast from "react-hot-toast";

export const editAttorneyProfilePut = createAsyncThunk(
  "editAttorneyProfilePut",
  async (data,thunkAPI) => {
    try {
      console.log("Token 1");
      const access_token = localStorage.getItem("access_token");
      const attorneyProf = JSON.parse(localStorage.getItem("attorneyProf"));

      console.log("Token 2", access_token);

      const res = await axios.put(
        `${domainUrl}edit-profile?id=${attorneyProf?.userId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      console.log("this is res", res);
      return res.data;
    } catch (error) {
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        const navigate = thunkAPI.extra.navigateCallback;
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      } else {
        throw JSON.stringify(error.response.data.detail);
      }
    }
  }
);

const editAttorneyProfileSlice = createSlice({
  name: "editAttorneyProfileStorage",
  initialState: { data: [], isLoading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(editAttorneyProfilePut.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(editAttorneyProfilePut.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(editAttorneyProfilePut.rejected, (state, action) => {
        console.log(state, action);
        state.isLoading = false;
        state.error = action.error;
        console.log(action);
      });
  },
});

export default editAttorneyProfileSlice.reducer;
