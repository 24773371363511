import { useEffect, useMemo, useState } from "react";
import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Line, Doughnut } from "react-chartjs-2";
import ExportImg from "../../assets/img/excel-img.png";
import UserProf from "../../assets/img/user_profile.png";
import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import MaterialReactTable from "material-react-table";
import { Hourglass, ThreeDots } from "react-loader-spinner";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import SmsIcon from "@mui/icons-material/Sms";
import { useNavigate } from "react-router-dom";
import { domainUrl, listedStates, logOutHandler } from "../../utils/constants";
import axios from "axios";
import toast from "react-hot-toast";
import { Avatar, Button, IconButton } from "@mui/material";

ChartJS.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PendingInvitationsTable() {
  const [data, setData] = useState([]);

  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isRefetching, setIsRefetching] = useState(false);

  const [rowCount, setRowCount] = useState(0);

  const role = localStorage.getItem("role");
  const access_token = localStorage.getItem("access_token");

  const navigate = useNavigate();
  //table state
  const [columnFilters, setColumnFilters] = useState([]);

  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [invitationEmailLoaders, setInvitationEmailLoaders] = useState({});
  const [invitationSmsLoaders, setInvitationSmsLoaders] = useState({});
  const [tableRefetch, setTableRefetch] = useState(false);

  const paymentPlanString = {
    weekly: "Weekly Payments",
    bi_weekly: "Bi-Weekly Payments",
    semi_monthly_dates: "Semi-Monthly By Date",
    semi_monthly_days: "Semi-Monthly By Day",
    monthly: "Monthly Payments",
  };

  function extractDateFromTimestamp(timestamp) {
    const dateObject = new Date(timestamp);
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 as getMonth() returns 0-based index
    const day = dateObject.getDate().toString().padStart(2, "0");
    const year = dateObject.getFullYear();
    return `${month}/${day}/${year}`;
  }

  function extractTimeFromTimestamp(timestamp) {
    const dateObject = new Date(timestamp);
    const hour = dateObject.getHours().toString().padStart(2, "0");
    const minute = dateObject.getMinutes().toString().padStart(2, "0");
    return `${hour}:${minute}`;
  }

  const resendEmailInvitation = async (id) => {
    try {
      setInvitationEmailLoaders({ ...invitationEmailLoaders, [id]: true });
      const res = await axios.post(
        `${domainUrl}invitations?id=${id}&type=email`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        setTableRefetch(!tableRefetch);
        setInvitationEmailLoaders({ ...invitationEmailLoaders, [id]: false });
        toast.success("An invitation has been send via Email", { id: 1 });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setInvitationEmailLoaders({ ...invitationEmailLoaders, [id]: false });
      toast.error("Something went wrong", { id: 1 });
    }
  };
  const resendSmsInvitation = async (id) => {
    try {
      setInvitationSmsLoaders({ ...invitationSmsLoaders, [id]: true });
      const res = await axios.post(
        `${domainUrl}invitations?id=${id}&type=sms`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data);
        setTableRefetch(!tableRefetch);
        setInvitationSmsLoaders({ ...invitationSmsLoaders, [id]: false });
        toast.success("An invitation has been send via Sms", { id: 1 });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
      setInvitationSmsLoaders({ ...invitationSmsLoaders, [id]: false });
      toast.error("Something went wrong", { id: 1 });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const view_attorney_id = localStorage.getItem("view_attorney_id");
      const view_firm_id = localStorage.getItem("view_firm_id");
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL(
        // 'event-reports','http://191.96.1.252:8000/',
        "clients",
        `${domainUrl}`
      );
      url.searchParams.set("status", `pending-invitation`);
      if (view_attorney_id) {
        url.searchParams.set("attorney", `${view_attorney_id}`);
      } else {
        if (view_firm_id) {
          console.log("VIEW_FIRM DETAILS>>>>>>>>");
          url.searchParams.set("firm", `${view_firm_id}`);
        }
      }
      url.searchParams.set(
        "offset",
        pagination.pageIndex * pagination.pageSize
      );
      url.searchParams.set("limit", `${pagination.pageSize}`);
      url.searchParams.set("offset", `${pagination.pageIndex}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("search", search ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      url.searchParams.set("table", `true`);
      try {
        const response = await fetch(url.href, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        });
        let json = await response.json();
        let data = json;
        console.log("###", data);
        if (data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setData(data.data);
        setRowCount(data.count);

        // setRowCount(Math.ceil(data.count / pagination.pageSize));
        // setRowCount(5);
        // Math.ceil(totalCount / pagination.pageSize)
        // setTotalCount(data.count);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    search,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    tableRefetch,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "clients",
        header: "",
        columns: [
          {
            id: "created_time",
            header: "Created Date",
            accessorFn: (row) => `${formatDate(row.created_time)}`,
            muiTableHeadCellFilterTextFieldProps: { placeholder: "YYYY-MM-DD" },
            accessorKey: "created_time",
            enableColumnActions: false,
            size: 30,
          },
          {
            id: "approved_time",
            header: "Approved Date",
            enableColumnActions: false,
            accessorFn: (row) => `${formatDate(row.approved_time)}`,
            muiTableHeadCellFilterTextFieldProps: { placeholder: "YYYY-MM-DD" },
            accessorKey: "approved_time",
            size: 30,
          },
          {
            accessorFn: (row) => `${row.client_code} `,
            id: "client_code",
            enableColumnActions: false,
            header: "Client Code",
            accessorKey: "client_code",
            size: 30,
          },
          {
            accessorFn: (row) =>
              `${row.user?.first_name} ${row.user?.last_name} `,
            id: "user_model__first_name",
            enableColumnActions: false,
            header: "Client Name",
            accessorKey: "first_name",
            size: 30,
            Cell: ({ row }) => (
              <span
                onClick={() => {
                  // Save data to localStorage
                  localStorage.setItem(
                    "view_client_id",
                    row?.original?.user?.id
                  );
                  // Navigate to the new page
                  navigate(`/view-pending-client`);
                }}
                style={{
                  cursor: "pointer",
                  color: "blue",
                  textDecoration: "underline",
                }}
              >
                {`${row?.original?.user?.first_name} ${row?.original?.user?.last_name}`}
              </span>
            ),
          },
          role == "admin"
            ? {
                id: "contracts__attorney__firm__firm_name",
                header: "Firm",
                enableColumnActions: false,
                accessorFn: (row) =>
                  `${row?.contracts?.[0].attorney.firm.firm_name}`,
                accessorKey: "firm_name",
                size: 30,
                Cell: ({ row }) => (
                  <span
                    onClick={() => {
                      localStorage.setItem(
                        "firm_id",
                        row?.original?.contracts?.[0].attorney.firm.id
                      );
                      localStorage.setItem(
                        "view_firm_id",
                        row?.original?.contracts?.[0].attorney.firm.id
                      );
                      navigate(`/view-firm`);
                    }}
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      textDecoration: "underline",
                    }}
                  >
                    {`${row?.original?.contracts?.[0].attorney.firm.firm_name}`}
                  </span>
                ),
              }
            : null,
          role == "admin" || role == "primaryattorney"
            ? {
                id: "contracts__attorney__user_model__first_name",
                header: "Attorney",
                accessorFn: (row) =>
                  `${row?.contracts?.[0].attorney?.user.first_name} ${row?.contracts?.[0]?.attorney.user.last_name}`,
                enableColumnActions: false,
                accessorKey: "first_name",
                size: 30,
                Cell: ({ row }) => (
                  <span
                    onClick={() => {
                      debugger
                      localStorage.setItem(
                        "view_attorney_id",
                        row?.original?.contracts?.[0].attorney?.attorney_id
                      );
                      navigate(`/attorney-profile`);
                    }}
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      textDecoration: "underline",
                    }}
                  >
                    {`${row?.original?.contracts?.[0].attorney?.user.first_name} ${row?.original?.contracts?.[0]?.attorney.user.last_name}`}
                  </span>
                ),
              }
            : null,
          role == "admin"
            ? {
                id: "user_model__received_invitations__send_sms_count",
                header: "Sms Count",
                enableColumnActions: false,
                accessorFn: (row) =>
                  `${
                    row.invitation?.send_sms_count
                      ? `${row.invitation.send_sms_count} times`
                      : ""
                  } `,
                accessorKey: "send_sms_count",
                enableSorting: false,
                enableColumnFilter: false,
                size: 30,
              }
            : null,
          role == "admin"
            ? {
                id: "user_model__received_invitations__send_count",
                header: "Email Count",
                enableColumnActions: false,
                accessorFn: (row) =>
                  `${
                    row.invitation?.send_count
                      ? `${row.invitation.send_count} times`
                      : ""
                  } `,
                accessorKey: "send_count",
                enableSorting: false,
                enableColumnFilter: false,

                size: 30,
              }
            : null,
        ].filter(Boolean),
      },
    ],

    []
  );
  const formatDate = (dateString) => {
    //   const originalDate = new Date(dateString);
    //   console.log(dateString)
    //   console.log(originalDate)
    //   const day = originalDate.getDate().toString().padStart(2, "0");
    //   const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    //   const year = originalDate.getFullYear();

    //   return `${month}/${day}/${year}`;
    console.log(dateString);
    if (dateString) {
      const date = dateString.split("-");
      console.log(date);
      return `${date[1]}/${date[2]}/${date[0]}`;
    }
    return dateString;
  };
  const getStateByCode = (code) => {
    const state = listedStates.find((state) => state.code === code);
    return state ? state : null;
  };
  function formatUSD(number) {
    // Check if the input is a valid number
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    // Format the number as USD currency
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }
  function formatUSCell(usPhoneNumber) {
    // Remove any non-digit characters
    let digitsOnly = usPhoneNumber.replace(/\D/g, "");

    // Check if the number has the expected length of 10 digits
    if (digitsOnly.length !== 10) {
      return "Invalid phone number format";
    }

    // Format the number in the original format and add +1 in front
    return "+1 " + digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }

  const [rowSelection, setRowSelection] = useState({});
  const [bulkEmailStatus, setBulkEmailStatus] = useState(false);
  const [bulkSmsStatus, setBulkSmsStatus] = useState(false);
  const [bulkEmailInvitationLoaders, setBulkEmailInvitationLoaders] =
    useState(false);
  const [bulkSmsInvitationLoaders, setBulkSmsInvitationLoaders] =
    useState(false);

  useEffect(() => {
    const length = Object.keys(rowSelection).length;
    console.log("JSON object length: " + length, rowSelection, data);
    if (length >= 1) {
      setBulkEmailStatus(true);
      setBulkSmsStatus(true);
    } else {
      setBulkEmailStatus(false);
      setBulkSmsStatus(false);
    }
  }, [rowSelection]);

  const sendBulk = async (type) => {
    const choosenRows = Object.keys(rowSelection).filter(
      (value) => rowSelection[value]
    );

    console.log("Choosen Rows", choosenRows);
    const idsToSend = choosenRows.map((index) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id == index) {
          return data[i].invitation.id;
        }
      }
    });
    console.log("Selected ids", idsToSend);
    let url = "";
    if (idsToSend.length > 1) {
      url = `${domainUrl}invitations?ids=${idsToSend}&type=${type}`;
    } else {
      url = `${domainUrl}invitations?id=${idsToSend}&type=${type}`;
    }
    if (type == "sms") {
      try {
        setBulkSmsInvitationLoaders(true);
        const res = await axios.post(
          url,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (res.data) {
          console.log(res.data);
          setTableRefetch(!tableRefetch);
          setBulkSmsInvitationLoaders(false);
          toast.success("Invited via SMS to the selected", { id: 1 });
          setRowSelection({});
        }
      } catch (error) {
        console.log(error);
        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setBulkSmsInvitationLoaders(false);
      }
    } else {
      try {
        setBulkEmailInvitationLoaders(true);
        const res = await axios.post(
          url,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (res.data) {
          console.log(res.data);
          setTableRefetch(!tableRefetch);
          setBulkEmailInvitationLoaders(false);
          toast.success("Invited via Email to the selected", { id: 1 });
          setRowSelection({});
        }
      } catch (error) {
        console.log(error);
        if (error.response?.data.code == "token_not_valid") {
          logOutHandler();
          navigate("/login");
          toast.error("Session Expired", { id: 1 });
          return;
        }
        setBulkEmailInvitationLoaders(false);
      }
    }
  };
  const exportFileHandler = async (id) => {
    const url = new URL(
      // 'event-reports','http://191.96.1.252:8000/',
      "clients",
      `${domainUrl}`
    );
    url.searchParams.set("status", "pending-invitation");
    url.searchParams.set("offset", pagination.pageIndex * pagination.pageSize);
    url.searchParams.set("limit", `${pagination.pageSize}`);
    url.searchParams.set("offset", `${pagination.pageIndex}`);
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("search", search ?? "");
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    url.searchParams.set("export", true);

    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        console.log(response);
        console.log(response.headers.get("content-type"));
        console.log(typeof response.headers.get("content-type"));
        if (response.headers.get("content-type") == "application/json") {
          // toast
          console.log("inside json if");
          console.log(response.data.scheduled);
          response.data.text().then((text) => {
            toast.success(JSON.parse(text).scheduled, { autoClose: 10000 });
          });
        } else {
          let filename = `Pending Invitations_${Date.now()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  return (
    <>
      <div>
        <main className="py-10">
          <main className="w-full py-10 ">
            <div className="mx-5">
              <h2
                style={{
                  color: "#1a3253",
                }}
              >
                Pending Invitations
              </h2>
              <div className="text-base text-[#9ca0b3] my-2">
                Clients approved but not accepted invitation
              </div>

              <div className="p-1 border rounded-lg">
                <MaterialReactTable
                  enableTopToolbar={true}
                  enableStickyHeader
                  // ############################################################
                  enableRowSelection={role == "admin" ? true : false}
                  renderTopToolbarCustomActions={() => {
                    if (role == "admin") {
                      return (
                        <div className="sm:flex justify-between gap-3">
                          <div className="flex flex-col items-start sm:flex-row sm:items-center gap-2">
                            <div>
                              {bulkSmsInvitationLoaders ? (
                                <button
                                  className={`rounded text-white p-2 text-sm bg-blue-800`}
                                >
                                  <div className="w-full flex  items-center gap-2">
                                    <div className="inline-flex items-center gap-2">
                                      Invite via SMS
                                      <ThreeDots
                                        height={20}
                                        width={20}
                                        radius="9"
                                        color="#ffff"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                      />
                                    </div>
                                  </div>
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    sendBulk("sms");
                                  }}
                                  disabled={!bulkSmsStatus}
                                  className={`rounded text-white p-2 text-sm ${
                                    bulkSmsStatus
                                      ? "bg-blue-600 hover:bg-blue-800"
                                      : "bg-blue-300"
                                  }`}
                                >
                                  <div className="w-full flex  items-center gap-2">
                                    <div className="inline-flex items-center gap-2">
                                      Invite via SMS <SmsIcon />
                                    </div>
                                  </div>
                                </button>
                              )}
                            </div>
                            <div>
                              {bulkEmailInvitationLoaders ? (
                                <button
                                  className={`rounded text-white p-2 text-sm bg-blue-800`}
                                >
                                  <div className="w-full flex  items-center gap-2">
                                    <div className="inline-flex items-center gap-2">
                                      Invite via Email
                                      <ThreeDots
                                        height={20}
                                        width={20}
                                        radius="9"
                                        color="#ffff"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                      />
                                    </div>
                                  </div>
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    sendBulk("email");
                                  }}
                                  disabled={!bulkEmailStatus}
                                  className={`rounded text-white p-2 text-sm ${
                                    bulkEmailStatus
                                      ? "bg-blue-600 hover:bg-blue-800"
                                      : "bg-blue-300"
                                  }`}
                                >
                                  <div className="w-full flex  items-center gap-2">
                                    <div className="inline-flex items-center gap-2">
                                      Invite via Email <ForwardToInboxIcon />
                                    </div>
                                  </div>
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="mt-3 flex flex-col items-start gap-3 sm:flex-row sm:items-center sm:mt-0">
                            <Button
                              disabled={false}
                              startIcon={<Avatar src={ExportImg} />}
                              onClick={() => {
                                // Implement export logic for the selected rows (10 or 30) here
                                console.log("Exporting as Excel");
                                exportFileHandler();
                              }}
                              variant="contained"
                              sx={{
                                bgcolor: "#127c44",
                                "&:hover": {
                                  bgcolor: "#127c44",
                                },
                                height: "25px",
                              }}
                            >
                              Export
                            </Button>
                          </div>
                        </div>
                      );
                    }
                    return;
                  }}
                  // getRowId={(row) => row.userId} //give each row a more useful id
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  // state={{ rowSelection }} //pass our managed row selection state to the table to use
                  // ######################################################################
                  muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                  // enableColumnResizing
                  // columnResizeMode="onEnd"
                  muiTableBodyProps={{
                    sx: () => ({
                      "& tr:nth-child(odd)": {
                        backgroundColor: "#f8f9fa",
                      },
                      "& tr:nth-child(even)": {
                        backgroundColor: "#ffffff", // Set the background color for even rows
                      },
                    }),
                  }}
                  muiTablePaperProps={{
                    sx: {
                      maxWidth: "full",
                      // m: 'auto'
                    },
                    elevation: 0,
                  }}
                  muiTableHeadCellProps={{
                    sx: (theme) => ({
                      // background: "rgba(52, 210, 235, 0.1)",
                      // borderRight: "1px solid rgba(224,224,224,1)",
                      color: theme.palette.text.primary,
                    }),
                  }}
                  enableFullScreenToggle={false}
                  enableDensityToggle={false}
                  enableHiding={false}
                  enableColumnFilters={true}
                  rowNumberMode="static"
                  renderDetailPanel={({ row }) => {
                    return (
                      <div className="bg-white">
                        <div aria-hidden="true" className="relative">
                          <img
                            // src="https://tailwindui.com/img/ecommerce-images/product-feature-02-full-width.jpg"
                            // src="https://w7.pngwing.com/pngs/247/564/png-transparent-computer-icons-user-profile-user-avatar-blue-heroes-electric-blue.png"
                            // src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png"
                            src={UserProf}
                            alt=""
                            className="h-40 w-full object-contain object-center"
                          />
                          <div className="absolute inset-0 bg-gradient-to-t from-white" />
                        </div>

                        <div className="relative mx-auto -mt-12 max-w-7xl px-4 pb-16 sm:px-6 sm:pb-24 lg:px-8">
                          <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                              <>
                                {" "}
                                {row.original?.user &&
                                  row.original.user.first_name}
                                {row.original?.user &&
                                  row.original.user.last_name}
                              </>
                            </h2>
                            <p className="mt-4 text-gray-500">
                              {row.original && row.original.client_code}
                            </p>
                          </div>

                          <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:max-w-none lg:grid-cols-3 lg:gap-x-8">
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                First Name
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original?.user &&
                                  row.original.user.first_name}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                Last Name
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original?.user &&
                                  row.original.user.last_name}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                Email
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original?.user && row.original.user.email}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                Primary Phone
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original?.user &&
                                  formatUSCell(
                                    row.original.user.cell_phone_number
                                  )}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                Address Line 1
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original && row.original.address_line1}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                Address Line 2
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original && row.original.address_line2}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                City
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original && row.original.city}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                State
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original &&
                                  getStateByCode(row.original.state).name}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                Zip Code
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original && row.original.zip_code}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                Created Date
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original &&
                                  formatDate(row.original.created_time)}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                Approved Date
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original &&
                                  formatDate(row.original.approved_time)}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                Case Number
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original &&
                                  row.original?.contracts?.[0].case_number}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                Case Type
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original &&
                                  row.original?.contracts?.[0].case_type}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                Contract Amount
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original &&
                                  formatUSD(
                                    row.original?.contracts?.[0].contract_amount
                                  )}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                Down Payment
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original &&
                                  formatUSD(
                                    row.original?.contracts?.[0].down_payment ==
                                      null
                                      ? 0
                                      : row.original?.contracts?.[0]
                                          .down_payment
                                  )}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                Monthly Income
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original &&
                                  formatUSD(
                                    row.original?.contracts?.[0]
                                      .client_monthly_income
                                  )}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                Payment Plan
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original &&
                                  paymentPlanString[
                                    row.original?.contracts?.[0].payment_plan
                                  ]}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                Tenure
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original &&
                                  row.original?.contracts?.[0].tenure}{" "}
                                months
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 pt-2">
                              <dt className="font-medium text-gray-900">
                                Payment Start Date
                              </dt>
                              <dd className="mt-2 text-sm text-gray-500">
                                {row.original &&
                                  formatDate(
                                    row.original?.contracts?.[0].start_date
                                  )}
                              </dd>
                            </div>
                            {role == "admin" ? (
                              <>
                                <div className="border-t border-gray-200 pt-2">
                                  <dt className="font-medium text-gray-900">
                                    Firm Name
                                  </dt>
                                  <dd className="mt-2 text-sm text-gray-500">
                                    {row.original &&
                                      row.original?.contracts?.[0].attorney.firm
                                        .firm_name}
                                  </dd>
                                </div>
                                <div className="border-t border-gray-200 pt-2">
                                  <dt className="font-medium text-gray-900">
                                    Firm Code
                                  </dt>
                                  <dd className="mt-2 text-sm text-gray-500">
                                    {row.original &&
                                      row.original?.contracts?.[0].attorney.firm
                                        .firm_code}
                                  </dd>
                                </div>
                                <div className="border-t border-gray-200 pt-2">
                                  <dt className="font-medium text-gray-900">
                                    Firm Email
                                  </dt>
                                  <dd className="mt-2 text-sm text-gray-500">
                                    {row.original &&
                                      row.original?.contracts?.[0].attorney.firm
                                        .email}
                                  </dd>
                                </div>
                                <div className="border-t border-gray-200 pt-2">
                                  <dt className="font-medium text-gray-900">
                                    Firm Phone Number
                                  </dt>
                                  <dd className="mt-2 text-sm text-gray-500">
                                    {row.original &&
                                      formatUSCell(
                                        row.original?.contracts?.[0].attorney
                                          .firm.cell_phone_number
                                      )}
                                  </dd>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            {role == "admin" || role == "primaryattorney" ? (
                              <>
                                <div className="border-t border-gray-200 pt-2">
                                  <dt className="font-medium text-gray-900">
                                    Attorney
                                  </dt>
                                  <dd className="mt-2 text-sm text-gray-500">
                                    {row.original &&
                                      `${row.original?.contracts?.[0].attorney.user?.first_name} ${row.original.contracts[0].attorney.user?.last_name}`}
                                  </dd>
                                </div>
                                <div className="border-t border-gray-200 pt-2">
                                  <dt className="font-medium text-gray-900">
                                    Attorney Code
                                  </dt>
                                  <dd className="mt-2 text-sm text-gray-500">
                                    {row.original &&
                                      row.original?.contracts?.[0].attorney
                                        .attorney_code}
                                  </dd>
                                </div>
                                <div className="border-t border-gray-200 pt-2">
                                  <dt className="font-medium text-gray-900">
                                    Attorney Email
                                  </dt>
                                  <dd className="mt-2 text-sm text-gray-500">
                                    {row.original &&
                                      row.original?.contracts?.[0].attorney.user
                                        .email}
                                  </dd>
                                </div>
                                <div className="border-t border-gray-200 pt-2">
                                  <dt className="font-medium text-gray-900">
                                    Attorney Phone Number
                                  </dt>
                                  <dd className="mt-2 text-sm text-gray-500">
                                    {row.original &&
                                      formatUSCell(
                                        row.original?.contracts?.[0].attorney
                                          .user.cell_phone_number
                                      )}
                                  </dd>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </dl>
                          <div className="px-4 sm:px-6 lg:px-8">
                            <div className="my-7 sm:flex sm:items-center">
                              <div className="sm:flex-auto">
                                <h1 className="text-base font-semibold leading-6 text-gray-900">
                                  Invitations Log
                                </h1>
                              </div>
                            </div>
                            <div className="mt-8 flow-root">
                              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                      <thead className="bg-gray-50">
                                        <tr>
                                          <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                          >
                                            No
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                          >
                                            Invitation Type
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                          >
                                            Date
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                          >
                                            Time
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="divide-y divide-gray-200 bg-white">
                                        {row.original.invitation_logs.map(
                                          (item, index) => (
                                            <tr className="bg-white">
                                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {index + 1}
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm font-bold text-gray-700">
                                                {item.type.toUpperCase()}
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm font-bold text-gray-700">
                                                {extractDateFromTimestamp(
                                                  item.created_time
                                                )}
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm font-bold text-gray-700">
                                                {extractTimeFromTimestamp(
                                                  item.created_time
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10, 15, 20, 25],
                    showFirstButton: false,
                    showLastButton: false,
                  }}
                  columns={columns}
                  data={data}
                  enableRowActions
                  positionActionsColumn="last"
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      header: role == "admin" ? "Resend" : null, //change header text
                      // size: 300, //make actions column wider
                    },
                  }}
                  renderRowActions={({ row }) => {
                    return (
                      <>
                        {role == "admin" && (
                          <div className="flex items-center">
                            <div>
                              {invitationSmsLoaders[
                                row.original.invitation?.id
                              ] ? (
                                <IconButton>
                                  <Hourglass
                                    visible={true}
                                    height="25"
                                    width="25"
                                    ariaLabel="hourglass-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    colors={["#306cce", "#72a1ed"]}
                                  />
                                </IconButton>
                              ) : (
                                <IconButton
                                  onClick={() => {
                                    resendSmsInvitation(
                                      row.original.invitation.id
                                    );
                                  }}
                                >
                                  <SmsIcon
                                    sx={{
                                      color: "black",
                                    }}
                                  />
                                </IconButton>
                              )}
                            </div>
                            <div>
                              {invitationEmailLoaders[
                                row.original.invitation.id
                              ] ? (
                                <IconButton>
                                  <Hourglass
                                    visible={true}
                                    height="25"
                                    width="25"
                                    ariaLabel="hourglass-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    colors={["#306cce", "#72a1ed"]}
                                  />
                                </IconButton>
                              ) : (
                                <IconButton
                                  onClick={() => {
                                    resendEmailInvitation(
                                      row.original.invitation.id
                                    );
                                  }}
                                >
                                  <ForwardToInboxIcon
                                    sx={{
                                      color: "black",
                                    }}
                                  />
                                </IconButton>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    );
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    //implement row selection click events manually
                    onClick: () => {
                      if (data.length) {
                        console.log(row.original.id);
                        // console.log(data[row.id - 1].id);
                        console.log("Clicked on ", row.id);
                        //alert();
                        localStorage.setItem("view_client_id", row.id);
                        // navigate(`/view-firm`);
                      }
                    },
                    // selected: rowSelection[row.id],
                    sx: {
                      cursor: "pointer",
                    },
                  })}
                  getRowId={(row) => row.id}
                  initialState={{
                    showColumnFilters: false,
                    density: "compact",
                  }}
                  // manualFiltering
                  manualPagination
                  manualSorting
                  muiToolbaralertBannerProps={
                    isError
                      ? {
                          color: "error",
                          children: "Error loading data",
                        }
                      : undefined
                  }
                  onColumnFiltersChange={setColumnFilters}
                  onGlobalFilterChange={setSearch}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  rowCount={rowCount}
                  // rowCount={Math.ceil(totalCount / pagination.pageSize)}
                  state={{
                    // columnFilters,
                    // search,
                    rowSelection,
                    isLoading,
                    pagination,
                    showalertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                  }}
                />
              </div>
            </div>
          </main>
        </main>
      </div>
    </>
  );
}
