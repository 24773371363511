import { io } from "socket.io-client";
function clearAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const equalsSignIndex = cookie.indexOf("=");

    // Extract the cookie name
    const cookieName =
      equalsSignIndex > -1 ? cookie.substring(0, equalsSignIndex) : cookie;

    // Set the cookie to expire in the past to delete it
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
  window.location.reload();
}

export const logOutHandler = () => {
  sessionStorage.clear();
  localStorage.clear();
  clearAllCookies();
};

let access_token = localStorage.getItem("access_token");

// export const domainUrl = `https://api-natlaccess.willgibbins.com/api/v1/`;
// export const domainUrl = `https://api-natl.willgibbins.com/api/v1/`;
// export const domainUrl = `http://192.168.163.32:8000/api/v1/`;

// export const domainUrl = `https://api-temp.willgibbins.com/api/v1/`;
export const domainUrl = process.env.REACT_APP_API_URL;
// export const domainUrl = `https://api-dev.willgibbins.com/api/v1/`;
// export const domainUrl = `https://api.nationalaccess.us/api/v1/`;

// export const domainUrl = process.env.NA_API_URL;
// export const domainUrl = `http://192.168.1.10:8000/api/v1/`;

// export const socket = io(`wss://api-dev.willgibbins.com/`, {
//   path: `/socket/notification/${access_token}/`,
//   transports: ["websocket"],
// });

export const listedStates = [
  { id: 1, name: "Alabama", code: "AL" },
  { id: 2, name: "Alaska", code: "AK" },
  { id: 3, name: "Arizona", code: "AZ" },
  { id: 4, name: "Arkansas", code: "AR" },
  { id: 5, name: "California", code: "CA" },
  { id: 6, name: "Colorado", code: "CO" },
  { id: 7, name: "Connecticut", code: "CT" },
  { id: 8, name: "Delaware", code: "DE" },
  { id: 9, name: "Florida", code: "FL" },
  { id: 10, name: "Georgia", code: "GA" },
  { id: 11, name: "Hawaii", code: "HI" },
  { id: 12, name: "Idaho", code: "ID" },
  { id: 13, name: "Illinois", code: "IL" },
  { id: 14, name: "Indiana", code: "IN" },
  { id: 15, name: "Iowa", code: "IA" },
  { id: 16, name: "Kansas", code: "KS" },
  { id: 17, name: "Kentucky", code: "KY" },
  { id: 18, name: "Louisiana", code: "LA" },
  { id: 19, name: "Maine", code: "ME" },
  { id: 20, name: "Maryland", code: "MD" },
  { id: 21, name: "Massachusetts", code: "MA" },
  { id: 22, name: "Michigan", code: "MI" },
  { id: 23, name: "Minnesota", code: "MN" },
  { id: 24, name: "Mississippi", code: "MS" },
  { id: 25, name: "Missouri", code: "MO" },
  { id: 26, name: "Montana", code: "MT" },
  { id: 27, name: "Nebraska", code: "NE" },
  { id: 28, name: "Nevada", code: "NV" },
  { id: 29, name: "New Hampshire", code: "NH" },
  { id: 30, name: "New Jersey", code: "NJ" },
  { id: 31, name: "New Mexico", code: "NM" },
  { id: 32, name: "New York", code: "NY" },
  { id: 33, name: "North Carolina", code: "NC" },
  { id: 34, name: "North Dakota", code: "ND" },
  { id: 35, name: "Ohio", code: "OH" },
  { id: 36, name: "Oklahoma", code: "OK" },
  { id: 37, name: "Oregon", code: "OR" },
  { id: 38, name: "Pennsylvania", code: "PA" },
  { id: 39, name: "Rhode Island", code: "RI" },
  { id: 40, name: "South Carolina", code: "SC" },
  { id: 41, name: "South Dakota", code: "SD" },
  { id: 42, name: "Tennessee", code: "TN" },
  { id: 43, name: "Texas", code: "TX" },
  { id: 44, name: "Utah", code: "UT" },
  { id: 45, name: "Vermont", code: "VT" },
  { id: 46, name: "Virginia", code: "VA" },
  { id: 47, name: "Washington", code: "WA" },
  { id: 48, name: "West Virginia", code: "WV" },
  { id: 49, name: "Wisconsin", code: "WI" },
  { id: 50, name: "Wyoming", code: "WY" },
  { id: 51, name: "District of Columbia", code: "DC" },
  { id: 52, name: "American Samoa", code: "AS" },
  { id: 53, name: "Guam", code: "GU" },
  { id: 54, name: "Northern Mariana Islands", code: "MP" },
  { id: 55, name: "Puerto Rico", code: "PR" },
  { id: 56, name: "U.S. Virgin Islands", code: "VI" },
  { id: 57, name: "U.S. Minor Outlying Islands", code: "UM" },
];
