import { Navigate } from "react-router-dom";

const PublicAuth = ({ children }) => {
  const access_token = localStorage.getItem("access_token");
  if (access_token?.length > 10) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
};

export default PublicAuth;
