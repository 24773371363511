import ApprovedClientsTable from "../Tables/ApprovedClientsTable";
import PendingClientsTable from "../Tables/PendingClientsTable";
import PendingInvitationsTable from "../Tables/PendingInvitationsTable";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { domainUrl, logOutHandler } from "../../utils/constants";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  Cell,
} from "recharts";
import DelinquentPaymentsBar from "../../Components/Graphs/DelinquentPaymentsBar";
import ExpectedPaymentsBar from "../../Components/Graphs/ExpectedPaymentsBar";
import FundCirculationPie from "../../Components/Graphs/FundCirculationPie";
import ContractPerformanceBar from "../../Components/Graphs/ContractPerformanceBar";
import CustomerManagementBar from "../../Components/Graphs/CustomerManagementBar";
import LocAndContractLine from "../../Components/Graphs/LocAndContractLine";

export default function AdminDashboardDev() {
  return (
    <>
      <div>
        <main className="py-10">
          <div className="w-full">
            <div className="w-full mb-8 flex flex-col justify-center xl:flex-row  items-center gap-5">
              <DelinquentPaymentsBar />
              <ExpectedPaymentsBar />
            </div>
            <div className="w-full mb-8 flex flex-col justify-center xl:flex-row  items-center gap-5">
              <FundCirculationPie />
              <LocAndContractLine />
            </div>
            <div className="w-full mb-8 flex flex-col justify-center xl:flex-row  items-stretch gap-5">
              <ContractPerformanceBar />
              <CustomerManagementBar />
            </div>

            <div className="mt-5 container m-auto grid grid-cols-1 place-items-center gap-x-2 gap-y-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
              <div className="w-full  shadow-md flex flex-col justify-between items-center border border-gray-200 rounded-xl  px-4 py-2 leading-normal sm:w-72 md:w-80 lg:w-80 h-60 xl:w-96">
                <div className="w-full h-48 border flex justify-center items-center">
                  <div className="text-xl font-bold">
                    Placeholder for Widget
                  </div>
                </div>
              </div>
              <div className="w-full  shadow-md flex flex-col justify-between items-center border border-gray-200 rounded-xl  px-4 py-2 leading-normal sm:w-72 md:w-80 lg:w-80 h-60 xl:w-96">
                <div className="w-full h-48 border flex justify-center items-center">
                  <div className="text-xl font-bold">
                    Placeholder for Widget
                  </div>
                </div>
              </div>
              <div className="w-full  shadow-md flex flex-col justify-between items-center border border-gray-200 rounded-xl  px-4 py-2 leading-normal sm:w-72 md:w-80 lg:w-80 h-60 xl:w-96">
                <div className="w-full h-48 border flex justify-center items-center">
                  <div className="text-xl font-bold">
                    Placeholder for Widget
                  </div>
                </div>
              </div>
              <div className="w-full  shadow-md flex flex-col justify-between items-center border border-gray-200 rounded-xl  px-4 py-2 leading-normal sm:w-72 md:w-80 lg:w-80 h-60 xl:w-96">
                <div className="w-full h-48 border flex justify-center items-center">
                  <div className="text-xl font-bold">
                    Placeholder for Widget
                  </div>
                </div>
              </div>
              <div className="w-full  shadow-md flex flex-col justify-between items-center border border-gray-200 rounded-xl  px-4 py-2 leading-normal sm:w-72 md:w-80 lg:w-80 h-60 xl:w-96">
                <div className="w-full h-48 border flex justify-center items-center">
                  <div className="text-xl font-bold">
                    Placeholder for Widget
                  </div>
                </div>
              </div>
              <div className="w-full  shadow-md flex flex-col justify-between items-center border border-gray-200 rounded-xl  px-4 py-2 leading-normal sm:w-72 md:w-80 lg:w-80 h-60 xl:w-96">
                <div className="w-full h-48 border flex justify-center items-center">
                  <div className="text-xl font-bold">
                    Placeholder for Widget
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PendingInvitationsTable />
          <PendingClientsTable />
          <ApprovedClientsTable />
        </main>
      </div>
    </>
  );
}
