import { useNavigate } from "react-router-dom";
import axios from "axios";
import { domainUrl, logOutHandler } from "../../utils/constants";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  Cell,
} from "recharts";
import { DateRangePicker } from "rsuite";
import subDays from "date-fns/subDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import subMonths from "date-fns/subMonths";
import subYears from "date-fns/subYears";

export default function AttorneyExpectedBar() {
  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");
  const [expectedData, setExpectedData] = useState({
    total_contract_amount: 0,
    expected_payments: 0,
  });
  const [data, setData] = useState([]);

  const [dateFilter, setDateFilter] = useState({
    start_date: "",
    end_date: "",
  });

  const predefinedRanges = [
    {
      label: "Past week",
      closeOverlay: false,
      value: [
        subDays(new Date(), 6), // Start date: 6 days ago
        new Date(), // End date: Today
      ],
      placement: "left",
    },
    {
      label: "Past month",
      closeOverlay: false,
      value: [
        startOfMonth(subMonths(new Date(), 1)), // Start date: First day of the month, 1 month ago
        endOfMonth(subMonths(new Date(), 1)), // End date: Last day of the month, 1 month ago
      ],
      placement: "left",
    },
    {
      label: "Past 3 months",
      closeOverlay: false,
      value: [
        startOfMonth(subMonths(new Date(), 3)), // Start date: First day of the month, 3 months ago
        new Date(), // End date: Today
      ],
      placement: "left",
    },
    {
      label: "Past 6 months",
      closeOverlay: false,
      value: [
        startOfMonth(subMonths(new Date(), 6)), // Start date: First day of the month, 6 months ago
        new Date(), // End date: Today
      ],
      placement: "left",
    },
    {
      label: "Past year",
      closeOverlay: false,
      value: [
        startOfMonth(subYears(new Date(), 1)), // Start date: First day of the month, 1 year ago
        new Date(), // End date: Today
      ],
      placement: "left",
    },
    {
      label: "Past 2 years",
      closeOverlay: false,
      value: [
        startOfMonth(subYears(new Date(), 2)), // Start date: First day of the month, 2 years ago
        new Date(), // End date: Today
      ],
      placement: "left",
    },
  ];

  const handleDateFilter = (e) => {
    if (e == null) {
      setDateFilter({
        start_date: "",
        end_date: "",
      });
    }
    if (e && e.length === 2) {
      const startDate = new Date(e[0]);
      const endDate = new Date(e[1]);

      var timezoneOffsetInMinutes = new Date().getTimezoneOffset();
      startDate.setHours(
        startDate.getHours() + startDate.getTimezoneOffset() / 60
      );
      endDate.setHours(endDate.getHours() + endDate.getTimezoneOffset() / 60);

      // Format the dates as YYYY-MM-DD
      const formattedStartDate = startDate.toISOString().split("T")[0];
      const formattedEndDate = endDate.toISOString().split("T")[0];

      // Update the dateFilter state
      setDateFilter({
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      });
    }
  };

  const getExpectedGraphData = async () => {
    try {
      const res = await axios.get(
        `${domainUrl}attorney-expected-payments-graph?start_date=${dateFilter.start_date}&end_date=${dateFilter.end_date}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res.data) {
        console.log(res.data, expectedData);
        setExpectedData(res.data);
        let temp = [
          {
            name: "Total Contract",
            value: res.data.total_contract_amount,
          },
          {
            name: "Expected Payments",
            value: res.data.expected_payments,
          },
          {
            name: "Payment Ontime",
            value: res.data.payment_ontime,
          },
          {
            name: "Delinquent",
            value: res.data.delinquent,
          },
          {
            name: "Overdue",
            value: res.data.over_due,
          },
          {
            name: "Total Received",
            value: res.data.total_received,
          },
        ];
        setData(temp);
      }
    } catch (error) {
      console.log(error);
      if (error.response?.data.code == "token_not_valid") {
        logOutHandler();
        navigate("/login");
        toast.error("Session Expired", { id: 1 });
        return;
      }
    }
  };

  function formatUSD(number) {
    // Check if the input is a valid number
    if (typeof number !== "number" || isNaN(number)) {
      if (typeof number == "string") {
        number = parseFloat(number);
      } else {
        return "Invalid number";
      }
    }

    // Format the number as USD currency
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(number);
  }

  useEffect(() => {
    getExpectedGraphData();
  }, [dateFilter]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-2 bg-white border border-black shadow-md flex flex-col gap-4 rounded-md">
          <p className="text-medium text-sm">{payload[0].payload.name}</p>
          {payload[0].payload.name == "Total Contract" ||
          payload[0].payload.name == "Expected Payments" ||
          payload[0].payload.name == "Total Received" ? (
            <p className="text-lg font-semibold text-blue-600">
              Amount:
              <span className="ml-2">
                {formatUSD(payload[0].payload.value)}
              </span>
            </p>
          ) : (
            <p className="text-lg font-semibold text-blue-600">
              <span className="ml-2">{payload[0].payload.value}</span>
            </p>
          )}
        </div>
      );
    }
  };

  // const colors = ["#3B82F6", "#4ab516"];
  const colors = [
    "#3B82F6",
    "#ADD8E6",
    "#4ab516",
    "#FFBF00",
    "#FF6865",
    "#3f64be",
  ];

  return (
    <>
      <div className="w-full">
        <div className="w-full h-[350px] p-2 flex flex-col justify-center items-center border border-gray-200 rounded-xl shadow-md">
          <div className="mb-3 text-base font-bold">PAYMENTS OVERVIEW</div>
          <div className="w-full h-60 sm:w-[27rem] lg:w-[27rem]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                className="hover:cursor-pointer"
                width="100%"
                height="100%"
                data={data}
                onClick={(event, entry) => {
                  console.log(event, entry);
                  if (!event?.activePayload) {
                    return;
                  }
                  if (event.activePayload[0].payload.name == "Total Contract") {
                    localStorage.setItem("query_param/total_contract", true);
                    let range = {
                      start_date: dateFilter.start_date,
                      end_date: dateFilter.end_date,
                    };
                    // localStorage.setItem(
                    //   "date_range/total_contract",
                    //   JSON.stringify(range)
                    // );
                    navigate("/attorney-all-payments");
                  } else if (
                    event.activePayload[0].payload.name == "Expected Payments"
                  ) {
                    localStorage.setItem("query_param/total_contract", true);
                    let range = {
                      start_date: dateFilter.start_date,
                      end_date: dateFilter.end_date,
                    };
                    // localStorage.setItem(
                    //   "date_range/total_contract",
                    //   JSON.stringify(range)
                    // );
                    navigate("/attorney-all-payments");
                  } else if (event.activePayload[0].payload.name == "Overdue") {
                    // localStorage.setItem("query_param/overdue", true);
                    let range = {
                      start_date: dateFilter.start_date,
                      end_date: dateFilter.end_date,
                    };
                    // localStorage.setItem(
                    //   "date_range/total_contract",
                    //   JSON.stringify(range)
                    // );
                    navigate("/missed-payment-clients");
                  } else if (
                    event.activePayload[0].payload.name == "Delinquent"
                  ) {
                    // localStorage.setItem("query_param/overdue", true);
                    let range = {
                      start_date: dateFilter.start_date,
                      end_date: dateFilter.end_date,
                    };
                    // localStorage.setItem(
                    //   "date_range/total_contract",
                    //   JSON.stringify(range)
                    // );
                    navigate("/delinquent-clients");
                  } else if (
                    event.activePayload[0].payload.name == "Payment Ontime"
                  ) {
                    localStorage.setItem("query_param/on_time_clients", true);
                    let range = {
                      start_date: dateFilter.start_date,
                      end_date: dateFilter.end_date,
                    };
                    // localStorage.setItem(
                    //   "date_range/total_contract",
                    //   JSON.stringify(range)
                    // );
                    navigate("/on-time-clients");
                  } else if (
                    event.activePayload[0].payload.name == "Total Received"
                  ) {
                    // localStorage.setItem("query_param/overdue", true);
                    let range = {
                      start_date: dateFilter.start_date,
                      end_date: dateFilter.end_date,
                    };
                    // localStorage.setItem(
                    //   "date_range/total_contract",
                    //   JSON.stringify(range)
                    // );
                    navigate("/transaction-history");
                  }
                }}
                margin={{
                  right: 38,
                  left: 38,
                }}
                padding={{
                  top: 20,
                  right: 20,
                  left: 20,
                  bottom: 20,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis label={"Amount"} tick={() => null} tickLine={false} />
                <YAxis
                  tickFormatter={formatUSD}
                  domain={[
                    0,
                    expectedData.total_contract_amount +
                      expectedData.total_contract_amount * 0.1,
                  ]}
                  tickCount={8}
                  tick={{ fontSize: 10 }}
                />
                <Tooltip
                  wrapperStyle={{ zIndex: 2 }}
                  content={<CustomTooltip />}
                />
                {/* <Legend /> */}
                <Bar
                  className="hover:cursor-pointer"
                  dataKey="value"
                  barSize={50}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                  {/* <LabelList
                          dataKey="payment"
                          position={"top"}
                          angle={0}
                          textAnchor={"end"}
                          // formatter={formatUSD}
                          fill="black"
                        /> */}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="my-3 flex flex-col justify-evenly items-center  gap-2">
            <div className="flex  gap-3 justify-center items-start">
              <div className="text-sm inline-flex items-center gap-1">
                <span className="h-2 w-2 bg-[#3B82F6]"></span>Total Contract
                Amount
              </div>
              <div className="text-sm inline-flex items-center gap-1">
                <span className="h-2 w-2 bg-[#ADD8E6]"></span>Expected Payments
              </div>
            </div>
            <div className="flex  gap-3 justify-center items-start">
              <div className="text-sm inline-flex items-center gap-1">
                <span className="h-2 w-2 bg-[#4ab516]"></span>Payment On Time
              </div>
              <div className="text-sm inline-flex items-center gap-1">
                <span className="h-2 w-2 bg-[#FFBF00]"></span>Delinquent Payment
              </div>
            </div>
            <div className="flex  gap-3 justify-center items-start">
              <div className="text-sm inline-flex items-center gap-1">
                <span className="h-2 w-2 bg-[#FF6865]"></span>Overdue
              </div>
              <div className="text-sm inline-flex items-center gap-1">
                <span className="h-2 w-2 bg-[#3f64be]"></span>Received Payment
              </div>
            </div>
          </div>
          {/* <DateRangePicker
            ranges={predefinedRanges}
            onChange={(e) => {
              console.log("Date", e);
              handleDateFilter(e);
            }}
            size="xs"
            showOneCalendar
            placeholder="Select Date"
            format="MM/dd/yyyy"
            character=" – "
            className="hover:cursor-pointer"
            preventOverflow={true}
          /> */}
        </div>
      </div>
    </>
  );
}
