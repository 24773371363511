import React, { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../Components/Breadcrumb";
import {
  Dialog,
  RadioGroup,
  Switch,
  Transition,
  Combobox,
  Listbox,
} from "@headlessui/react";
import ModalComponent from "../Components/ModalComponent";
import {
  CheckIcon,
  ArrowPathIcon,
  DocumentDuplicateIcon,
  EnvelopeIcon,
  InformationCircleIcon,
  LockClosedIcon,
  PhoneArrowDownLeftIcon,
  QrCodeIcon,
  ShieldCheckIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { changePassPut } from "../utils/redux/slice/changePassSlice";
import { setAuthGet } from "../utils/redux/slice/setAuthGetSlice";
import OTPInput from "react-otp-input";
import { setAuthPost } from "../utils/redux/slice/setAuthPostSlice";
import { setEmailSmsAuthPost } from "../utils/redux/slice/setEmailSmsAuthPostSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { editProfilePut } from "../utils/redux/slice/editProfileSlice";
import { MagnifyingGlass, ProgressBar, ThreeDots } from "react-loader-spinner";
import { putTwoFactorPut } from "../utils/redux/slice/putTwoFactorSlice";
import { listedStates } from "../utils/constants";
import CurrencyFormat from "react-currency-format";
import ClientDataVersionTable from "./Tables/ClientDataVersionTable";
import LoginHistoryTable from "./Tables/LoginHistoryTable";

const ProfilePage = () => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const access_token = localStorage.getItem("access_token");
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const userProf = JSON.parse(localStorage.getItem("userProf"));
  const clientData = JSON.parse(localStorage.getItem("client_data"));
  const [refreshData, setRefreshData] = useState(false);
  let adminData = {
    user: {
      id: userProf.id,
    },
  };

  const [openModal, setOpenModal] = useState(false);
  const [openSmsEmailModal, setOpenSmsEmailModal] = useState(false);
  const [smsAuthFlag, setSmsAuthFlag] = useState(false);

  const [typingTimeout, setTypingTimeout] = useState(0);
  const [query, setQuery] = useState("");

  const getStateByCode = (code) => {
    const state = listedStates.find((state) => state.code === code);
    return state ? state : null;
  };
  const [selectedState, setSelectedState] = useState(
    getStateByCode(clientData?.state)
  );

  const [profileDetail, setProfileDetail] = useState({
    first_name: userProf.first_name,
    last_name: userProf.last_name,
    email: userProf.email,
    // cell_phone_number: userProf.cell_phone_number.slice(2, 15),
    cell_phone_number: formatUSCell(userProf.cell_phone_number),
    address_line1: clientData?.address_line1,
    address_line2: clientData?.address_line2,
    state: getStateByCode(clientData?.state)?.code,
    city: clientData?.city,
    zip_code: clientData?.zip_code,
  });
  const [errorFlag, setErrorFlag] = useState({
    first_name: "",
    last_name: "",
    email: "",
    cell_phone_number: "",
    address_line1: "",
    address_line2: "",
    state: "",
    city: "",
    zip_code: "",
  });

  const formatcell_phone_number = (value) => {
    let convertedNumber = value.replace(/\D/g, "");
    convertedNumber = convertedNumber.replace(/^1/, "");
    convertedNumber = convertedNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "$1 $2 $3"
    );
    console.log(convertedNumber.length);
    return convertedNumber;
  };
  function formatUSCell(usPhoneNumber) {
    // Remove any non-digit characters
    let digitsOnly = usPhoneNumber.replace(/\D/g, "");

    // Check if the number has the expected length of 10 digits
    if (digitsOnly.length !== 10) {
      return "Invalid phone number format";
    }

    // Format the number in the original format and add +1 in front
    return "+1 " + digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
  const filterNumberLength = (value, max) => {
    const numericValue = value.replace(/[^0-9]/g, "");

    if (numericValue.length > max) {
      return numericValue.slice(0, max);
    }
    return numericValue;
  };
  function formatAlphabetsAndSpaces(input) {
    // Use a regular expression to match only alphabets and spaces
    const regex = /[^a-zA-Z ]/g;
    // Replace any characters that don't match the regex with an empty string
    const formattedString = input.replace(regex, "");

    return formattedString;
  }
  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return email.length > 0 && regex.test(email);
  };
  const filteredstates =
    query === ""
      ? listedStates
      : listedStates.filter((listedStates) =>
          listedStates.code
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const formHandler = (e) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setErrorFlag((prevErrorFlag) => ({
      ...prevErrorFlag,
      [e.target.name]: "",
    }));
    if (
      [
        "first_name",
        "last_name",
        "email",
        "cell_phone_number",
        "address_line1",
        "address_line2",
        "city",
        "zip_code",
      ].includes(e.target.name)
    ) {
      setProfileDetail((prevValue) => ({
        ...prevValue,
        [e.target.name]: ["first_name", "last_name", "city"].includes(
          e.target.name
        )
          ? formatAlphabetsAndSpaces(e.target.value)
          : e.target.name == "cell_phone_number"
          ? e.target.value
          : e.target.name == "zip_code"
          ? filterNumberLength(e.target.value, 5)
          : e.target.value,
      }));
    }
    setTypingTimeout(setTimeout(async () => validateFormFields(e), 300));
  };

  const validateFormFields = async (e) => {
    // // console.log("validating firm");
    if (["first_name", "last_name"].includes(e.target.name)) {
      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        [e.target.name]:
          e.target.value.length > 30
            ? `Please enter a valid ${e.target.name} (1-30 characters)`
            : "",
      }));
    } else if (e.target.name == "email") {
      // console.log("validating firm email");

      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        [e.target.name]: !validateEmail(e.target.value)
          ? "Enter a valid email."
          : "",
      }));
    } else if (e.target.name == "zip_code") {
      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        [e.target.name]:
          e.target.value.length != 5 ? "Zipcode should be 5 digit" : "",
      }));
    } else if (e.target.name == "cell_phone_number") {
      setErrorFlag((prevErrorFlag) => ({
        ...prevErrorFlag,
        [e.target.name]:
          formatcell_phone_number(e.target.value).length != 12
            ? "Phone number must be 10 digit"
            : "",
      }));
    }
  };

  const editProfLoader = useSelector(
    (state) => state.editProfileStorage.isLoading
  );
  const changePassLoader = useSelector(
    (state) => state.changePassStorage.isLoading
  );
  const setEmailSmsAuthPostLoader = useSelector(
    (state) => state.setEmailSmsAuthPostStorage.isLoading
  );
  const setAuthGetLoader = useSelector(
    (state) => state.setAuthGetStorage.isLoading
  );

  const setAuthPostLoader = useSelector(
    (state) => state.setAuthPostStorage.isLoading
  );

  const [editProfileFlag, setEditProfileFlag] = useState(false);

  const isSubmitEditButtonDisabled = () => {
    const commonRequiredFields = [
      "first_name",
      "last_name",
      "cell_phone_number",
      "email",
    ];
    const clientRequiredFields = [
      "address_line1",
      "address_line2",
      "state",
      "city",
      "zip_code",
    ];

    const requiredFields =
      role === "client"
        ? [...commonRequiredFields, ...clientRequiredFields]
        : commonRequiredFields;

    for (const key of requiredFields) {
      console.log(key);
      if (!profileDetail.hasOwnProperty(key) || profileDetail[key] === "") {
        setErrorFlag((prevErrorFlag) => ({
          ...prevErrorFlag,
          [key]: `*${key
            .replace("cell_phone_number", "phone number")
            .replace(/_/g, " ")
            .replace(/\b\w/g, (match) => match.toUpperCase())} is required`,
        }));
      }
    }

    return (
      requiredFields.some((field) => profileDetail[field] === "") ||
      requiredFields.some((field) => errorFlag[field] !== "")
    );
  };

  const submitEditProfileHandler = async () => {
    const isClientDetailError = isSubmitEditButtonDisabled();
    console.log(isClientDetailError);

    if (isClientDetailError) {
      toast.error("Complete the Fields", { id: 1 });
      return;
    }
    if (role == "client") {
      const res = await dispatch(
        editProfilePut(
          {
            cell_phone_number: formatcell_phone_number(
              profileDetail.cell_phone_number
            ),
            email: profileDetail.email,
            first_name: profileDetail.first_name,
            last_name: profileDetail.last_name,
            address_line1: profileDetail.address_line1,
            address_line2: profileDetail.address_line2,
            state: profileDetail.state,
            city: profileDetail.city,
            zip_code: profileDetail.zip_code,
          },
          navigate
        )
      );
      if (res.payload) {
        setRefreshData(!refreshData);
        console.log(res.payload);
        userProf.first_name = res.payload.first_name;
        userProf.last_name = res.payload.last_name;
        userProf.email = res.payload.email;
        userProf.cell_phone_number = res.payload.cell_phone_number;
        userProf.address_line1 = res.payload.address_line1;
        userProf.address_line2 = res.payload.address_line2;
        userProf.state = res.payload.state;
        userProf.city = res.payload.city;
        userProf.zip_code = res.payload.zip_code;
        userProf.id = userProf.id;
        userProf.chosen_2fa_method = userProf.chosen_2fa_method;
        userProf.is_2fa_enabled = userProf.is_2fa_enabled;
        userProf.role = userProf.role;
        localStorage.setItem("userProf", JSON.stringify(userProf));
        clientData.address_line1 = res.payload.address_line1;
        clientData.address_line2 = res.payload.address_line2;
        clientData.state = res.payload.state;
        clientData.city = res.payload.city;
        clientData.zip_code = res.payload.zip_code;
        localStorage.setItem("client_data", JSON.stringify(clientData));
        setEditProfileFlag(false);

        toast.success("Successfully Updated Profile", { id: 1 });
      }
      if (res.error) {
        // multiple errors handling in single response
        let err = JSON.parse(res.error.message);
        Object.keys(err).forEach((key) => {
          const value = err[key];
          console.log(`${key}: ${value}`);
          toast.error(value);
        });
        // console.log(err);
      }
    } else {
      const res = await dispatch(
        editProfilePut(
          {
            cell_phone_number: formatcell_phone_number(
              profileDetail.cell_phone_number
            ),
            email: profileDetail.email,
            first_name: profileDetail.first_name,
            last_name: profileDetail.last_name,
          },
          navigate
        )
      );
      if (res.payload) {
        setRefreshData(!refreshData);
        console.log(res.payload);
        userProf.first_name = res.payload.first_name;
        userProf.last_name = res.payload.last_name;
        userProf.email = res.payload.email;
        userProf.cell_phone_number = res.payload.cell_phone_number;
        localStorage.setItem("userProf", JSON.stringify(userProf));
        setEditProfileFlag(false);

        toast.success("Successfully Updated Profile", { id: 1 });
      }
      if (res.error) {
        // multiple errors handling in single response
        let err = JSON.parse(res.error.message);
        Object.keys(err).forEach((key) => {
          const value = err[key];
          console.log(`${key}: ${value}`);
          toast.error(value);
        });
        // console.log(err);
      }
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const [qrData, setQRData] = useState("");
  const [qrKey, setQrKey] = useState("");

  const splitStringIntoChunks = (inputString, chunkSize, delimiter) =>
    inputString.match(new RegExp(`.{1,${chunkSize}}`, "g")).join(delimiter);

  const handleCopyText = () => {
    navigator.clipboard.writeText(qrKey);
    toast.success("Copied Text", { id: 1 });
  };

  const [qrPage, setQrPage] = useState(false);

  const dispatch = useDispatch();

  const user2fa = JSON.parse(localStorage.getItem("user2fa"));

  const [selected, setSelected] = useState(
    user2fa ? user2fa.chosen_2fa_method : ""
  );

  const settings = [
    {
      name: "Email",
      description: "Send code to your email",
      preffered_2fa_method: "email",
    },
    {
      name: "Text message (SMS)",
      description: "We'll send a code to the number that you choose.",
      preffered_2fa_method: "sms",
    },
    {
      name: "Authenticator",
      description:
        "We'll recommend an app to download if you don't have one. It will generate a code that you'll enter when you log in.",
      preffered_2fa_method: "totp",
    },
  ];

  const [existQrFlag, setExistQrFlag] = useState(false);

  const handleSelectionChange = async (value) => {
    // setSelected(value);
    // Add your conditional logic here based on the selected value
    if (value.preffered_2fa_method === "email") {
      setSmsAuthFlag(false);
      setOpenSmsEmailModal(true);
      setQrPage(false);
      setOtp("");
      // changeEmailOSmsAuth("email");
      // Handle Option 1
    } else if (value.preffered_2fa_method === "sms") {
      setSmsAuthFlag(true);
      setOpenSmsEmailModal(true);
      setQrPage(false);
      setOtp("");
      // changeEmailOSmsAuth("sms");
      // Handle Option 2
    } else if (value.preffered_2fa_method === "totp") {
      const res = await dispatch(setAuthGet(navigate));
      if (res.payload) {
        if (res.payload?.qr_code) {
          setOpenModal(true);
          setQRData(res.payload.qr_code);
          setQrKey(res.payload.key);
          // setSelected(user2fa.chosen_2fa_method);
        } else {
          setOpenModal(true);
          setExistQrFlag(true);
        }
      }
      if (res.error) {
        console.log("This is error 2", res.error);
        toast.error(res.error.message, { id: 1 });
      }
    }
  };

  const decideExistAuth = async (action) => {
    if (action == "override") {
      const res = await dispatch(setAuthGet({ overide: "override" }, navigate));
      if (res.payload) {
        if (res.payload?.qr_code) {
          setExistQrFlag(false);
          setOpenModal(true);
          setQRData(res.payload.qr_code);
          setQrKey(res.payload.key);
          // setSelected(user2fa.chosen_2fa_method);
        }
      }
      if (res.error) {
        console.log("This is error 2", res.error);
        toast.error(res.error.message, { id: 1 });
      }
    } else {
      const res = await dispatch(
        setAuthPost(
          {
            assign: 1,
          },
          navigate
        )
      );
      if (res.payload) {
        if (user2fa) {
          user2fa.chosen_2fa_method = "totp";
          localStorage.setItem("user2fa", JSON.stringify(user2fa));
        }
        setSelected("totp");
        console.log("this is a res", res.payload);
        setOpenModal(false);
        toast.success(res.payload.message, { id: 1 });
      }
      if (res.error) {
        console.log("This is error 2", res.error);
        toast.error(res.error.message, { id: 1 });
      }
    }
  };

  useEffect(() => {
    if (user2fa) {
      setSelected(user2fa.chosen_2fa_method);
    }
    console.log("change");
  }, [openModal, user2fa]);
  const [toggle2FA, setToggle2FA] = useState(user2fa?.is_2fa_enabled);
  const change2FaStatus = async (e) => {
    console.log("E.TARGET", e);
    // setToggle2FA(!toggle2FA)
    const res = await dispatch(putTwoFactorPut(navigate));
    if (res.payload) {
      console.log(res.payload);
      setToggle2FA(!toggle2FA);
      user2fa.is_2fa_enabled = res.payload["2fa_status"];
      localStorage.setItem("user2fa", JSON.stringify(user2fa));
      toast.success(res.payload.message, { id: 1 });
    }
    if (res.error) {
      // setToggle2FA(!toggle2FA)
      let err = JSON.parse(res.error.message);
      // console.log(err);
    }
  };
  const profileRef = useRef(null);
  const basicRef = useRef(null);
  const changePassRef = useRef(null);
  const twoFactorRef = useRef(null);

  const scrollToContainer = (containerRef) => {
    containerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] =
    useState(false);

  const [passData, setPassData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [currentPassword, setCurrentPassword] = useState("");

  const handleChange = (e) => {
    if (e.target.name === "currentpassword") {
      setCurrentPassword(e.target.value);
    }
    setPassData({
      ...passData,
      [e.target.name]: e.target.value,
    });
  };
  const [postPassword, setPostPassword] = useState({
    currentPassword: "",
    password: "",
  });

  useEffect(() => {
    setPostPassword({
      currentPassword: currentPassword,
      password: passData.password,
    });
  }, [currentPassword, passData]);

  const toggleCurrentPasswordVisibility = () => {
    setIsCurrentPasswordVisible(!isCurrentPasswordVisible);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const submitChangePassword = async (e) => {
    e.preventDefault();
    if (passData.password !== passData.confirmPassword) {
      console.log("password mismatch!!!!!!");
      toast.error("New Password or Confirm Password Mismatch");
      return false;
    }
    console.log(postPassword);
    const res = await dispatch(
      changePassPut(
        {
          current_password: postPassword.currentPassword,
          new_password: postPassword.password,
        },
        navigate
      )
    );
    if (res.payload) {
      setPassData({ password: "", confirmPassword: "" });
      setCurrentPassword("");
      toast.success(res.payload.message, { id: 1 });
    }
    if (res.error) {
      let err = JSON.parse(res.error.message);
      console.log(err);
      if (Array.isArray(err.detail)) {
        toast.error(err.detail[0], { id: 1 });
      } else {
        toast.error(err.detail, { id: 1 });
      }
    }
  };

  const [otp, setOtp] = useState("");

  const validateOtp = async (e) => {
    e.preventDefault();
    const res = await dispatch(setAuthPost({ totp: otp }, navigate));
    if (res.payload) {
      if (user2fa) {
        user2fa.chosen_2fa_method = "totp";
        localStorage.setItem("user2fa", JSON.stringify(user2fa));
      }
      setSelected("totp");
      console.log("this is a res", res.payload);
      setOpenModal(false);
      setQrPage(false);
      setOtp("");
      toast.success(res.payload.message, { id: 1 });
    }
    if (res.error) {
      console.log("This is error 2", res.error);
      toast.error(res.error.message, { id: 1 });
    }
  };

  const changeEmailOSmsAuth = async (value) => {
    const res = await dispatch(
      setEmailSmsAuthPost({ preferred_2fa_method: value }, navigate)
    );
    if (res.payload) {
      setOpenSmsEmailModal(false);

      if (res.payload.detail === "2FA method changed to email.") {
        user2fa.chosen_2fa_method = "email";
        setSelected("email");
        localStorage.setItem("user2fa", JSON.stringify(user2fa));
      } else {
        user2fa.chosen_2fa_method = "sms";
        setSelected("sms");
        localStorage.setItem("user2fa", JSON.stringify(user2fa));
      }

      // setSelected(value);
      toast.success(res.payload.detail, { id: 1 });
      console.log("this is a res", res.payload);
    }
    if (res.error) {
      console.log("This is error 2", res.error);
      toast.error(res.error.message, { id: 1 });
    }
  };

  return (
    <div className="">
      <motion.div
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        transition={{ delay: 0.1, type: "spring", stiffness: 120 }}
      >
        <div className="flex w-full relative mt-5">
          {/* Left Container */}

          <div className="w-72  hidden h-full bg-white overflow-y-auto lg:block sticky left-50 top-10">
            <div className="px-4">
              <div className="mt-8">
                <div className="border text-[#344767] shadow-lg rounded-lg py-5 px-4 h-50">
                  <div
                    className="my-2 hover:cursor-pointer flex items-center gap-2"
                    onClick={() => scrollToContainer(profileRef)}
                  >
                    <div>
                      <UserIcon
                        className="h-5 w-5 shrink-0 text-[#344767]"
                        aria-hidden="true"
                      />
                    </div>
                    <div> Profile</div>
                  </div>
                  <div
                    className="my-2 hover:cursor-pointer flex items-center gap-2"
                    onClick={() => scrollToContainer(basicRef)}
                  >
                    <div>
                      <InformationCircleIcon
                        className="h-5 w-5 shrink-0 text-[#344767]"
                        aria-hidden="true"
                      />
                    </div>
                    <div> Basic Info </div>
                  </div>
                  <div
                    className="my-2 hover:cursor-pointer flex items-center gap-2"
                    onClick={() => scrollToContainer(changePassRef)}
                  >
                    <div>
                      <LockClosedIcon
                        className="h-5 w-5 shrink-0 text-[#344767]"
                        aria-hidden="true"
                      />
                    </div>
                    <div> Change Password </div>
                  </div>
                  <div
                    className="my-2 hover:cursor-pointer flex items-center gap-2"
                    onClick={() => scrollToContainer(twoFactorRef)}
                  >
                    <div>
                      <ShieldCheckIcon
                        className="h-5 w-5 shrink-0 text-[#344767]"
                        aria-hidden="true"
                      />
                    </div>
                    <div> Two-factor Authentication </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-4/4 overflow-y-auto">
            <div className="p-4">
              <div className="md:w-full lg:w-full lg:p-0  flex items-center justify-center mb-3">
                <div className="border sm:w-screen bg-white  rounded-lg md:w-full lg:w-full p-8  shadow-lg w-96">
                  <div className="w-full flex justify-between items-center">
                    <div ref={profileRef}>
                      <div className="-m-1.5 flex items-center p-1.5">
                        <span className="flex items-center ">
                          <span
                            className="ml-4 text-lg font-semibold leading-6 text-black"
                            aria-hidden="true"
                          >
                            {userProf && (
                              <>
                                <span> {userProf.first_name}</span>
                                <span className="ml-1">
                                  {userProf.last_name}
                                </span>
                              </>
                            )}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-4">
              <div className=" md:w-full lg:w-full lg:p-0  flex items-center justify-center rounded-lg">
                <div
                  ref={basicRef}
                  className="border sm:w-screen bg-white  rounded-lg md:w-full lg:w-full p-8  shadow-lg w-96"
                >
                  <h2 className="text-2xl font-semibold mb-4 text-[#344767]">
                    Basic Info
                  </h2>
                  <form className="mt-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="mb-4">
                        <label className="block font-medium text-[#8b90a7]">
                          First Name
                        </label>
                        {!editProfileFlag ? (
                          <>
                            <div className="mt-2 block w-full  border-b-2 text-black">
                              {userProf &&
                                (userProf.first_name ? (
                                  <>
                                    <span>{userProf.first_name}</span>
                                  </>
                                ) : (
                                  <>Nil</>
                                ))}
                            </div>
                          </>
                        ) : (
                          <>
                            <input
                              onChange={(e) => formHandler(e)}
                              name="first_name"
                              value={profileDetail.first_name}
                              className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                            />
                            <span className="text-red-500 text-xs">
                              {errorFlag?.first_name}
                            </span>
                          </>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="block font-medium text-[#8b90a7]">
                          Last Name
                        </label>
                        {!editProfileFlag ? (
                          <>
                            <div className="mt-2 block w-full  border-b-2 text-black">
                              {userProf &&
                                (userProf.last_name ? (
                                  <>
                                    <span>{userProf.last_name}</span>
                                  </>
                                ) : (
                                  <>Nil</>
                                ))}
                            </div>
                          </>
                        ) : (
                          <>
                            <input
                              onChange={(e) => formHandler(e)}
                              name="last_name"
                              value={profileDetail.last_name}
                              className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                            />
                            <span className="text-red-500 text-xs">
                              {errorFlag?.last_name}
                            </span>
                          </>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="block font-medium text-[#8b90a7]">
                          Email
                        </label>
                        {!editProfileFlag ? (
                          <>
                            <div className="mt-2 block w-full  border-b-2 text-black">
                              {userProf &&
                                (userProf.email ? (
                                  <>
                                    <span>{userProf.email}</span>
                                  </>
                                ) : (
                                  <>Nil</>
                                ))}
                            </div>
                          </>
                        ) : (
                          <>
                            <input
                              type="email"
                              onChange={(e) => formHandler(e)}
                              name="email"
                              placeholder="example@willgibbins.com"
                              value={profileDetail.email}
                              className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                            />
                            <span className="text-red-500 text-xs">
                              {errorFlag?.email}
                            </span>
                          </>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="block font-medium text-[#8b90a7]">
                          Primary Phone
                        </label>
                        {!editProfileFlag ? (
                          <>
                            <div className="mt-2 block w-full  border-b-2 text-black">
                              {userProf &&
                                (userProf.cell_phone_number ? (
                                  <>
                                    <span>
                                      {formatUSCell(userProf.cell_phone_number)}
                                    </span>
                                  </>
                                ) : (
                                  <>Nil</>
                                ))}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="w-full flex items-center">
                              <div className="w-full">
                                <CurrencyFormat
                                  name="cell_phone_number"
                                  value={profileDetail.cell_phone_number}
                                  onChange={(e) => formHandler(e)}
                                  className="block w-full pl-3  mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                  format="+1 (###) ###-####"
                                  mask="_"
                                />
                                <span className="text-red-500 text-xs">
                                  {errorFlag?.cell_phone_number}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      {role == "client" && (
                        <>
                          <div className="mb-4">
                            <label className="block font-medium text-[#8b90a7]">
                              Address Line 1
                            </label>
                            {!editProfileFlag ? (
                              <>
                                <div className="mt-2 block w-full  border-b-2 text-black">
                                  {clientData &&
                                    (clientData.address_line1 ? (
                                      <>
                                        <span>{clientData.address_line1}</span>
                                      </>
                                    ) : (
                                      <>Nil</>
                                    ))}
                                </div>
                              </>
                            ) : (
                              <>
                                <input
                                  placeholder=""
                                  onChange={(e) => formHandler(e)}
                                  required
                                  name="address_line1"
                                  value={profileDetail.address_line1}
                                  className="block w-full pl-3  mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                />
                                <span className="text-red-500 text-xs">
                                  {errorFlag?.address_line1}
                                </span>
                              </>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block font-medium text-[#8b90a7]">
                              Address Line 2
                            </label>
                            {!editProfileFlag ? (
                              <>
                                <div className="mt-2 block w-full  border-b-2 text-black">
                                  {clientData &&
                                    (clientData.address_line2 ? (
                                      <>
                                        <span>{clientData.address_line2}</span>
                                      </>
                                    ) : (
                                      <>Nil</>
                                    ))}
                                </div>
                              </>
                            ) : (
                              <>
                                <input
                                  placeholder=""
                                  onChange={(e) => formHandler(e)}
                                  required
                                  name="address_line2"
                                  value={profileDetail.address_line2}
                                  className="block w-full pl-3  mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                />
                                <span className="text-red-500 text-xs">
                                  {errorFlag?.address_line2}
                                </span>
                              </>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block font-medium text-[#8b90a7]">
                              State
                            </label>
                            {!editProfileFlag ? (
                              <>
                                <div className="mt-2 block w-full  border-b-2 text-black">
                                  {clientData &&
                                    (clientData.state ? (
                                      <>
                                        <span>
                                          {
                                            getStateByCode(clientData?.state)
                                              ?.code
                                          }
                                        </span>
                                      </>
                                    ) : (
                                      <>Nil</>
                                    ))}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="mt-1 w-full h-full flex items-center">
                                  <div className="grow h-full">
                                    <Combobox
                                      value={selectedState}
                                      name="state"
                                      onChange={(value) => {
                                        // console.log(value);
                                        setSelectedState(value);
                                        // formHandler()
                                        setProfileDetail((prevValue) => ({
                                          ...prevValue,
                                          state: value.code,
                                        }));
                                        setErrorFlag((prevErrorFlag) => ({
                                          ...prevErrorFlag,
                                          state: "",
                                        }));
                                      }}
                                    >
                                      <div className="relative">
                                        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                          <Combobox.Input
                                            className="mt-2 border-b-1 focus:border-[#344767] block w-full outline-none   border-b-2 text-[#344767]"
                                            displayValue={(listedStates) =>
                                              listedStates.code
                                            }
                                            onChange={(event) => {
                                              setQuery(event.target.value);
                                            }}
                                            placeholder=""
                                          />
                                        </div>
                                        <span className="text-red-500 text-xs">
                                          {errorFlag?.state}
                                        </span>
                                        <Transition
                                          as={Fragment}
                                          leave="transition ease-in duration-100"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                          afterLeave={() => setQuery("")}
                                        >
                                          <Combobox.Options className="absolute  max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {filteredstates.length === 0 &&
                                            query !== "" ? (
                                              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                No match found.
                                              </div>
                                            ) : (
                                              filteredstates.map(
                                                (listedStates) => (
                                                  <Combobox.Option
                                                    key={listedStates.id}
                                                    className={({ active }) =>
                                                      `relative  z-10 cursor-default select-none py-2 pl-10 pr-4  ${
                                                        active
                                                          ? "bg-[#376080] text-white"
                                                          : "bg-white text-gray-900"
                                                      }`
                                                    }
                                                    value={listedStates}
                                                    disabled={
                                                      listedStates.unavailable
                                                    }
                                                  >
                                                    {({ selected, active }) => (
                                                      <>
                                                        <span
                                                          className={`block truncate  ${
                                                            selected
                                                              ? "font-medium"
                                                              : "font-normal"
                                                          }`}
                                                        >
                                                          {listedStates.code}
                                                        </span>
                                                        {selected ? (
                                                          <span
                                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                              active
                                                                ? "text-white"
                                                                : "text-[#376080]"
                                                            }`}
                                                          >
                                                            <CheckIcon
                                                              className="h-5 w-5"
                                                              aria-hidden="true"
                                                            />
                                                          </span>
                                                        ) : null}
                                                      </>
                                                    )}
                                                  </Combobox.Option>
                                                )
                                              )
                                            )}
                                          </Combobox.Options>
                                        </Transition>
                                      </div>
                                    </Combobox>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block font-medium text-[#8b90a7]">
                              City
                            </label>
                            {!editProfileFlag ? (
                              <>
                                <div className="mt-2 block w-full  border-b-2 text-black">
                                  {clientData &&
                                    (clientData.city ? (
                                      <>
                                        <span>{clientData.city}</span>
                                      </>
                                    ) : (
                                      <>Nil</>
                                    ))}
                                </div>
                              </>
                            ) : (
                              <>
                                <input
                                  placeholder=""
                                  onChange={(e) => formHandler(e)}
                                  required
                                  name="city"
                                  value={profileDetail.city}
                                  className="block w-full pl-3  mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                />
                                <span className="text-red-500 text-xs">
                                  {errorFlag?.city}
                                </span>
                              </>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="block font-medium text-[#8b90a7]">
                              Zip Code
                            </label>
                            {!editProfileFlag ? (
                              <>
                                <div className="mt-2 block w-full  border-b-2 text-black">
                                  {clientData &&
                                    (clientData.zip_code ? (
                                      <>
                                        <span>{clientData.zip_code}</span>
                                      </>
                                    ) : (
                                      <>Nil</>
                                    ))}
                                </div>
                              </>
                            ) : (
                              <>
                                <input
                                  placeholder=""
                                  onChange={(e) => formHandler(e)}
                                  required
                                  name="zip_code"
                                  value={profileDetail.zip_code}
                                  className="block w-full pl-3  mt-2 border-b-1 focus:border-[#344767] block w-full outline-none  border-b-2 text-[#344767]"
                                />
                                <span className="text-red-500 text-xs">
                                  {errorFlag?.zip_code}
                                </span>
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="w-full mt-5 flex justify-end items-center">
                      {!editProfileFlag ? (
                        <>
                          <div className="">
                            <button
                              type="button"
                              onClick={() => {
                                setEditProfileFlag(true);
                              }}
                              className="bg-[#272729] text-white px-4 py-2 rounded-lg hover:bg-[#172729] focus:outline-none focus:bg-[#272729]"
                            >
                              EDIT PROFILE
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          {editProfLoader ? (
                            <div className="w-full flex justify-center items-center">
                              <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color="#1a3253"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            </div>
                          ) : (
                            <div className="flex gap-5">
                              <button
                                type="button"
                                onClick={() => submitEditProfileHandler()}
                                className={`text-white px-4 py-2 rounded-lg bg-[#272729] hover:bg-[#172729] focus:outline-none focus:bg-[#272729]`}
                              >
                                Submit
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  setEditProfileFlag(false);
                                  setSelectedState(
                                    getStateByCode(clientData?.state)
                                  );
                                  setProfileDetail((prevState) => ({
                                    ...prevState,
                                    first_name: userProf.first_name,
                                    last_name: userProf.last_name,
                                    email: userProf.email,
                                    cell_phone_number: formatUSCell(
                                      userProf.cell_phone_number
                                    ),
                                    address_line1: clientData?.address_line1,
                                    address_line2: clientData?.address_line2,
                                    state: getStateByCode(clientData?.state)
                                      ?.code,
                                    city: clientData?.city,
                                    zip_code: clientData?.zip_code,
                                  }));
                                  setErrorFlag({
                                    first_name: "",
                                    last_name: "",
                                    email: "",
                                    cell_phone_number: "",
                                    address_line1: "",
                                    address_line2: "",
                                    state: "",
                                    city: "",
                                    zip_code: "",
                                  });
                                }}
                                className="bg-[#272729] text-white px-4 py-2 rounded-lg hover:bg-[#172729] focus:outline-none focus:bg-[#272729]"
                              >
                                Cancel
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="p-4">
              <div className="py-10 ">
                <div ref={changePassRef} className="">
                  <div className=" md:w-full lg:w-full lg:p-0 flex items-center justify-center   rounded-lg">
                    <div className="border sm:w-screen bg-white rounded-lg  md:w-full lg:w-full p-8  shadow-lg w-96">
                      <h2 className="text-2xl font-semibold mb-4 text-[#344767]">
                        Change Password
                      </h2>
                      <form
                        onSubmit={(e) => {
                          submitChangePassword(e);
                        }}
                      >
                        <div className="mb-4">
                          <div className="mt-2 relative w-full">
                            <input
                              id="currentpassword"
                              name="currentpassword"
                              type={
                                isCurrentPasswordVisible ? "text" : "password"
                              }
                              autoComplete=""
                              placeholder="Current Password"
                              onChange={handleChange}
                              required
                              value={currentPassword}
                              className="block w-full rounded-md border py-1.5 shadow-sm  placeholder:text-[#9ca0b3] pl-4 focus:outline-none sm:text-sm sm:leading-6"
                            />
                            <button
                              type="button"
                              className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                              onClick={() => toggleCurrentPasswordVisibility()}
                            >
                              {isCurrentPasswordVisible ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-5 h-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-5 h-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="mb-4">
                          <div className="mt-2 relative w-full">
                            <input
                              id="password"
                              name="password"
                              type={isPasswordVisible ? "text" : "password"}
                              autoComplete="current-password"
                              placeholder="Password"
                              onChange={handleChange}
                              required
                              value={passData.password}
                              className="block w-full rounded-md border py-1.5 shadow-sm  placeholder:text-[#9ca0b3] pl-4 focus:outline-none sm:text-sm sm:leading-6"
                            />
                            <button
                              type="button"
                              className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                              onClick={() => togglePasswordVisibility()}
                            >
                              {isPasswordVisible ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-5 h-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-5 h-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="mb-4">
                          <div className="mt-2 relative w-full">
                            <input
                              id="confirmPassword"
                              name="confirmPassword"
                              type={isPasswordVisible ? "text" : "password"}
                              autoComplete=""
                              placeholder="Confirm Password"
                              onChange={handleChange}
                              required
                              value={passData.confirmPassword}
                              className="block w-full rounded-md border py-1.5 shadow-sm  placeholder:text-[#9ca0b3] pl-4 focus:outline-none sm:text-sm sm:leading-6"
                            />
                            <button
                              type="button"
                              className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                              onClick={() => togglePasswordVisibility()}
                            >
                              {isPasswordVisible ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-5 h-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-5 h-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>

                        <div className="w-full md:flex lg:flex justify-between sm:flex-col">
                          <div>
                            <div className="my-3 text-[#344767] text-lg font-bold">
                              Password requirements
                            </div>
                            <div className="text-[#9ca0b3] text-md my-3">
                              Please follow this guide for a strong password
                            </div>
                            <ul className="text-[#9ca0b3] text-xs pl-3 list-disc">
                              <li className="my-1">One special characters</li>
                              <li className="my-1">Min 6 characters</li>
                              <li className="my-1">
                                One number(2 are recommended)
                              </li>
                              <li className="my-1">Change it often</li>
                            </ul>
                          </div>
                          <div>
                            {changePassLoader ? (
                              <div className="w-full flex justify-center items-center">
                                <ThreeDots
                                  height="80"
                                  width="80"
                                  radius="9"
                                  color="#1a3253"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              </div>
                            ) : (
                              <div className="text-end mt-5 ">
                                <button
                                  type="submit"
                                  className="bg-[#272729] text-white px-4 py-2 rounded-lg hover:bg-[#172729] focus:outline-none focus:bg-[#272729]"
                                >
                                  UPDATE PASSWORD
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4">
              <div className="py-10 ">
                <div ref={twoFactorRef} className="">
                  <div className=" md:w-full lg:w-full lg:p-0 flex items-center justify-center  rounded-lg ">
                    <div className="border sm:w-screen bg-white rounded-lg  md:w-full lg:w-full p-8  shadow-lg w-96">
                      <div className="w-full flex justify-between items-center mb-4">
                        <h2 className="text-2xl font-semibold  text-[#344767]">
                          Two-factor Authentication
                        </h2>
                        <div>
                          {role == "client" && (
                            <div className="flex items-center gap-3">
                              <label className="block text-base leading-6 text-[#8a8ea6]">
                                Activate:
                              </label>
                              <Switch
                                checked={toggle2FA}
                                onChange={(e) => {
                                  change2FaStatus(e);
                                }}
                                className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full "
                              >
                                <span
                                  aria-hidden="true"
                                  className="pointer-events-none absolute h-full w-full rounded-md bg-[#F0F2F5]"
                                />
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    toggle2FA ? "bg-[#1a3253]" : "bg-gray-200",
                                    "pointer-events-none absolute mx-auto h-4 w-9 rounded-full border border-gray-300 transition-colors duration-200 ease-in-out"
                                  )}
                                />
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    toggle2FA
                                      ? "translate-x-5"
                                      : "translate-x-0",
                                    "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-black border border-gray-400 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
                                  )}
                                />
                              </Switch>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className={user2fa?.is_2fa_enabled ? "" : `blur-[1px]`}
                      >
                        <RadioGroup
                          value={selected}
                          onChange={handleSelectionChange}
                          disabled={!user2fa?.is_2fa_enabled}
                        >
                          <RadioGroup.Label className="sr-only">
                            Privacy setting
                          </RadioGroup.Label>
                          <div className="-space-y-px rounded-md bg-white">
                            {settings.map((setting, settingIdx) => (
                              <RadioGroup.Option
                                key={setting.name}
                                value={setting}
                                className={({ checked }) =>
                                  classNames(
                                    settingIdx === 0
                                      ? "rounded-tl-md rounded-tr-md"
                                      : "",
                                    settingIdx === settings.length - 1
                                      ? "rounded-bl-md rounded-br-md"
                                      : "",
                                    checked
                                      ? "z-10 border-indigo-200 bg-indigo-50"
                                      : "border-gray-200",
                                    "relative flex cursor-pointer border p-4 focus:outline-none"
                                  )
                                }
                              >
                                {({ active, checked }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected ===
                                          setting.preffered_2fa_method
                                          ? "bg-indigo-600 border-transparent"
                                          : "bg-white border-gray-300",
                                        active
                                          ? "ring-2 ring-offset-2 ring-indigo-600 bg-indigo-600"
                                          : "",
                                        "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                      )}
                                      aria-hidden="true"
                                    >
                                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                                    </span>
                                    <span className="ml-3 flex flex-col">
                                      <RadioGroup.Label
                                        as="span"
                                        className={classNames(
                                          checked
                                            ? "text-indigo-900"
                                            : "text-gray-900",
                                          "block text-sm font-medium"
                                        )}
                                      >
                                        {setting.name}
                                      </RadioGroup.Label>
                                      <RadioGroup.Description
                                        as="span"
                                        className={classNames(
                                          checked
                                            ? "text-indigo-700"
                                            : "text-gray-500",
                                          "block text-sm"
                                        )}
                                      >
                                        {setting.description}
                                      </RadioGroup.Description>
                                    </span>
                                  </>
                                )}
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {role == "admin" && adminData ? (
              <LoginHistoryTable clientData={adminData} />
            ) : (
              ""
            )}
        {role == "admin" ? (
          <ClientDataVersionTable
            clientData={adminData}
            refetchVersion={refreshData}
          />
        ) : (
          ""
        )}
      </motion.div>

      <ModalComponent open={openModal} handleClose={() => {}}>
        <div className="flex justify-center items-center">
          <div className="w-full  relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  sm:max-w-lg">
            {existQrFlag ? (
              <div className="w-full  relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                      <QrCodeIcon
                        className="h-6 w-6 text-indigo-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <h3 className="text-base font-semibold leading-6 text-gray-900">
                        AUTHENTICATOR
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          You have an active Authenticator app
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {setAuthGetLoader || setAuthPostLoader ? (
                  <div className="w-full flex justify-center items-center">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#1a3253"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 sm:ml-3 sm:w-auto outline-none"
                      onClick={() => decideExistAuth("override")}
                    >
                      Set-up New
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => decideExistAuth("assign")}
                    >
                      Keep exist
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex items-center justify-center bg-white p-5">
                {!qrPage ? (
                  <div className="bg-white">
                    <div className="w-full flex justify-end">
                      <span
                        className="border hover:cursor-pointer"
                        onClick={() => {
                          setOpenModal(false);
                          setOtp("")
                        }}
                      >
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="48"
                            height="48"
                            fill="white"
                            fill-opacity="0.01"
                          />
                          <path
                            d="M14 14L34 34"
                            stroke="#333"
                            stroke-width="1"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14 34L34 14"
                            stroke="#333"
                            stroke-width="1"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="mt-5">
                      <div className="text-2xl font-bold my-3">
                        Instructions for setup
                      </div>
                      <div className="my-5">
                        <div className="font-bold text-xl text-[#1c2b33]">
                          1. Download an authentication app
                        </div>
                        <div className="text-sm px-5 text-[#4a585f]">
                          We recommend downloading Duo Mobile or Google
                          Authenticator if you don't have one installed.
                        </div>
                      </div>
                      <div className="my-5">
                        <div className="font-bold text-xl text-[#1c2b33]">
                          {" "}
                          2. Scan this barcode/QR code or copy the key{" "}
                        </div>
                        <div className="text-sm px-5 text-[#4a585f]">
                          Scan this barcode/QR code in the authentication app or
                          copy the key and paste it in the authentication app.
                        </div>
                      </div>
                      <div className="my-5 px-5">
                        <div className="w-full flex justify-center">
                          {setAuthGetLoader ? (
                            <div className="w-full flex justify-center items-center">
                              <MagnifyingGlass
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="MagnifyingGlass-loading"
                                wrapperStyle={{}}
                                wrapperClass="MagnifyingGlass-wrapper"
                                glassColor="#ffff"
                                color="#1a3253"
                              />
                            </div>
                          ) : (
                            <img
                              className="h-32 w-32"
                              src={`data:image/png;base64,${qrData}`}
                              alt="qr_code"
                            />
                          )}

                          <>
                            {!setAuthGetLoader && (
                              <div className="w-full flex flex-col justify-center ml-3 gap-3 ">
                                {qrKey && (
                                  <div className=" text-lg font-bold break-words">
                                    {splitStringIntoChunks(qrKey, 4, " ")}
                                  </div>
                                )}

                                <div
                                  className="font-bold text-[#2379e5] hover:cursor-pointer"
                                  onClick={handleCopyText}
                                >
                                  Copy the key
                                </div>
                              </div>
                            )}
                          </>
                        </div>
                      </div>
                      <div className="my-5">
                        <div className="font-bold text-xl text-[#1c2b33]">
                          3. Copy and enter 6-digit code
                        </div>
                        <div className="text-sm px-5 text-[#4a585f]">
                          After the barcode/QR code has been scanned or the key
                          has been entered, your authentication app will
                          generate a 6-digit code. Copy the code and then come
                          back to National Access to enter it.
                        </div>
                      </div>
                    </div>
                    <div
                      className="bg-[#3a6281] py-2 rounded-lg text-white text-center font-bold hover:cursor-pointer"
                      onClick={() => setQrPage(true)}
                    >
                      Next
                    </div>
                  </div>
                ) : (
                  <div className="">
                    <div className="w-full flex justify-between">
                      <span
                        className="hover:cursor-pointer"
                        onClick={() => {
                          setQrPage(false);
                          setOtp("")
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          fill="currentColor"
                          class="bi bi-arrow-left-circle"
                          viewBox="0 0 16 16"
                        >
                          {" "}
                          <path
                            fill-rule="evenodd"
                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                          />{" "}
                        </svg>
                      </span>
                      <span
                        className="border hover:cursor-pointer"
                        onClick={() => {
                          setOpenModal(false);
                          setOtp("")
                        }}
                      >
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="48"
                            height="48"
                            fill="white"
                            fill-opacity="0.01"
                          />
                          <path
                            d="M14 14L34 34"
                            stroke="#333"
                            stroke-width="1"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14 34L34 14"
                            stroke="#333"
                            stroke-width="1"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="mt-5">
                      <div className="text-2xl font-bold my-3">
                        Get your code from your authentication app
                      </div>
                      <div className="my-5">
                        <div className="text-md text-[#1c2b33]">
                          Enter the 6-digit code generated by your
                          authentication app
                        </div>
                      </div>
                      <div>
                        <div>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              validateOtp(e);
                            }}
                          >
                            <div
                              id="otpContainer"
                              className=" h-28 flex justify-center align-center"
                            >
                              <OTPInput
                                value={otp}
                                onChange={setOtp}
                                // inputType="number"
                                numInputs={6}
                                // renderSeparator={<span>_</span>}
                                renderInput={(props) => (
                                  <input
                                    {...props}
                                    type="number"
                                    placeholder="-"
                                    style={{
                                      fontSize: "1.5rem",
                                      height: "3rem",
                                      width: "100%",
                                      margin: "0.2rem",
                                      padding: "0.2rem 0 0.2rem 0",
                                      textAlign: "center",
                                      borderBottom: "1px solid #376080",
                                      // borderRadius: "5px",
                                      backgroundColor: "#f0f2f5",
                                    }}
                                  />
                                )}
                              />
                            </div>
                            <div className="">
                              {setAuthPostLoader ? (
                                <div className="w-full flex justify-center items-center">
                                  <ThreeDots
                                    height="80"
                                    width="80"
                                    radius="9"
                                    color="#1a3253"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                </div>
                              ) : (
                                <button
                                  type="submit"
                                  className="w-full bg-[#3a6281] py-2 rounded-lg text-white text-center font-bold"
                                >
                                  Next
                                </button>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </ModalComponent>

      <ModalComponent open={openSmsEmailModal} handleClose={handleClose}>
        <>
          {!smsAuthFlag ? (
            <div className="flex justify-center items-center">
              <div className="w-full  relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                      <EnvelopeIcon
                        className="h-6 w-6 text-indigo-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <h3 className="text-base font-semibold leading-6 text-gray-900">
                        EMAIL
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Choose Email as the default 2 Factor Method
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {setEmailSmsAuthPostLoader ? (
                  <div className="w-full flex justify-center items-center">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#1a3253"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 sm:ml-3 sm:w-auto outline-none"
                      onClick={() => changeEmailOSmsAuth("email")}
                    >
                      Turn On
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpenSmsEmailModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <div className="w-full  relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                      <PhoneArrowDownLeftIcon
                        className="h-6 w-6 text-indigo-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <h3 className="text-base font-semibold leading-6 text-gray-900">
                        SMS
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Choose SMS as the default 2 Factor Method
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {setEmailSmsAuthPostLoader ? (
                  <div className="w-full flex justify-center items-center">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#1a3253"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 sm:ml-3 sm:w-auto outline-none"
                      onClick={() => changeEmailOSmsAuth("sms")}
                    >
                      Turn On
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpenSmsEmailModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      </ModalComponent>
    </div>
  );
};

export default ProfilePage;
