import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition, Popover } from "@headlessui/react";
import { BellIcon } from "@heroicons/react/24/outline";
import { domainUrl } from "../utils/constants";
import axios from "axios";
import toast from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { Badge } from "@mui/material";

const NotificationComponent = () => {
  const access_token = localStorage.getItem("access_token");
  const [data, setData] = useState([]);
  const [markAsReadLoader, setMarkAsReadLoader] = useState({});

  function extractDateFromTimestamp(timestamp) {
    const dateObject = new Date(timestamp);
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 as getMonth() returns 0-based index
    const day = dateObject.getDate().toString().padStart(2, "0");
    const year = dateObject.getFullYear();
    return `${month}/${day}/${year}`;
  }

  function extractTimeFromTimestamp(timestamp) {
    const dateObject = new Date(timestamp);
    const hour = dateObject.getHours().toString().padStart(2, "0");
    const minute = dateObject.getMinutes().toString().padStart(2, "0");
    return `${hour}:${minute}`;
  }

  const getNotificationData = async () => {
    try {
      const res = await axios.get(
        `${domainUrl}notifications?offset=0&limit=999`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res?.data && res.data.data) {
        setData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getNotificationData();
    };

    fetchData();
    const intervalId = setInterval(fetchData, 15000);

    return () => clearInterval(intervalId);
  }, []);

  const markNotificationData = async (id) => {
    try {
      setMarkAsReadLoader({ ...markAsReadLoader, [id]: true });

      const res = await axios.post(
        `${domainUrl}notifications?id=${id}&offset=0&limit=999`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (res?.data) {
        console.log(res.data);
        setData(res.data.data);
        toast.success("Marked as Read");
        setMarkAsReadLoader({ ...markAsReadLoader, [id]: false });
      }
    } catch (error) {
      console.log(error);
      setMarkAsReadLoader({ ...markAsReadLoader, [id]: false });
    }
  };
  return (
    <>
      <div className="flex items-center gap-x-4 lg:gap-x-6">
        <Popover className="relative">
          <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 focus:outline-none">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500 focus:outline-none"
            >
              <span className="sr-only">View notifications</span>
              {/* <span className="relative inline-block">
                <BellIcon className="h-6 w-6" aria-hidden="true" />
                {data.length !== 0 ? (
                  <span className="absolute right-0 top-0 block h-2 w-2 rounded-full bg-green-400 ring-2 ring-white" />
                ) : (
                  ""
                )}
              </span> */}
              <Badge
                color="primary"
                badgeContent={data.length ? data.length : 0}
                max={999}
              >
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </Badge>
            </button>
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 mt-5 flex w-screen max-w-max translate-x-0">
              <div className="flex-auto overflow-hidden rounded-lg  bg-white text-sm leading-6 shadow-xl ring-1 ring-gray-900/5">
                <div className="w-96 pb-3 sm:w-full">
                  <header className="bg-[#4f4f4f] flex items-center justify-between p-2">
                    <div className="inline-flex items-center gap-2 text-lg  leading-7 text-white">
                      <span>
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 44 42"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 20C0 8.9543 8.95431 0 20 0H24C35.0457 0 44 8.95431 44 20V42H20C8.9543 42 0 33.0457 0 22V20Z"
                            fill="white"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M19.0805 22.4044L9.65655 19.4059C7.19541 18.6228 5.96484 18.2312 5.96484 17.5C5.96484 16.7688 7.19541 16.3772 9.65655 15.5942L32.8526 8.21361C34.5167 7.68411 35.3488 7.41936 35.7798 7.86256C36.2108 8.30577 35.9229 9.13012 35.3471 10.7788L27.5549 33.0929C26.7695 35.3422 26.3767 36.4668 25.6667 36.4668C24.9567 36.4668 24.564 35.3422 23.7785 33.0929L20.5288 23.7868L28.1905 16.4734C28.59 16.092 28.6048 15.459 28.2234 15.0595C27.8421 14.66 27.2091 14.6453 26.8096 15.0266L19.0805 22.4044Z"
                            fill="#418AEB"
                          />
                        </svg>
                      </span>
                      Notifications
                    </div>
                  </header>
                  <div className="w-full max-h-[350px] px-2 overflow-y-auto bg-white sm:w-[450px]">
                    <ul
                      role="list"
                      className="w-full divide-y divide-gray-100 border-l border-r"
                    >
                      {data.length ? (
                        data.map((item, index) => (
                          <>
                            <li
                              key={index}
                              className="w-full h-full pb-2 my-2 bg-white"
                            >
                              <div className="border-b border-gray-700 rounded-2xl hover:bg-gray-200 transition-all duration-75 cursor-pointer">
                                <div className="w-full px-1 py-3 rounded-b-2xl bg-[#1a73e7] flex items-center justify-between">
                                  <div className="w-full flex items-center gap-x-4">
                                    <div className="w-full">
                                      <p className="text-xs  text-white sm:text-sm">
                                        {item.label}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-3 mr-1">
                                    <div className="text-xs text-white">
                                      {extractDateFromTimestamp(
                                        item.created_time
                                      )}
                                    </div>

                                    <div className="text-xs text-white">
                                      <time>
                                        {extractTimeFromTimestamp(
                                          item.created_time
                                        )}
                                      </time>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-1 mx-2 h-full overflow-auto break-words">
                                  <p className="w-full text-xs leading-5 text-gray-900 sm:text-sm">
                                    {item.description}
                                  </p>
                                </div>
                                <div className="flex justify-end items-center">
                                  {markAsReadLoader[item.id] ? (
                                    <div className="mx-3">
                                      <ThreeDots
                                        visible={true}
                                        height="35"
                                        width="35"
                                        ariaLabel="hourglass-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        color={"#72a1ed"}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() => {
                                        console.log(item.id);
                                        markNotificationData(item.id);
                                      }}
                                      className="my-2 mx-2 text-end text-[#4788e0] font-bold active:text-blue-300"
                                    >
                                      Mark As Read
                                    </div>
                                  )}
                                </div>
                              </div>
                            </li>
                          </>
                        ))
                      ) : (
                        <div className="p-5 text-center text-base font-semibold italic">
                          No notifications on board
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
    </>
  );
};

export default NotificationComponent;
