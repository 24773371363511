import { motion } from "framer-motion";
import PendingClientsTable from "../Tables/PendingClientsTable";
import PendingInvitationsTable from "../Tables/PendingInvitationsTable";
import ApprovedClientsTable from "../Tables/ApprovedClientsTable";
import RejectedClientsTable from "../Tables/RejectedClientsTable";
import AttorneyContractStatusPie from "../../Components/Graphs/AttorneyContractStatusPie";
import AttorneyClientsBar from "../../Components/Graphs/AttorneyClientsBar";
import AttorneyPayoutBar from "../../Components/Graphs/AttorneyPayoutBar";
import AttorneyExpectedBar from "../../Components/Graphs/AttorneyExpectedBar";

export default function AttorneyDashboard() {
  return (
    <>
      <div>
        <motion.div
          initial={{ y: 100 }}
          animate={{ y: 0 }}
          transition={{ delay: 0.1, type: "spring", stiffness: 120 }}
        >
          <main className="py-10">
            <div className="mt-5 container m-auto grid grid-cols-1 place-items-start gap-x-2 gap-y-24 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
              <>
                <AttorneyContractStatusPie />
                <AttorneyClientsBar />
                <AttorneyPayoutBar />
                <AttorneyExpectedBar />
              </>
            </div>
            <PendingClientsTable />
            <PendingInvitationsTable />
            <ApprovedClientsTable />
            <RejectedClientsTable />
          </main>
        </motion.div>
      </div>
    </>
  );
}
