import React from "react";
import ArchivedClientsTable from "./Tables/ArchivedClientsTable";

const ArchivedClients = () => {
  return (
    <>
      <ArchivedClientsTable />
    </>
  );
};

export default ArchivedClients;
