import { Link } from "react-router-dom";
import page_not_found_img from "../assets/img/page_not_found_img.jpg";

export default function NotFoundPage() {
  return (
    <>
      <div className="h-screen">
        <main className="min-h-full w-full flex justify-center items-center">
          <div>
            <img
              src={page_not_found_img}
              alt="404_page_not_found"
              className="h-full w-full object-cover object-top"
            />
          </div>
        </main>
      </div>
    </>
  );
}
