import React from "react";
import MissedPaymentClientsTable from "./Tables/MissedPaymentClientsTable";

const MissedPaymentClients = () => {
  return (
    <div>
      <MissedPaymentClientsTable />
    </div>
  );
};

export default MissedPaymentClients;
