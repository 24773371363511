import ListAttorneysTable from "./Tables/ListAttorneysTable";
import ListFirms from "./ListFirms";
export default function FirmOverView() {
  return (
    <>
      <main className="py-10">
        <ListFirms />
        <ListAttorneysTable />
      </main>
    </>
  );
}
